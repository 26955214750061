.watchlist-tooltip:after, .watchlist-tooltipmobile:after{
  content: '';
  position: absolute;
  left: 125px;
  top: 5px;
  width: 20px;
  height: 20px;
  background: #f4f4f4;
  -webkit-transform: rotate(45deg);
  transform: rotate(225deg);
  box-shadow: inset -6px -6px 0px -4px #2DC6D6;
}

.watchlist-tooltipmobile:after {
  right: 75px;
  left:unset;
}

.watchlist-dropdown-toggle:after {
  display:none;
}

.tx-cs2-productfinder .filter-button .svg-icon {
  display:inline-block;
  width:2rem;
  height:2rem;
  stroke-width:0;
  stroke:currentColor;
  fill:currentColor
}
