#tx-solr-search {

  @include media-breakpoint-down(md) {
    h2 {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .tx-solr-search-form {
    .form-control {
      height: initial;
      @include media-breakpoint-down(md) {
        line-height: 1;
      }
    }
  }

  #tx-solr-faceting {
    .facets-label {
      font-weight: 700;
    }

    .facet-area-main {
      .facets {
        .facet-option {
          .active {
            background-color: $blue;
            border-color: $blue;
          }
        }
      }
    }
  }

  .search-result {


    .results-teaser {

      &:not(span.fileLink) {
        a {
          overflow-wrap: anywhere;
        }
      }
    }


    @include media-breakpoint-down(md) {
      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 16px;
      }
    }


  }

  #tx-solr-search-form-pi-results {

    margin-bottom: 72px;

    @include media-breakpoint-down(md) {

      margin-bottom: 32px;

    }

    .input-box {
      max-width: 650px;
      margin: 0 auto;

      .form-control {
        height: 43px;
        border: 1px solid $white;
        background: $gray-300;
        border-radius: 50px;
        color: $black;
        max-width: 585px;
        &:focus {
          border-color: $blue;
        }
      }
      .btn {
        padding: 0 1rem;
        box-shadow: none;
        line-height: 1;
        i {
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
    }
  }


  .result__type {
    display: inline-block;
    text-align: center;
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    vertical-align: bottom;
    padding: 10px 0;
  }
  .results-list {
    .results-entry {

      border-bottom: 1px solid $gray-600;
      &:last-child {
        margin-bottom: 40px;
      }
      &:first-child {
        margin-top: 24px;
      }

      .result__title_text {
        margin-left: 16px;

        @include media-breakpoint-down(md) {

          display: block;
          margin-top: 16px;
          margin-left: 0;
        }
      }
      .result__type {
        width: 123px;
      }

      .result__type--blue {
        color: $white;
        background-color: $blue;

      }
    }
  }
  .facets {

    .facet-option {
      margin-left: 16px;
      .facet {
        line-height: 40px;



      }
      span {

        &.facet {

          color: $gray-600;
          border-color: $gray-600;
          cursor: unset;
          &:hover {
            background-color: transparent;
            color: $gray-600;
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        width: 100%;
        .facet {
          width: 100%;
          padding: 0;
          margin-bottom: 16px;
        }
      }


      &:first-child {
        margin-left: 0;
      }
    }

    .result__type {
      padding: 12px 16px;
      color: $blue;
      border: 1px solid $blue;

      &.active {
        color: $white;
        background-color: $blue;
      }
    }
  }
  .searchinfo {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .result-content {
    .results-highlight {
      /*font-weight: bold;*/
      color: $black;
      background-color: $bluelight;
      padding: 2px 4px;
    }
  }
  #results-per-page {
    text-align: right;
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    .form-inline {
      display: inline;

      .btn-like-link {
        border: unset;
        background-color: transparent;
        color: $blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }


  }

}

#searchOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;

  &.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 2000;
  }
  .tx_solr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    color: $black;
    outline: none;
    border-bottom: 1px solid $gray-600;
    @include media-breakpoint-down(md) {
      width: 95%;
    }
    input {
      border: 0;
      box-shadow: none;
    }
    .input-group-btn {
      border: 0;
      .btn {
        border-bottom-width: 0;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .close {
    position: fixed;
    top: 15px;
    right: 15px;
  }
}

.tx-solr-autosuggest { border: 1px solid $gray-400; background: $white; overflow: auto; box-shadow: $box-shadow; a { text-decoration: none; } }
.tx-solr-autosuggest strong { font-weight: bolder; color: $blue; }
.tx-solr-autosuggest .autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; cursor: pointer; }
.tx-solr-autosuggest .autocomplete-selected { background: $gray-200; }
.tx-solr-autosuggest .autocomplete-group { padding: 10px 5px; }
.tx-solr-autosuggest .autocomplete-group strong { display: block; border-bottom: 1px solid $gray-700; }
