.tx-cs2-triallicencemanager {

  .form__group {
    margin: 10px 0 0;
  }

  .form__group--error.form__serialno > input {
    background-color: #eb3b3b;
  }

  .form__group--error.form__serialno > input[disabled] {
    background-color: #eb3b3b;
    color: #fff;
    opacity: 1;
  }

  .form__group--success.form__serialno > input {
    background-color: #32c832;
  }

  .form__group--success.form__serialno > input[disabled] {
    background-color: #32c832;
    color: #fff;
    opacity: 1;
  }

  .form__serialno {
    /* display: block; */
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 15px;
    border: 1px solid #ced1d2;
    height: 75px;
  }

  .form__serialno:before,
  .form__serialno:after {
    display: table;
    content: '';
  }

  .form__serialno:after {
    clear: both;
  }

  .form__serialno > label {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form__serialno > input[type="number"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .form__serialno > input {
    display: inline-block;
    float: left;
    /* width: 15%; */
    /* height: 50px; */
    line-height: 48px;
    text-align: center;
    font-size: 36px;
    color: #181819;
    border: 0;
    border-bottom: 5px solid #000000;
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s, color 0.3s, opacity 0.3s;
    cursor: default;
    user-select: none;
    margin: 0;
    margin-top: 10px;
    /* margin-left: 2%; */
    /* margin-right: 2%; */
    /* padding: 0; */
  }

  .form__serialno > input.invalid {
    color: #e5352d;
  }

  .form__serialno.is-invalid > input,
  .form__serialno > input:is(:placeholder-shown) {
    border-bottom: 5px solid #e5352d;
  }

  .form__serialno > input:focus {
    outline: 0;
    box-shadow: none;
    border-color: #000;
    animation: border-pulsate 1.5s infinite;
    -webkit-tap-highlight-color: transparent;
  }

  .form__serialno > input:last-child {
    margin-right: 0;
  }

  .form__serialno > input[disabled] {
    background: #eee;
    opacity: 1;
  }

  label {
    font-weight: normal;
  }

  .form-control.is-invalid {
    border-top: 1px solid #ced1d2;
    border-left: 1px solid #ced1d2;
    border-right: 1px solid #ced1d2;
    border-bottom: 5px solid #e5352d;
  }

  .custom-control-label:before {
    border: #ced1d2 solid 2px !important;
  }

  .custom-control-input.is-invalid~.custom-control-label::before {
    border: #e5352d solid 2px !important;
  }

  .custom-control-input:checked~.custom-control-label::before {
    background-color: #ffffff !important;
  }

  .custom-control-input:checked~.custom-control-label::after {
    color: #000000 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }

  .alert-danger {
    background-color: #ffffff;
    border: 2px solid #e5352d;
    color: #e5352d;
    font-weight: bold;
  }

  table#licences-list {
    th {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 8px !important;
      padding-right: 28px !important;
    }
    td {
      padding: 8px !important;
    }
  }
}
