@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger.is-active:hover {
  opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #185FA5;
}

.hamburger-box {
  width: 19px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 19px;
  height: 2px;
  background-color: #333333;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 38px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 38px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 38px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 38px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 38px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 38px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-3.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-3.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(3.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(3.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-3.8px, -4.75px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-3.8px, 4.75px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(3.8px, -4.75px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(3.8px, 4.75px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -38px;
  top: -38px;
  transform: translate3d(38px, 38px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -38px;
  top: -38px;
  transform: translate3d(-38px, 38px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -38px;
  top: 38px;
  transform: translate3d(38px, -38px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -38px;
  top: 38px;
  transform: translate3d(-38px, -38px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1px;
}

.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 16px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-2.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 16px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(2.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 8px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #2DC6D6;
  --indigo: #06375B;
  --purple: #D4007A;
  --pink: #e83e8c;
  --red: #E5352D;
  --orange: #fd7e14;
  --yellow: #FECC00;
  --green: #97A719;
  --teal: #20c997;
  --cyan: #6DC9D1;
  --white: #fff;
  --gray: #CED1D2;
  --gray-dark: rgba(0, 0, 0, 0.8);
  --bluedark: #0B72B5;
  --reddark: #B00511;
  --gray-light: #E9E9E9;
  --primary: #2DC6D6;
  --secondary: #6DC9D1;
  --success: #97A719;
  --info: #fff;
  --warning: #FECC00;
  --danger: #E5352D;
  --light: #F4F4F4;
  --dark: rgba(0, 0, 0, 0.8);
  --blue: #2DC6D6;
  --bluedark: #0B72B5;
  --indigo: #06375B;
  --purple: #D4007A;
  --pink: #e83e8c;
  --red: #E5352D;
  --reddark: #B00511;
  --orange: #fd7e14;
  --yellow: #FECC00;
  --green: #97A719;
  --teal: #20c997;
  --cyan: #6DC9D1;
  --white: #fff;
  --gray-light: #E9E9E9;
  --gray: #CED1D2;
  --gray-dark: rgba(0, 0, 0, 0.8);
  --black: #000;
  --hub: #EDEDED;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Verdana";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #2DC6D6;
  text-decoration: underline;
  background-color: transparent;
}

a:hover {
  color: #1e8d99;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #CED1D2;
  text-align: left;
  caption-side: bottom;
}

th {
  font-weight: 700;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.25rem;
  font-family: "Open Sans Condensed Light";
  font-weight: 300;
  line-height: 1.36;
  color: #000;
}

h1, .h1 {
  font-size: 3.0625rem;
}

h2, .h2 {
  font-size: 2.5rem;
}

h3, .h3 {
  font-size: 1.9375rem;
}

h4, .h4 {
  font-size: 1.5625rem;
}

h5, .h5 {
  font-size: 1.5625rem;
}

h6, .h6 {
  font-size: 1.25rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.36;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.36;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.36;
}

.display-4 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.36;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #CED1D2;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E9E9E9;
  border-radius: 0.125rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #CED1D2;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #161615;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #161615;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1060px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}

.table th,
.table td {
  padding: 5px;
  vertical-align: top;
  border-top: 1px solid #CED1D2;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #CED1D2;
}

.table tbody + tbody {
  border-top: 2px solid #CED1D2;
}

.table-sm th,
.table-sm td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #CED1D2;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #CED1D2;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4eff4;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #92e1ea;
}

.table-hover .table-primary:hover {
  background-color: #afe9f0;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #afe9f0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6f0f2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3e3e7;
}

.table-hover .table-secondary:hover {
  background-color: #c3e9ec;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c3e9ec;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e2e6bf;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c9d187;
}

.table-hover .table-success:hover {
  background-color: #dadfad;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #dadfad;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: white;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: white;
}

.table-hover .table-info:hover {
  background-color: #f2f2f2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #f2f2f2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1b8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fee47a;
}

.table-hover .table-warning:hover {
  background-color: #ffec9f;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffec9f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8c6c4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f19692;
}

.table-hover .table-danger:hover {
  background-color: #f5b0ad;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b0ad;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9f9f9;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgba(203, 203, 203, 0.944);
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgba(148, 148, 148, 0.896);
}

.table-hover .table-dark:hover {
  background-color: rgba(190, 190, 190, 0.944);
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgba(190, 190, 190, 0.944);
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #c4eff4;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #92e1ea;
}

.table-hover .table-blue:hover {
  background-color: #afe9f0;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #afe9f0;
}

.table-bluedark,
.table-bluedark > th,
.table-bluedark > td {
  background-color: #bbd8ea;
}

.table-bluedark th,
.table-bluedark td,
.table-bluedark thead th,
.table-bluedark tbody + tbody {
  border-color: #80b6d9;
}

.table-hover .table-bluedark:hover {
  background-color: #a8cde4;
}

.table-hover .table-bluedark:hover > td,
.table-hover .table-bluedark:hover > th {
  background-color: #a8cde4;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #b9c7d1;
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #7e97aa;
}

.table-hover .table-indigo:hover {
  background-color: #aabbc7;
}

.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #aabbc7;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #f3b8da;
}

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #e97aba;
}

.table-hover .table-purple:hover {
  background-color: #efa2cf;
}

.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #efa2cf;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #f9c9df;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f39bc3;
}

.table-hover .table-pink:hover {
  background-color: #f6b2d1;
}

.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #f6b2d1;
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: #f8c6c4;
}

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #f19692;
}

.table-hover .table-red:hover {
  background-color: #f5b0ad;
}

.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: #f5b0ad;
}

.table-reddark,
.table-reddark > th,
.table-reddark > td {
  background-color: #e9b9bc;
}

.table-reddark th,
.table-reddark td,
.table-reddark thead th,
.table-reddark tbody + tbody {
  border-color: #d67d83;
}

.table-hover .table-reddark:hover {
  background-color: #e3a6a9;
}

.table-hover .table-reddark:hover > td,
.table-hover .table-reddark:hover > th {
  background-color: #e3a6a9;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fedbbd;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #febc85;
}

.table-hover .table-orange:hover {
  background-color: #fecda4;
}

.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #fecda4;
}

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #fff1b8;
}

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #fee47a;
}

.table-hover .table-yellow:hover {
  background-color: #ffec9f;
}

.table-hover .table-yellow:hover > td,
.table-hover .table-yellow:hover > th {
  background-color: #ffec9f;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #e2e6bf;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #c9d187;
}

.table-hover .table-green:hover {
  background-color: #dadfad;
}

.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #dadfad;
}

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #c1f0e2;
}

.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: #8be3c9;
}

.table-hover .table-teal:hover {
  background-color: #acebd8;
}

.table-hover .table-teal:hover > td,
.table-hover .table-teal:hover > th {
  background-color: #acebd8;
}

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #d6f0f2;
}

.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody + tbody {
  border-color: #b3e3e7;
}

.table-hover .table-cyan:hover {
  background-color: #c3e9ec;
}

.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: #c3e9ec;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-gray-light,
.table-gray-light > th,
.table-gray-light > td {
  background-color: #f9f9f9;
}

.table-gray-light th,
.table-gray-light td,
.table-gray-light thead th,
.table-gray-light tbody + tbody {
  border-color: #f4f4f4;
}

.table-hover .table-gray-light:hover {
  background-color: #ececec;
}

.table-hover .table-gray-light:hover > td,
.table-hover .table-gray-light:hover > th {
  background-color: #ececec;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #f1f2f2;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #e6e7e8;
}

.table-hover .table-gray:hover {
  background-color: #e4e6e6;
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #e4e6e6;
}

.table-gray-dark,
.table-gray-dark > th,
.table-gray-dark > td {
  background-color: rgba(203, 203, 203, 0.944);
}

.table-gray-dark th,
.table-gray-dark td,
.table-gray-dark thead th,
.table-gray-dark tbody + tbody {
  border-color: rgba(148, 148, 148, 0.896);
}

.table-hover .table-gray-dark:hover {
  background-color: rgba(190, 190, 190, 0.944);
}

.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: rgba(190, 190, 190, 0.944);
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-hub,
.table-hub > th,
.table-hub > td {
  background-color: #fafafa;
}

.table-hub th,
.table-hub td,
.table-hub thead th,
.table-hub tbody + tbody {
  border-color: #f6f6f6;
}

.table-hover .table-hub:hover {
  background-color: #ededed;
}

.table-hover .table-hub:hover > td,
.table-hover .table-hub:hover > th {
  background-color: #ededed;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(19, 19, 19, 0.8);
}

.table .thead-light th {
  color: #707070;
  background-color: #F4F4F4;
  border-color: #CED1D2;
}

.table-dark {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgba(19, 19, 19, 0.8);
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.27778rem 0.88888rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CED1D2;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #2DC6D6;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.form-control::placeholder {
  color: #CED1D2;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #F4F4F4;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.27778rem + 1px);
  padding-bottom: calc(0.27778rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.27778rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 2rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #CED1D2;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #97A719;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(151, 167, 25, 0.9);
  border-radius: 0.125rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #97A719;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #97A719;
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #97A719;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #97A719;
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #97A719;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #97A719;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #97A719;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #bfd320;
  background-color: #bfd320;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #97A719;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #97A719;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #97A719;
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E5352D;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(229, 53, 45, 0.9);
  border-radius: 0.125rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E5352D;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #E5352D;
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E5352D;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #E5352D;
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E5352D;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E5352D;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #E5352D;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #eb615a;
  background-color: #eb615a;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E5352D;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E5352D;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E5352D;
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #000;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 1.7778rem;
  font-size: 1rem;
  line-height: 2.7778rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #000;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 2px 2px 10px 4px rgba(0, 0, 0, 0.2);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 2px 2px 10px 4px rgba(0, 0, 0, 0.2), 2px 2px 5px rgba(0, 0, 0, 0.2);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-primary:hover {
  color: #fff;
  background-color: #24abb9;
  border-color: #22a1ae;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #24abb9;
  border-color: #22a1ae;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(42, 172, 185, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #22a1ae;
  border-color: #2097a3;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(42, 172, 185, 0.5);
}

.btn-secondary {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-secondary:hover {
  color: #161615;
  background-color: #50bec8;
  border-color: #46bbc5;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #161615;
  background-color: #50bec8;
  border-color: #46bbc5;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(96, 174, 181, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #161615;
  background-color: #46bbc5;
  border-color: #3db7c1;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(96, 174, 181, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-success:hover {
  color: #fff;
  background-color: #798614;
  border-color: #6f7b12;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #798614;
  border-color: #6f7b12;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(167, 180, 60, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #6f7b12;
  border-color: #657011;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(167, 180, 60, 0.5);
}

.btn-info {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-info:hover {
  color: #161615;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-info:focus, .btn-info.focus {
  color: #161615;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(220, 220, 220, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #161615;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(220, 220, 220, 0.5);
}

.btn-warning {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-warning:hover {
  color: #161615;
  background-color: #d8ad00;
  border-color: #cba300;
}

.btn-warning:focus, .btn-warning.focus {
  color: #161615;
  background-color: #d8ad00;
  border-color: #cba300;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(219, 177, 3, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #161615;
  background-color: #cba300;
  border-color: #be9900;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(219, 177, 3, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-danger:hover {
  color: #fff;
  background-color: #d2221a;
  border-color: #c62019;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d2221a;
  border-color: #c62019;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(233, 83, 77, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c62019;
  border-color: #bb1e17;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(233, 83, 77, 0.5);
}

.btn-light {
  color: #161615;
  background-color: #F4F4F4;
  border-color: #F4F4F4;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-light:hover {
  color: #161615;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}

.btn-light:focus, .btn-light.focus {
  color: #161615;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(211, 211, 211, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #161615;
  background-color: #F4F4F4;
  border-color: #F4F4F4;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #161615;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(211, 211, 211, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-dark:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(53, 53, 53, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(53, 53, 53, 0.5);
}

.btn-blue {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-blue:hover {
  color: #fff;
  background-color: #24abb9;
  border-color: #22a1ae;
}

.btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background-color: #24abb9;
  border-color: #22a1ae;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(42, 172, 185, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #22a1ae;
  border-color: #2097a3;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(42, 172, 185, 0.5);
}

.btn-bluedark {
  color: #fff;
  background-color: #0B72B5;
  border-color: #0B72B5;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-bluedark:hover {
  color: #fff;
  background-color: #095b91;
  border-color: #085485;
}

.btn-bluedark:focus, .btn-bluedark.focus {
  color: #fff;
  background-color: #095b91;
  border-color: #085485;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(48, 135, 192, 0.5);
}

.btn-bluedark.disabled, .btn-bluedark:disabled {
  color: #fff;
  background-color: #0B72B5;
  border-color: #0B72B5;
}

.btn-bluedark:not(:disabled):not(.disabled):active, .btn-bluedark:not(:disabled):not(.disabled).active,
.show > .btn-bluedark.dropdown-toggle {
  color: #fff;
  background-color: #085485;
  border-color: #074c79;
}

.btn-bluedark:not(:disabled):not(.disabled):active:focus, .btn-bluedark:not(:disabled):not(.disabled).active:focus,
.show > .btn-bluedark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(48, 135, 192, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #06375B;
  border-color: #06375B;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-indigo:hover {
  color: #fff;
  background-color: #042137;
  border-color: #031a2b;
}

.btn-indigo:focus, .btn-indigo.focus {
  color: #fff;
  background-color: #042137;
  border-color: #031a2b;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(43, 85, 116, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #06375B;
  border-color: #06375B;
}

.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #031a2b;
  border-color: #02131f;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(43, 85, 116, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #D4007A;
  border-color: #D4007A;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-purple:hover {
  color: #fff;
  background-color: #ae0064;
  border-color: #a1005d;
}

.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #ae0064;
  border-color: #a1005d;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(218, 38, 142, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #D4007A;
  border-color: #D4007A;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #a1005d;
  border-color: #940055;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(218, 38, 142, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-pink:hover {
  color: #fff;
  background-color: #e41c78;
  border-color: #d91a72;
}

.btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background-color: #e41c78;
  border-color: #d91a72;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d91a72;
  border-color: #ce196c;
}

.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-red:hover {
  color: #fff;
  background-color: #d2221a;
  border-color: #c62019;
}

.btn-red:focus, .btn-red.focus {
  color: #fff;
  background-color: #d2221a;
  border-color: #c62019;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(233, 83, 77, 0.5);
}

.btn-red.disabled, .btn-red:disabled {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #c62019;
  border-color: #bb1e17;
}

.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(233, 83, 77, 0.5);
}

.btn-reddark {
  color: #fff;
  background-color: #B00511;
  border-color: #B00511;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-reddark:hover {
  color: #fff;
  background-color: #8b040d;
  border-color: #7e040c;
}

.btn-reddark:focus, .btn-reddark.focus {
  color: #fff;
  background-color: #8b040d;
  border-color: #7e040c;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(188, 43, 53, 0.5);
}

.btn-reddark.disabled, .btn-reddark:disabled {
  color: #fff;
  background-color: #B00511;
  border-color: #B00511;
}

.btn-reddark:not(:disabled):not(.disabled):active, .btn-reddark:not(:disabled):not(.disabled).active,
.show > .btn-reddark.dropdown-toggle {
  color: #fff;
  background-color: #7e040c;
  border-color: #72030b;
}

.btn-reddark:not(:disabled):not(.disabled):active:focus, .btn-reddark:not(:disabled):not(.disabled).active:focus,
.show > .btn-reddark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(188, 43, 53, 0.5);
}

.btn-orange {
  color: #161615;
  background-color: #fd7e14;
  border-color: #fd7e14;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-orange:hover {
  color: #fff;
  background-color: #e96b02;
  border-color: #dc6502;
}

.btn-orange:focus, .btn-orange.focus {
  color: #fff;
  background-color: #e96b02;
  border-color: #dc6502;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(218, 110, 20, 0.5);
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #161615;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #dc6502;
  border-color: #cf5f02;
}

.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(218, 110, 20, 0.5);
}

.btn-yellow {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-yellow:hover {
  color: #161615;
  background-color: #d8ad00;
  border-color: #cba300;
}

.btn-yellow:focus, .btn-yellow.focus {
  color: #161615;
  background-color: #d8ad00;
  border-color: #cba300;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(219, 177, 3, 0.5);
}

.btn-yellow.disabled, .btn-yellow:disabled {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: #161615;
  background-color: #cba300;
  border-color: #be9900;
}

.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(219, 177, 3, 0.5);
}

.btn-green {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-green:hover {
  color: #fff;
  background-color: #798614;
  border-color: #6f7b12;
}

.btn-green:focus, .btn-green.focus {
  color: #fff;
  background-color: #798614;
  border-color: #6f7b12;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(167, 180, 60, 0.5);
}

.btn-green.disabled, .btn-green:disabled {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #6f7b12;
  border-color: #657011;
}

.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(167, 180, 60, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-teal:hover {
  color: #fff;
  background-color: #1ba87e;
  border-color: #199d76;
}

.btn-teal:focus, .btn-teal.focus {
  color: #fff;
  background-color: #1ba87e;
  border-color: #199d76;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
}

.btn-teal.disabled, .btn-teal:disabled {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #199d76;
  border-color: #17926e;
}

.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
}

.btn-cyan {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-cyan:hover {
  color: #161615;
  background-color: #50bec8;
  border-color: #46bbc5;
}

.btn-cyan:focus, .btn-cyan.focus {
  color: #161615;
  background-color: #50bec8;
  border-color: #46bbc5;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(96, 174, 181, 0.5);
}

.btn-cyan.disabled, .btn-cyan:disabled {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
  color: #161615;
  background-color: #46bbc5;
  border-color: #3db7c1;
}

.btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(96, 174, 181, 0.5);
}

.btn-white {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-white:hover {
  color: #161615;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  color: #161615;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(220, 220, 220, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #161615;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(220, 220, 220, 0.5);
}

.btn-gray-light {
  color: #161615;
  background-color: #E9E9E9;
  border-color: #E9E9E9;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-gray-light:hover {
  color: #161615;
  background-color: #d6d6d6;
  border-color: #d0d0d0;
}

.btn-gray-light:focus, .btn-gray-light.focus {
  color: #161615;
  background-color: #d6d6d6;
  border-color: #d0d0d0;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(201, 201, 201, 0.5);
}

.btn-gray-light.disabled, .btn-gray-light:disabled {
  color: #161615;
  background-color: #E9E9E9;
  border-color: #E9E9E9;
}

.btn-gray-light:not(:disabled):not(.disabled):active, .btn-gray-light:not(:disabled):not(.disabled).active,
.show > .btn-gray-light.dropdown-toggle {
  color: #161615;
  background-color: #d0d0d0;
  border-color: #c9c9c9;
}

.btn-gray-light:not(:disabled):not(.disabled):active:focus, .btn-gray-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-light.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(201, 201, 201, 0.5);
}

.btn-gray {
  color: #161615;
  background-color: #CED1D2;
  border-color: #CED1D2;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-gray:hover {
  color: #161615;
  background-color: #babec0;
  border-color: #b3b8ba;
}

.btn-gray:focus, .btn-gray.focus {
  color: #161615;
  background-color: #babec0;
  border-color: #b3b8ba;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(178, 181, 182, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #161615;
  background-color: #CED1D2;
  border-color: #CED1D2;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #161615;
  background-color: #b3b8ba;
  border-color: #adb2b3;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(178, 181, 182, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-gray-dark:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-gray-dark:focus, .btn-gray-dark.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(53, 53, 53, 0.5);
}

.btn-gray-dark.disabled, .btn-gray-dark:disabled {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(53, 53, 53, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-hub {
  color: #161615;
  background-color: #EDEDED;
  border-color: #EDEDED;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.btn-hub:hover {
  color: #161615;
  background-color: #dadada;
  border-color: #d4d4d4;
}

.btn-hub:focus, .btn-hub.focus {
  color: #161615;
  background-color: #dadada;
  border-color: #d4d4d4;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17), 0 0 0 0.2rem rgba(205, 205, 205, 0.5);
}

.btn-hub.disabled, .btn-hub:disabled {
  color: #161615;
  background-color: #EDEDED;
  border-color: #EDEDED;
}

.btn-hub:not(:disabled):not(.disabled):active, .btn-hub:not(:disabled):not(.disabled).active,
.show > .btn-hub.dropdown-toggle {
  color: #161615;
  background-color: #d4d4d4;
  border-color: #cdcdcd;
}

.btn-hub:not(:disabled):not(.disabled):active:focus, .btn-hub:not(:disabled):not(.disabled).active:focus,
.show > .btn-hub.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(205, 205, 205, 0.5);
}

.btn-outline-primary {
  color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-primary:hover {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2DC6D6;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.btn-outline-secondary {
  color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-secondary:hover {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6DC9D1;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.btn-outline-success {
  color: #97A719;
  border-color: #97A719;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #97A719;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.btn-outline-info {
  color: #fff;
  border-color: #fff;
}

.btn-outline-info:hover {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-warning {
  color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-warning:hover {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FECC00;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.btn-outline-danger {
  color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #E5352D;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.btn-outline-light {
  color: #F4F4F4;
  border-color: #F4F4F4;
}

.btn-outline-light:hover {
  color: #161615;
  background-color: #F4F4F4;
  border-color: #F4F4F4;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #F4F4F4;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #161615;
  background-color: #F4F4F4;
  border-color: #F4F4F4;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-dark {
  color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-blue {
  color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-blue:hover {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #2DC6D6;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #161615;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.btn-outline-bluedark {
  color: #0B72B5;
  border-color: #0B72B5;
}

.btn-outline-bluedark:hover {
  color: #fff;
  background-color: #0B72B5;
  border-color: #0B72B5;
}

.btn-outline-bluedark:focus, .btn-outline-bluedark.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 114, 181, 0.5);
}

.btn-outline-bluedark.disabled, .btn-outline-bluedark:disabled {
  color: #0B72B5;
  background-color: transparent;
}

.btn-outline-bluedark:not(:disabled):not(.disabled):active, .btn-outline-bluedark:not(:disabled):not(.disabled).active,
.show > .btn-outline-bluedark.dropdown-toggle {
  color: #fff;
  background-color: #0B72B5;
  border-color: #0B72B5;
}

.btn-outline-bluedark:not(:disabled):not(.disabled):active:focus, .btn-outline-bluedark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-bluedark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(11, 114, 181, 0.5);
}

.btn-outline-indigo {
  color: #06375B;
  border-color: #06375B;
}

.btn-outline-indigo:hover {
  color: #fff;
  background-color: #06375B;
  border-color: #06375B;
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(6, 55, 91, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #06375B;
  background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #06375B;
  border-color: #06375B;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(6, 55, 91, 0.5);
}

.btn-outline-purple {
  color: #D4007A;
  border-color: #D4007A;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #D4007A;
  border-color: #D4007A;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 0, 122, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #D4007A;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #D4007A;
  border-color: #D4007A;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(212, 0, 122, 0.5);
}

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #e83e8c;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-red {
  color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-red:focus, .btn-outline-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.btn-outline-red.disabled, .btn-outline-red:disabled {
  color: #E5352D;
  background-color: transparent;
}

.btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #E5352D;
  border-color: #E5352D;
}

.btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.btn-outline-reddark {
  color: #B00511;
  border-color: #B00511;
}

.btn-outline-reddark:hover {
  color: #fff;
  background-color: #B00511;
  border-color: #B00511;
}

.btn-outline-reddark:focus, .btn-outline-reddark.focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 5, 17, 0.5);
}

.btn-outline-reddark.disabled, .btn-outline-reddark:disabled {
  color: #B00511;
  background-color: transparent;
}

.btn-outline-reddark:not(:disabled):not(.disabled):active, .btn-outline-reddark:not(:disabled):not(.disabled).active,
.show > .btn-outline-reddark.dropdown-toggle {
  color: #fff;
  background-color: #B00511;
  border-color: #B00511;
}

.btn-outline-reddark:not(:disabled):not(.disabled):active:focus, .btn-outline-reddark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-reddark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(176, 5, 17, 0.5);
}

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14;
}

.btn-outline-orange:hover {
  color: #161615;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fd7e14;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #161615;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-yellow {
  color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-yellow:hover {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-yellow:focus, .btn-outline-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  color: #FECC00;
  background-color: transparent;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
.show > .btn-outline-yellow.dropdown-toggle {
  color: #161615;
  background-color: #FECC00;
  border-color: #FECC00;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.btn-outline-green {
  color: #97A719;
  border-color: #97A719;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #97A719;
  background-color: transparent;
}

.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997;
}

.btn-outline-teal:hover {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-outline-teal:focus, .btn-outline-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.btn-outline-teal.disabled, .btn-outline-teal:disabled {
  color: #20c997;
  background-color: transparent;
}

.btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.btn-outline-cyan {
  color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-cyan:hover {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-cyan:focus, .btn-outline-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  color: #6DC9D1;
  background-color: transparent;
}

.btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
.show > .btn-outline-cyan.dropdown-toggle {
  color: #161615;
  background-color: #6DC9D1;
  border-color: #6DC9D1;
}

.btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #161615;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-gray-light {
  color: #E9E9E9;
  border-color: #E9E9E9;
}

.btn-outline-gray-light:hover {
  color: #161615;
  background-color: #E9E9E9;
  border-color: #E9E9E9;
}

.btn-outline-gray-light:focus, .btn-outline-gray-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0.5);
}

.btn-outline-gray-light.disabled, .btn-outline-gray-light:disabled {
  color: #E9E9E9;
  background-color: transparent;
}

.btn-outline-gray-light:not(:disabled):not(.disabled):active, .btn-outline-gray-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-light.dropdown-toggle {
  color: #161615;
  background-color: #E9E9E9;
  border-color: #E9E9E9;
}

.btn-outline-gray-light:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-light.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(233, 233, 233, 0.5);
}

.btn-outline-gray {
  color: #CED1D2;
  border-color: #CED1D2;
}

.btn-outline-gray:hover {
  color: #161615;
  background-color: #CED1D2;
  border-color: #CED1D2;
}

.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 209, 210, 0.5);
}

.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #CED1D2;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #161615;
  background-color: #CED1D2;
  border-color: #CED1D2;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(206, 209, 210, 0.5);
}

.btn-outline-gray-dark {
  color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-hub {
  color: #EDEDED;
  border-color: #EDEDED;
}

.btn-outline-hub:hover {
  color: #161615;
  background-color: #EDEDED;
  border-color: #EDEDED;
}

.btn-outline-hub:focus, .btn-outline-hub.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 237, 237, 0.5);
}

.btn-outline-hub.disabled, .btn-outline-hub:disabled {
  color: #EDEDED;
  background-color: transparent;
}

.btn-outline-hub:not(:disabled):not(.disabled):active, .btn-outline-hub:not(:disabled):not(.disabled).active,
.show > .btn-outline-hub.dropdown-toggle {
  color: #161615;
  background-color: #EDEDED;
  border-color: #EDEDED;
}

.btn-outline-hub:not(:disabled):not(.disabled):active:focus, .btn-outline-hub:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-hub.dropdown-toggle:focus {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(237, 237, 237, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2DC6D6;
  text-decoration: underline;
}

.btn-link:hover {
  color: #1e8d99;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #CED1D2;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F4F4F4;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #161615;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #6DC9D1;
  text-decoration: none;
  background-color: #F4F4F4;
}

.dropdown-item.active, .dropdown-item:active {
  color: #161615;
  text-decoration: none;
  background-color: #F4F4F4;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #CED1D2;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #CED1D2;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #161615;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.33335rem;
  padding-left: 1.33335rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.27778rem 0.88888rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #F4F4F4;
  border: 1px solid #CED1D2;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.88888rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2DC6D6;
  background-color: #2DC6D6;
  box-shadow:;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2DC6D6;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #c2eff3;
  border-color: #c2eff3;
  box-shadow:;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #CED1D2;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #F4F4F4;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #6DC9D1 solid 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2DC6D6;
  background-color: #2DC6D6;
  box-shadow:;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 198, 214, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(45, 198, 214, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 198, 214, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.25rem + 4px);
  width: calc(1rem - 8px);
  height: calc(1rem - 8px);
  background-color: #6DC9D1;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 198, 214, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0.27778rem 1.88888rem 0.27778rem 0.88888rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='10' viewBox='0 0 448 512'%3e%3cpath fill='rgba%280, 0, 0, 0.8%29' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'/%3e%3c/svg%3e") no-repeat right 1rem center/16px 16px;
  border: 1px solid #CED1D2;
  border-radius: 0.125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}

.custom-select:focus {
  border-color: #2DC6D6;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-select:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.88888rem;
  background-image: none;
}

.custom-select:disabled {
  color: #CED1D2;
  background-color: #F4F4F4;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #2DC6D6;
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #F4F4F4;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.27778rem 0.88888rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 1px solid #CED1D2;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.55556rem);
  padding: 0.27778rem 0.88888rem;
  line-height: 1.5;
  color: #000;
  content: "Browse";
  background-color: #F4F4F4;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2DC6D6;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #c2eff3;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E9E9E9;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2DC6D6;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #c2eff3;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E9E9E9;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2DC6D6;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #c2eff3;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-fill-lower {
  background-color: #E9E9E9;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #E9E9E9;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #E9E9E9;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #E9E9E9;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #E9E9E9;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #CED1D2;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E9E9E9;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #F4F4F4 #F4F4F4 #E9E9E9;
}

.nav-tabs .nav-link.disabled {
  color: #CED1D2;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #707070;
  background-color: #fff;
  border-color: #E9E9E9 #E9E9E9 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2DC6D6;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: white;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: white;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.125rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.125rem - 1px) calc(0.125rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.125rem - 1px) calc(0.125rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.125rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px);
}

.card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-size: 0.7222rem;
  list-style: none;
  background-color: inherit;
  border-radius: 0.125rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #999999;
  content: "<";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #CED1D2;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2DC6D6;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #E9E9E9;
}

.page-link:hover {
  z-index: 2;
  color: #1e8d99;
  text-decoration: none;
  background-color: #F4F4F4;
  border-color: #E9E9E9;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.page-item.disabled .page-link {
  color: #CED1D2;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #E9E9E9;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #161615;
  background-color: #2DC6D6;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #161615;
  background-color: #22a1ae;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.badge-secondary {
  color: #161615;
  background-color: #6DC9D1;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #161615;
  background-color: #46bbc5;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #97A719;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #6f7b12;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.badge-info {
  color: #161615;
  background-color: #fff;
}

a.badge-info:hover, a.badge-info:focus {
  color: #161615;
  background-color: #e6e6e6;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-warning {
  color: #161615;
  background-color: #FECC00;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #161615;
  background-color: #cba300;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #E5352D;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c62019;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.badge-light {
  color: #161615;
  background-color: #F4F4F4;
}

a.badge-light:hover, a.badge-light:focus {
  color: #161615;
  background-color: #dbdbdb;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-blue {
  color: #161615;
  background-color: #2DC6D6;
}

a.badge-blue:hover, a.badge-blue:focus {
  color: #161615;
  background-color: #22a1ae;
}

a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 198, 214, 0.5);
}

.badge-bluedark {
  color: #fff;
  background-color: #0B72B5;
}

a.badge-bluedark:hover, a.badge-bluedark:focus {
  color: #fff;
  background-color: #085485;
}

a.badge-bluedark:focus, a.badge-bluedark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(11, 114, 181, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #06375B;
}

a.badge-indigo:hover, a.badge-indigo:focus {
  color: #fff;
  background-color: #031a2b;
}

a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(6, 55, 91, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #D4007A;
}

a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #a1005d;
}

a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(212, 0, 122, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #e83e8c;
}

a.badge-pink:hover, a.badge-pink:focus {
  color: #fff;
  background-color: #d91a72;
}

a.badge-pink:focus, a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.badge-red {
  color: #fff;
  background-color: #E5352D;
}

a.badge-red:hover, a.badge-red:focus {
  color: #fff;
  background-color: #c62019;
}

a.badge-red:focus, a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 53, 45, 0.5);
}

.badge-reddark {
  color: #fff;
  background-color: #B00511;
}

a.badge-reddark:hover, a.badge-reddark:focus {
  color: #fff;
  background-color: #7e040c;
}

a.badge-reddark:focus, a.badge-reddark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(176, 5, 17, 0.5);
}

.badge-orange {
  color: #161615;
  background-color: #fd7e14;
}

a.badge-orange:hover, a.badge-orange:focus {
  color: #161615;
  background-color: #dc6502;
}

a.badge-orange:focus, a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.badge-yellow {
  color: #161615;
  background-color: #FECC00;
}

a.badge-yellow:hover, a.badge-yellow:focus {
  color: #161615;
  background-color: #cba300;
}

a.badge-yellow:focus, a.badge-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 204, 0, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #97A719;
}

a.badge-green:hover, a.badge-green:focus {
  color: #fff;
  background-color: #6f7b12;
}

a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(151, 167, 25, 0.5);
}

.badge-teal {
  color: #fff;
  background-color: #20c997;
}

a.badge-teal:hover, a.badge-teal:focus {
  color: #fff;
  background-color: #199d76;
}

a.badge-teal:focus, a.badge-teal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.badge-cyan {
  color: #161615;
  background-color: #6DC9D1;
}

a.badge-cyan:hover, a.badge-cyan:focus {
  color: #161615;
  background-color: #46bbc5;
}

a.badge-cyan:focus, a.badge-cyan.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(109, 201, 209, 0.5);
}

.badge-white {
  color: #161615;
  background-color: #fff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #161615;
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-gray-light {
  color: #161615;
  background-color: #E9E9E9;
}

a.badge-gray-light:hover, a.badge-gray-light:focus {
  color: #161615;
  background-color: #d0d0d0;
}

a.badge-gray-light:focus, a.badge-gray-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0.5);
}

.badge-gray {
  color: #161615;
  background-color: #CED1D2;
}

a.badge-gray:hover, a.badge-gray:focus {
  color: #161615;
  background-color: #b3b8ba;
}

a.badge-gray:focus, a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 209, 210, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

a.badge-gray-dark:hover, a.badge-gray-dark:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

a.badge-gray-dark:focus, a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}

a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: black;
}

a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-hub {
  color: #161615;
  background-color: #EDEDED;
}

a.badge-hub:hover, a.badge-hub:focus {
  color: #161615;
  background-color: #d4d4d4;
}

a.badge-hub:focus, a.badge-hub.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 237, 237, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F4F4F4;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #17676f;
  background-color: #d5f4f7;
  border-color: #c4eff4;
}

.alert-primary hr {
  border-top-color: #afe9f0;
}

.alert-primary .alert-link {
  color: #0e4045;
}

.alert-secondary {
  color: #39696d;
  background-color: #e2f4f6;
  border-color: #d6f0f2;
}

.alert-secondary hr {
  border-top-color: #c3e9ec;
}

.alert-secondary .alert-link {
  color: #27494c;
}

.alert-success {
  color: #4f570d;
  background-color: #eaedd1;
  border-color: #e2e6bf;
}

.alert-success hr {
  border-top-color: #dadfad;
}

.alert-success .alert-link {
  color: #272b06;
}

.alert-info {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-info hr {
  border-top-color: #f2f2f2;
}

.alert-info .alert-link {
  color: #6c6c6c;
}

.alert-warning {
  color: #846a00;
  background-color: #fff5cc;
  border-color: #fff1b8;
}

.alert-warning hr {
  border-top-color: #ffec9f;
}

.alert-warning .alert-link {
  color: #514100;
}

.alert-danger {
  color: #771c17;
  background-color: #fad7d5;
  border-color: #f8c6c4;
}

.alert-danger hr {
  border-top-color: #f5b0ad;
}

.alert-danger .alert-link {
  color: #4c120f;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-light hr {
  border-top-color: #efefef;
}

.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(219, 219, 219, 0.96);
  border-color: rgba(203, 203, 203, 0.944);
}

.alert-dark hr {
  border-top-color: rgba(190, 190, 190, 0.944);
}

.alert-dark .alert-link {
  color: rgba(0, 0, 0, 0.896);
}

.alert-blue {
  color: #17676f;
  background-color: #d5f4f7;
  border-color: #c4eff4;
}

.alert-blue hr {
  border-top-color: #afe9f0;
}

.alert-blue .alert-link {
  color: #0e4045;
}

.alert-bluedark {
  color: #063b5e;
  background-color: #cee3f0;
  border-color: #bbd8ea;
}

.alert-bluedark hr {
  border-top-color: #a8cde4;
}

.alert-bluedark .alert-link {
  color: #031d2e;
}

.alert-indigo {
  color: #031d2f;
  background-color: #cdd7de;
  border-color: #b9c7d1;
}

.alert-indigo hr {
  border-top-color: #aabbc7;
}

.alert-indigo .alert-link {
  color: black;
}

.alert-purple {
  color: #6e003f;
  background-color: #f6cce4;
  border-color: #f3b8da;
}

.alert-purple hr {
  border-top-color: #efa2cf;
}

.alert-purple .alert-link {
  color: #3b0022;
}

.alert-pink {
  color: #792049;
  background-color: #fad8e8;
  border-color: #f9c9df;
}

.alert-pink hr {
  border-top-color: #f6b2d1;
}

.alert-pink .alert-link {
  color: #511531;
}

.alert-red {
  color: #771c17;
  background-color: #fad7d5;
  border-color: #f8c6c4;
}

.alert-red hr {
  border-top-color: #f5b0ad;
}

.alert-red .alert-link {
  color: #4c120f;
}

.alert-reddark {
  color: #5c0309;
  background-color: #efcdcf;
  border-color: #e9b9bc;
}

.alert-reddark hr {
  border-top-color: #e3a6a9;
}

.alert-reddark .alert-link {
  color: #2b0104;
}

.alert-orange {
  color: #84420a;
  background-color: #ffe5d0;
  border-color: #fedbbd;
}

.alert-orange hr {
  border-top-color: #fecda4;
}

.alert-orange .alert-link {
  color: #552a06;
}

.alert-yellow {
  color: #846a00;
  background-color: #fff5cc;
  border-color: #fff1b8;
}

.alert-yellow hr {
  border-top-color: #ffec9f;
}

.alert-yellow .alert-link {
  color: #514100;
}

.alert-green {
  color: #4f570d;
  background-color: #eaedd1;
  border-color: #e2e6bf;
}

.alert-green hr {
  border-top-color: #dadfad;
}

.alert-green .alert-link {
  color: #272b06;
}

.alert-teal {
  color: #11694f;
  background-color: #d2f4ea;
  border-color: #c1f0e2;
}

.alert-teal hr {
  border-top-color: #acebd8;
}

.alert-teal .alert-link {
  color: #0a3d2e;
}

.alert-cyan {
  color: #39696d;
  background-color: #e2f4f6;
  border-color: #d6f0f2;
}

.alert-cyan hr {
  border-top-color: #c3e9ec;
}

.alert-cyan .alert-link {
  color: #27494c;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-gray-light {
  color: #797979;
  background-color: #fbfbfb;
  border-color: #f9f9f9;
}

.alert-gray-light hr {
  border-top-color: #ececec;
}

.alert-gray-light .alert-link {
  color: #606060;
}

.alert-gray {
  color: #6b6d6d;
  background-color: #f5f6f6;
  border-color: #f1f2f2;
}

.alert-gray hr {
  border-top-color: #e4e6e6;
}

.alert-gray .alert-link {
  color: #525353;
}

.alert-gray-dark {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(219, 219, 219, 0.96);
  border-color: rgba(203, 203, 203, 0.944);
}

.alert-gray-dark hr {
  border-top-color: rgba(190, 190, 190, 0.944);
}

.alert-gray-dark .alert-link {
  color: rgba(0, 0, 0, 0.896);
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-hub {
  color: #7b7b7b;
  background-color: #fbfbfb;
  border-color: #fafafa;
}

.alert-hub hr {
  border-top-color: #ededed;
}

.alert-hub .alert-link {
  color: #626262;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #F4F4F4;
  border-radius: 0.125rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2DC6D6;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.125rem;
}

.list-group-item-action {
  width: 100%;
  color: #707070;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #707070;
  text-decoration: none;
  background-color: #F9F9F9;
}

.list-group-item-action:active {
  color: #000;
  background-color: #F4F4F4;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #CED1D2;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.125rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #17676f;
  background-color: #c4eff4;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #17676f;
  background-color: #afe9f0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #17676f;
  border-color: #17676f;
}

.list-group-item-secondary {
  color: #39696d;
  background-color: #d6f0f2;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #39696d;
  background-color: #c3e9ec;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #39696d;
  border-color: #39696d;
}

.list-group-item-success {
  color: #4f570d;
  background-color: #e2e6bf;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #4f570d;
  background-color: #dadfad;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #4f570d;
  border-color: #4f570d;
}

.list-group-item-info {
  color: #858585;
  background-color: white;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-warning {
  color: #846a00;
  background-color: #fff1b8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #846a00;
  background-color: #ffec9f;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #846a00;
  border-color: #846a00;
}

.list-group-item-danger {
  color: #771c17;
  background-color: #f8c6c4;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #771c17;
  background-color: #f5b0ad;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #771c17;
  border-color: #771c17;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(203, 203, 203, 0.944);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(190, 190, 190, 0.944);
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.896);
  border-color: rgba(0, 0, 0, 0.896);
}

.list-group-item-blue {
  color: #17676f;
  background-color: #c4eff4;
}

.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #17676f;
  background-color: #afe9f0;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #17676f;
  border-color: #17676f;
}

.list-group-item-bluedark {
  color: #063b5e;
  background-color: #bbd8ea;
}

.list-group-item-bluedark.list-group-item-action:hover, .list-group-item-bluedark.list-group-item-action:focus {
  color: #063b5e;
  background-color: #a8cde4;
}

.list-group-item-bluedark.list-group-item-action.active {
  color: #fff;
  background-color: #063b5e;
  border-color: #063b5e;
}

.list-group-item-indigo {
  color: #031d2f;
  background-color: #b9c7d1;
}

.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #031d2f;
  background-color: #aabbc7;
}

.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #031d2f;
  border-color: #031d2f;
}

.list-group-item-purple {
  color: #6e003f;
  background-color: #f3b8da;
}

.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #6e003f;
  background-color: #efa2cf;
}

.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #6e003f;
  border-color: #6e003f;
}

.list-group-item-pink {
  color: #792049;
  background-color: #f9c9df;
}

.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #792049;
  background-color: #f6b2d1;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #792049;
  border-color: #792049;
}

.list-group-item-red {
  color: #771c17;
  background-color: #f8c6c4;
}

.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #771c17;
  background-color: #f5b0ad;
}

.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #771c17;
  border-color: #771c17;
}

.list-group-item-reddark {
  color: #5c0309;
  background-color: #e9b9bc;
}

.list-group-item-reddark.list-group-item-action:hover, .list-group-item-reddark.list-group-item-action:focus {
  color: #5c0309;
  background-color: #e3a6a9;
}

.list-group-item-reddark.list-group-item-action.active {
  color: #fff;
  background-color: #5c0309;
  border-color: #5c0309;
}

.list-group-item-orange {
  color: #84420a;
  background-color: #fedbbd;
}

.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #84420a;
  background-color: #fecda4;
}

.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #84420a;
  border-color: #84420a;
}

.list-group-item-yellow {
  color: #846a00;
  background-color: #fff1b8;
}

.list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
  color: #846a00;
  background-color: #ffec9f;
}

.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #846a00;
  border-color: #846a00;
}

.list-group-item-green {
  color: #4f570d;
  background-color: #e2e6bf;
}

.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #4f570d;
  background-color: #dadfad;
}

.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #4f570d;
  border-color: #4f570d;
}

.list-group-item-teal {
  color: #11694f;
  background-color: #c1f0e2;
}

.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: #11694f;
  background-color: #acebd8;
}

.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #11694f;
  border-color: #11694f;
}

.list-group-item-cyan {
  color: #39696d;
  background-color: #d6f0f2;
}

.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: #39696d;
  background-color: #c3e9ec;
}

.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #39696d;
  border-color: #39696d;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-gray-light {
  color: #797979;
  background-color: #f9f9f9;
}

.list-group-item-gray-light.list-group-item-action:hover, .list-group-item-gray-light.list-group-item-action:focus {
  color: #797979;
  background-color: #ececec;
}

.list-group-item-gray-light.list-group-item-action.active {
  color: #fff;
  background-color: #797979;
  border-color: #797979;
}

.list-group-item-gray {
  color: #6b6d6d;
  background-color: #f1f2f2;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #6b6d6d;
  background-color: #e4e6e6;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #6b6d6d;
  border-color: #6b6d6d;
}

.list-group-item-gray-dark {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(203, 203, 203, 0.944);
}

.list-group-item-gray-dark.list-group-item-action:hover, .list-group-item-gray-dark.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.896);
  background-color: rgba(190, 190, 190, 0.944);
}

.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.896);
  border-color: rgba(0, 0, 0, 0.896);
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-hub {
  color: #7b7b7b;
  background-color: #fafafa;
}

.list-group-item-hub.list-group-item-action:hover, .list-group-item-hub.list-group-item-action:focus {
  color: #7b7b7b;
  background-color: #ededed;
}

.list-group-item-hub.list-group-item-action.active {
  color: #fff;
  background-color: #7b7b7b;
  border-color: #7b7b7b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #CED1D2;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #F4F4F4;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5625rem 1.5625rem;
  border-bottom: 1px solid #EDEDED;
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}

.modal-header .close {
  padding: 1.5625rem 1.5625rem;
  margin: -1.5625rem -1.5625rem -1.5625rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #EDEDED;
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2DC6D6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #22a1ae !important;
}

.bg-secondary {
  background-color: #6DC9D1 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #46bbc5 !important;
}

.bg-success {
  background-color: #97A719 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #6f7b12 !important;
}

.bg-info {
  background-color: #fff !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #e6e6e6 !important;
}

.bg-warning {
  background-color: #FECC00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cba300 !important;
}

.bg-danger {
  background-color: #E5352D !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c62019 !important;
}

.bg-light {
  background-color: #F4F4F4 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dbdbdb !important;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-blue {
  background-color: #2DC6D6 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #22a1ae !important;
}

.bg-bluedark {
  background-color: #0B72B5 !important;
}

a.bg-bluedark:hover, a.bg-bluedark:focus,
button.bg-bluedark:hover,
button.bg-bluedark:focus {
  background-color: #085485 !important;
}

.bg-indigo {
  background-color: #06375B !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #031a2b !important;
}

.bg-purple {
  background-color: #D4007A !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #a1005d !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #E5352D !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #c62019 !important;
}

.bg-reddark {
  background-color: #B00511 !important;
}

a.bg-reddark:hover, a.bg-reddark:focus,
button.bg-reddark:hover,
button.bg-reddark:focus {
  background-color: #7e040c !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important;
}

.bg-yellow {
  background-color: #FECC00 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #cba300 !important;
}

.bg-green {
  background-color: #97A719 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #6f7b12 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #6DC9D1 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #46bbc5 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray-light {
  background-color: #E9E9E9 !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #d0d0d0 !important;
}

.bg-gray {
  background-color: #CED1D2 !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b3b8ba !important;
}

.bg-gray-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-hub {
  background-color: #EDEDED !important;
}

a.bg-hub:hover, a.bg-hub:focus,
button.bg-hub:hover,
button.bg-hub:focus {
  background-color: #d4d4d4 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #E9E9E9 !important;
}

.border-top {
  border-top: 1px solid #E9E9E9 !important;
}

.border-right {
  border-right: 1px solid #E9E9E9 !important;
}

.border-bottom {
  border-bottom: 1px solid #E9E9E9 !important;
}

.border-left {
  border-left: 1px solid #E9E9E9 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2DC6D6 !important;
}

.border-secondary {
  border-color: #6DC9D1 !important;
}

.border-success {
  border-color: #97A719 !important;
}

.border-info {
  border-color: #fff !important;
}

.border-warning {
  border-color: #FECC00 !important;
}

.border-danger {
  border-color: #E5352D !important;
}

.border-light {
  border-color: #F4F4F4 !important;
}

.border-dark {
  border-color: rgba(0, 0, 0, 0.8) !important;
}

.border-blue {
  border-color: #2DC6D6 !important;
}

.border-bluedark {
  border-color: #0B72B5 !important;
}

.border-indigo {
  border-color: #06375B !important;
}

.border-purple {
  border-color: #D4007A !important;
}

.border-pink {
  border-color: #e83e8c !important;
}

.border-red {
  border-color: #E5352D !important;
}

.border-reddark {
  border-color: #B00511 !important;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.border-yellow {
  border-color: #FECC00 !important;
}

.border-green {
  border-color: #97A719 !important;
}

.border-teal {
  border-color: #20c997 !important;
}

.border-cyan {
  border-color: #6DC9D1 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray-light {
  border-color: #E9E9E9 !important;
}

.border-gray {
  border-color: #CED1D2 !important;
}

.border-gray-dark {
  border-color: rgba(0, 0, 0, 0.8) !important;
}

.border-black {
  border-color: #000 !important;
}

.border-hub {
  border-color: #EDEDED !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2DC6D6 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1e8d99 !important;
}

.text-secondary {
  color: #6DC9D1 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #3aaeb8 !important;
}

.text-success {
  color: #97A719 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #5b640f !important;
}

.text-info {
  color: #fff !important;
}

a.text-info:hover, a.text-info:focus {
  color: #d9d9d9 !important;
}

.text-warning {
  color: #FECC00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b28f00 !important;
}

.text-danger {
  color: #E5352D !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b01c16 !important;
}

.text-light {
  color: #F4F4F4 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cecece !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.8) !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgba(0, 0, 0, 0.8) !important;
}

.text-blue {
  color: #2DC6D6 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #1e8d99 !important;
}

.text-bluedark {
  color: #0B72B5 !important;
}

a.text-bluedark:hover, a.text-bluedark:focus {
  color: #07456d !important;
}

.text-indigo {
  color: #06375B !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #010c13 !important;
}

.text-purple {
  color: #D4007A !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #88004e !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important;
}

.text-red {
  color: #E5352D !important;
}

a.text-red:hover, a.text-red:focus {
  color: #b01c16 !important;
}

.text-reddark {
  color: #B00511 !important;
}

a.text-reddark:hover, a.text-reddark:focus {
  color: #66030a !important;
}

.text-orange {
  color: #fd7e14 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #c35a02 !important;
}

.text-yellow {
  color: #FECC00 !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #b28f00 !important;
}

.text-green {
  color: #97A719 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #5b640f !important;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important;
}

.text-cyan {
  color: #6DC9D1 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #3aaeb8 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-gray-light {
  color: #E9E9E9 !important;
}

a.text-gray-light:hover, a.text-gray-light:focus {
  color: #c3c3c3 !important;
}

.text-gray {
  color: #CED1D2 !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #a6acad !important;
}

.text-gray-dark {
  color: rgba(0, 0, 0, 0.8) !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: rgba(0, 0, 0, 0.8) !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-hub {
  color: #EDEDED !important;
}

a.text-hub:hover, a.text-hub:focus {
  color: #c7c7c7 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #CED1D2 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #E9E9E9;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E9E9E9 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #CED1D2;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #CED1D2;
  }
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-abacus:before {
  content: "\f640";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acorn:before {
  content: "\f6ae";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-conditioner:before {
  content: "\f8f4";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-alarm-exclamation:before {
  content: "\f843";
}

.fa-alarm-plus:before {
  content: "\f844";
}

.fa-alarm-snooze:before {
  content: "\f845";
}

.fa-album:before {
  content: "\f89f";
}

.fa-album-collection:before {
  content: "\f8a0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alicorn:before {
  content: "\f6b0";
}

.fa-alien:before {
  content: "\f8f5";
}

.fa-alien-monster:before {
  content: "\f8f6";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-slash:before {
  content: "\f846";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-amp-guitar:before {
  content: "\f8a1";
}

.fa-analytics:before {
  content: "\f643";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angel:before {
  content: "\f779";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-crate:before {
  content: "\f6b1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-atom-alt:before {
  content: "\f5d3";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-axe:before {
  content: "\f6b2";
}

.fa-axe-battle:before {
  content: "\f6b3";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backpack:before {
  content: "\f5d4";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-bacteria:before {
  content: "\e059";
}

.fa-bacterium:before {
  content: "\e05a";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-badge-dollar:before {
  content: "\f645";
}

.fa-badge-percent:before {
  content: "\f646";
}

.fa-badge-sheriff:before {
  content: "\f8a2";
}

.fa-badger-honey:before {
  content: "\f6b4";
}

.fa-bags-shopping:before {
  content: "\f847";
}

.fa-bahai:before {
  content: "\f666";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-balance-scale-left:before {
  content: "\f515";
}

.fa-balance-scale-right:before {
  content: "\f516";
}

.fa-ball-pile:before {
  content: "\f77e";
}

.fa-ballot:before {
  content: "\f732";
}

.fa-ballot-check:before {
  content: "\f733";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-banjo:before {
  content: "\f8a3";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-barcode-alt:before {
  content: "\f463";
}

.fa-barcode-read:before {
  content: "\f464";
}

.fa-barcode-scan:before {
  content: "\f465";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball:before {
  content: "\f432";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-basketball-hoop:before {
  content: "\f435";
}

.fa-bat:before {
  content: "\f6b5";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-bed-alt:before {
  content: "\f8f7";
}

.fa-bed-bunk:before {
  content: "\f8f8";
}

.fa-bed-empty:before {
  content: "\f8f9";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-exclamation:before {
  content: "\f848";
}

.fa-bell-on:before {
  content: "\f8fa";
}

.fa-bell-plus:before {
  content: "\f849";
}

.fa-bell-school:before {
  content: "\f5d5";
}

.fa-bell-school-slash:before {
  content: "\f5d6";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bells:before {
  content: "\f77f";
}

.fa-betamax:before {
  content: "\f8a4";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-biking:before {
  content: "\f84a";
}

.fa-biking-mountain:before {
  content: "\f84b";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blanket:before {
  content: "\f498";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blinds:before {
  content: "\f8fb";
}

.fa-blinds-open:before {
  content: "\f8fc";
}

.fa-blinds-raised:before {
  content: "\f8fd";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bone-break:before {
  content: "\f5d8";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-alt:before {
  content: "\f5d9";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-heart:before {
  content: "\f499";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-book-spells:before {
  content: "\f6b8";
}

.fa-book-user:before {
  content: "\f7e7";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-books:before {
  content: "\f5db";
}

.fa-books-medical:before {
  content: "\f7e8";
}

.fa-boombox:before {
  content: "\f8a5";
}

.fa-boot:before {
  content: "\f782";
}

.fa-booth-curtain:before {
  content: "\f734";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-border-all:before {
  content: "\f84c";
}

.fa-border-bottom:before {
  content: "\f84d";
}

.fa-border-center-h:before {
  content: "\f89c";
}

.fa-border-center-v:before {
  content: "\f89d";
}

.fa-border-inner:before {
  content: "\f84e";
}

.fa-border-left:before {
  content: "\f84f";
}

.fa-border-none:before {
  content: "\f850";
}

.fa-border-outer:before {
  content: "\f851";
}

.fa-border-right:before {
  content: "\f852";
}

.fa-border-style:before {
  content: "\f853";
}

.fa-border-style-alt:before {
  content: "\f854";
}

.fa-border-top:before {
  content: "\f855";
}

.fa-bow-arrow:before {
  content: "\f6b9";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-bowling-pins:before {
  content: "\f437";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-alt:before {
  content: "\f49a";
}

.fa-box-ballot:before {
  content: "\f735";
}

.fa-box-check:before {
  content: "\f467";
}

.fa-box-fragile:before {
  content: "\f49b";
}

.fa-box-full:before {
  content: "\f49c";
}

.fa-box-heart:before {
  content: "\f49d";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-box-tissue:before {
  content: "\e05b";
}

.fa-box-up:before {
  content: "\f49f";
}

.fa-box-usd:before {
  content: "\f4a0";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-boxes-alt:before {
  content: "\f4a1";
}

.fa-boxing-glove:before {
  content: "\f438";
}

.fa-brackets:before {
  content: "\f7e9";
}

.fa-brackets-curly:before {
  content: "\f7ea";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-loaf:before {
  content: "\f7eb";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-bring-forward:before {
  content: "\f856";
}

.fa-bring-front:before {
  content: "\f857";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-bullseye-arrow:before {
  content: "\f648";
}

.fa-bullseye-pointer:before {
  content: "\f649";
}

.fa-burger-soda:before {
  content: "\f858";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-burrito:before {
  content: "\f7ed";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-bus-school:before {
  content: "\f5dd";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-cabinet-filing:before {
  content: "\f64b";
}

.fa-cactus:before {
  content: "\f8a7";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calculator-alt:before {
  content: "\f64c";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-star:before {
  content: "\f736";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camcorder:before {
  content: "\f8a8";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-home:before {
  content: "\f8fe";
}

.fa-camera-movie:before {
  content: "\f8a9";
}

.fa-camera-polaroid:before {
  content: "\f8aa";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campfire:before {
  content: "\f6ba";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candle-holder:before {
  content: "\f6bc";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-candy-corn:before {
  content: "\f6bd";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-building:before {
  content: "\f859";
}

.fa-car-bump:before {
  content: "\f5e0";
}

.fa-car-bus:before {
  content: "\f85a";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-garage:before {
  content: "\f5e2";
}

.fa-car-mechanic:before {
  content: "\f5e3";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-car-tilt:before {
  content: "\f5e5";
}

.fa-car-wash:before {
  content: "\f5e6";
}

.fa-caravan:before {
  content: "\f8ff";
}

.fa-caravan-alt:before {
  content: "\e000";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cars:before {
  content: "\f85b";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cassette-tape:before {
  content: "\f8ab";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cat-space:before {
  content: "\e001";
}

.fa-cauldron:before {
  content: "\f6bf";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cctv:before {
  content: "\f8ac";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chair-office:before {
  content: "\f6c1";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-line-down:before {
  content: "\f64d";
}

.fa-chart-network:before {
  content: "\f78a";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-chart-pie-alt:before {
  content: "\f64e";
}

.fa-chart-scatter:before {
  content: "\f7ee";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-cheese-swiss:before {
  content: "\f7f0";
}

.fa-cheeseburger:before {
  content: "\f7f1";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-bishop-alt:before {
  content: "\f43b";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-clock:before {
  content: "\f43d";
}

.fa-chess-clock-alt:before {
  content: "\f43e";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-king-alt:before {
  content: "\f440";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-knight-alt:before {
  content: "\f442";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-pawn-alt:before {
  content: "\f444";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-queen-alt:before {
  content: "\f446";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chess-rook-alt:before {
  content: "\f448";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chimney:before {
  content: "\f78b";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clarinet:before {
  content: "\f8ad";
}

.fa-claw-marks:before {
  content: "\f6c2";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clipboard-list-check:before {
  content: "\f737";
}

.fa-clipboard-prescription:before {
  content: "\f5e8";
}

.fa-clipboard-user:before {
  content: "\f7f3";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-drizzle:before {
  content: "\f738";
}

.fa-cloud-hail:before {
  content: "\f739";
}

.fa-cloud-hail-mixed:before {
  content: "\f73a";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-music:before {
  content: "\f8ae";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-rainbow:before {
  content: "\f73e";
}

.fa-cloud-showers:before {
  content: "\f73f";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sleet:before {
  content: "\f741";
}

.fa-cloud-snow:before {
  content: "\f742";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-clouds:before {
  content: "\f744";
}

.fa-clouds-moon:before {
  content: "\f745";
}

.fa-clouds-sun:before {
  content: "\f746";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-coffee-pot:before {
  content: "\e002";
}

.fa-coffee-togo:before {
  content: "\f6c5";
}

.fa-coffin:before {
  content: "\f6c6";
}

.fa-coffin-cross:before {
  content: "\e051";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coin:before {
  content: "\f85c";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comet:before {
  content: "\e003";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-alt-check:before {
  content: "\f4a2";
}

.fa-comment-alt-dollar:before {
  content: "\f650";
}

.fa-comment-alt-dots:before {
  content: "\f4a3";
}

.fa-comment-alt-edit:before {
  content: "\f4a4";
}

.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}

.fa-comment-alt-lines:before {
  content: "\f4a6";
}

.fa-comment-alt-medical:before {
  content: "\f7f4";
}

.fa-comment-alt-minus:before {
  content: "\f4a7";
}

.fa-comment-alt-music:before {
  content: "\f8af";
}

.fa-comment-alt-plus:before {
  content: "\f4a8";
}

.fa-comment-alt-slash:before {
  content: "\f4a9";
}

.fa-comment-alt-smile:before {
  content: "\f4aa";
}

.fa-comment-alt-times:before {
  content: "\f4ab";
}

.fa-comment-check:before {
  content: "\f4ac";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-edit:before {
  content: "\f4ae";
}

.fa-comment-exclamation:before {
  content: "\f4af";
}

.fa-comment-lines:before {
  content: "\f4b0";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-minus:before {
  content: "\f4b1";
}

.fa-comment-music:before {
  content: "\f8b0";
}

.fa-comment-plus:before {
  content: "\f4b2";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comment-smile:before {
  content: "\f4b4";
}

.fa-comment-times:before {
  content: "\f4b5";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-alt:before {
  content: "\f4b6";
}

.fa-comments-alt-dollar:before {
  content: "\f652";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compass-slash:before {
  content: "\f5e9";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-alt:before {
  content: "\f422";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-computer-classic:before {
  content: "\f8b1";
}

.fa-computer-speaker:before {
  content: "\f8b2";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-construction:before {
  content: "\f85d";
}

.fa-container-storage:before {
  content: "\f4b7";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-conveyor-belt:before {
  content: "\f46e";
}

.fa-conveyor-belt-alt:before {
  content: "\f46f";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-corn:before {
  content: "\f6c7";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cow:before {
  content: "\f6c8";
}

.fa-cowbell:before {
  content: "\f8b3";
}

.fa-cowbell-more:before {
  content: "\f8b4";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-cricket:before {
  content: "\f449";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-croissant:before {
  content: "\f7f6";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-crutches:before {
  content: "\f7f8";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-curling:before {
  content: "\f44a";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dagger:before {
  content: "\f6cb";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-debug:before {
  content: "\f7f9";
}

.fa-deer:before {
  content: "\f78e";
}

.fa-deer-rudolph:before {
  content: "\f78f";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dewpoint:before {
  content: "\f748";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d10:before {
  content: "\f6cd";
}

.fa-dice-d12:before {
  content: "\f6ce";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d4:before {
  content: "\f6d0";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-d8:before {
  content: "\f6d2";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digging:before {
  content: "\f85e";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-diploma:before {
  content: "\f5ea";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-disc-drive:before {
  content: "\f8b5";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-disease:before {
  content: "\f7fa";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-do-not-enter:before {
  content: "\f5ec";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dog-leashed:before {
  content: "\f6d4";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-empty:before {
  content: "\f473";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-dolly-flatbed-alt:before {
  content: "\f475";
}

.fa-dolly-flatbed-empty:before {
  content: "\f476";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-circle:before {
  content: "\f5ed";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-draw-square:before {
  content: "\f5ef";
}

.fa-dreidel:before {
  content: "\f792";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-drone:before {
  content: "\f85f";
}

.fa-drone-alt:before {
  content: "\f860";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick:before {
  content: "\f6d6";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dryer:before {
  content: "\f861";
}

.fa-dryer-alt:before {
  content: "\f862";
}

.fa-duck:before {
  content: "\f6d8";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-ear:before {
  content: "\f5f0";
}

.fa-ear-muffs:before {
  content: "\f795";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-eclipse:before {
  content: "\f749";
}

.fa-eclipse-alt:before {
  content: "\f74a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-egg-fried:before {
  content: "\f7fc";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-elephant:before {
  content: "\f6da";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-empty-set:before {
  content: "\f656";
}

.fa-engine-warning:before {
  content: "\f5f2";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-dollar:before {
  content: "\f657";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-alt:before {
  content: "\f424";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-evil:before {
  content: "\f6db";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fan:before {
  content: "\f863";
}

.fa-fan-table:before {
  content: "\e004";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-farm:before {
  content: "\f864";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-faucet:before {
  content: "\e005";
}

.fa-faucet-drip:before {
  content: "\e006";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-field-hockey:before {
  content: "\f44c";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-certificate:before {
  content: "\f5f3";
}

.fa-file-chart-line:before {
  content: "\f659";
}

.fa-file-chart-pie:before {
  content: "\f65a";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-music:before {
  content: "\f8b6";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-search:before {
  content: "\f865";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-spreadsheet:before {
  content: "\f65b";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-user:before {
  content: "\f65c";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-files-medical:before {
  content: "\f7fd";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-film-canister:before {
  content: "\f8b7";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-fire-smoke:before {
  content: "\f74b";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-fireplace:before {
  content: "\f79a";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fish-cooked:before {
  content: "\f7fe";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-alt:before {
  content: "\f74c";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flame:before {
  content: "\f6df";
}

.fa-flashlight:before {
  content: "\f8b8";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flask-poison:before {
  content: "\f6e0";
}

.fa-flask-potion:before {
  content: "\f6e1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flower:before {
  content: "\f7ff";
}

.fa-flower-daffodil:before {
  content: "\f800";
}

.fa-flower-tulip:before {
  content: "\f801";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-flute:before {
  content: "\f8b9";
}

.fa-flux-capacitor:before {
  content: "\f8ba";
}

.fa-fly:before {
  content: "\f417";
}

.fa-fog:before {
  content: "\f74e";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-download:before {
  content: "\e053";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-folder-times:before {
  content: "\f65f";
}

.fa-folder-tree:before {
  content: "\f802";
}

.fa-folder-upload:before {
  content: "\e054";
}

.fa-folders:before {
  content: "\f660";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-font-case:before {
  content: "\f866";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-football-helmet:before {
  content: "\f44f";
}

.fa-forklift:before {
  content: "\f47a";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-fragile:before {
  content: "\f4bb";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-french-fries:before {
  content: "\f803";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frosty-head:before {
  content: "\f79b";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-function:before {
  content: "\f661";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-galaxy:before {
  content: "\e008";
}

.fa-game-board:before {
  content: "\f867";
}

.fa-game-board-alt:before {
  content: "\f868";
}

.fa-game-console-handheld:before {
  content: "\f8bb";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gamepad-alt:before {
  content: "\f8bc";
}

.fa-garage:before {
  content: "\e009";
}

.fa-garage-car:before {
  content: "\e00a";
}

.fa-garage-open:before {
  content: "\e00b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gas-pump-slash:before {
  content: "\f5f4";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gift-card:before {
  content: "\f663";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-gingerbread-man:before {
  content: "\f79d";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass:before {
  content: "\f804";
}

.fa-glass-champagne:before {
  content: "\f79e";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-citrus:before {
  content: "\f869";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glasses-alt:before {
  content: "\f5f5";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-globe-snow:before {
  content: "\f7a3";
}

.fa-globe-stand:before {
  content: "\f5f6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-golf-club:before {
  content: "\f451";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gramophone:before {
  content: "\f8bd";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-guitar-electric:before {
  content: "\f8be";
}

.fa-guitars:before {
  content: "\f8bf";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-h4:before {
  content: "\f86a";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hammer-war:before {
  content: "\f6e4";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-heart:before {
  content: "\f4bc";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-box:before {
  content: "\f47b";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-magic:before {
  content: "\f6e5";
}

.fa-hand-holding-medical:before {
  content: "\e05c";
}

.fa-hand-holding-seedling:before {
  content: "\f4bf";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-holding-water:before {
  content: "\f4c1";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-receiving:before {
  content: "\f47c";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-sparkles:before {
  content: "\e05d";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-heart:before {
  content: "\f4c3";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-hands-usd:before {
  content: "\f4c5";
}

.fa-hands-wash:before {
  content: "\e05e";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-handshake-alt:before {
  content: "\f4c6";
}

.fa-handshake-alt-slash:before {
  content: "\e05f";
}

.fa-handshake-slash:before {
  content: "\e060";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-chef:before {
  content: "\f86b";
}

.fa-hat-cowboy:before {
  content: "\f8c0";
}

.fa-hat-cowboy-side:before {
  content: "\f8c1";
}

.fa-hat-santa:before {
  content: "\f7a7";
}

.fa-hat-winter:before {
  content: "\f7a8";
}

.fa-hat-witch:before {
  content: "\f6e7";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-head-side:before {
  content: "\f6e9";
}

.fa-head-side-brain:before {
  content: "\f808";
}

.fa-head-side-cough:before {
  content: "\e061";
}

.fa-head-side-cough-slash:before {
  content: "\e062";
}

.fa-head-side-headphones:before {
  content: "\f8c2";
}

.fa-head-side-mask:before {
  content: "\e063";
}

.fa-head-side-medical:before {
  content: "\f809";
}

.fa-head-side-virus:before {
  content: "\e064";
}

.fa-head-vr:before {
  content: "\f6ea";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heart-circle:before {
  content: "\f4c7";
}

.fa-heart-rate:before {
  content: "\f5f8";
}

.fa-heart-square:before {
  content: "\f4c8";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-heat:before {
  content: "\e00c";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-helmet-battle:before {
  content: "\f6eb";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hockey-mask:before {
  content: "\f6ee";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-hockey-sticks:before {
  content: "\f454";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-home-alt:before {
  content: "\f80a";
}

.fa-home-heart:before {
  content: "\f4c9";
}

.fa-home-lg:before {
  content: "\f80b";
}

.fa-home-lg-alt:before {
  content: "\f80c";
}

.fa-hood-cloak:before {
  content: "\f6ef";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-horizontal-rule:before {
  content: "\f86c";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-horse-saddle:before {
  content: "\f8c3";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hospital-user:before {
  content: "\f80d";
}

.fa-hospitals:before {
  content: "\f80e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house:before {
  content: "\e00d";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-house-day:before {
  content: "\e00e";
}

.fa-house-flood:before {
  content: "\f74f";
}

.fa-house-leave:before {
  content: "\e00f";
}

.fa-house-night:before {
  content: "\e010";
}

.fa-house-return:before {
  content: "\e011";
}

.fa-house-signal:before {
  content: "\e012";
}

.fa-house-user:before {
  content: "\e065";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-humidity:before {
  content: "\f750";
}

.fa-hurricane:before {
  content: "\f751";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-ice-skate:before {
  content: "\f7ac";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-icons:before {
  content: "\f86d";
}

.fa-icons-alt:before {
  content: "\f86e";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-image-polaroid:before {
  content: "\f8c4";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-inhaler:before {
  content: "\f5f9";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-integral:before {
  content: "\f667";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-intersection:before {
  content: "\f668";
}

.fa-inventory:before {
  content: "\f480";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-island-tropical:before {
  content: "\f811";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-joystick:before {
  content: "\f8c5";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-jug:before {
  content: "\f8c6";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-kazoo:before {
  content: "\f8c7";
}

.fa-kerning:before {
  content: "\f86f";
}

.fa-key:before {
  content: "\f084";
}

.fa-key-skeleton:before {
  content: "\f6f3";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-keynote:before {
  content: "\f66c";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kidneys:before {
  content: "\f5fb";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kite:before {
  content: "\f6f4";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-knife-kitchen:before {
  content: "\f6f5";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-lambda:before {
  content: "\f66e";
}

.fa-lamp:before {
  content: "\f4ca";
}

.fa-lamp-desk:before {
  content: "\e014";
}

.fa-lamp-floor:before {
  content: "\e015";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-landmark-alt:before {
  content: "\f752";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-house:before {
  content: "\e066";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lasso:before {
  content: "\f8c8";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-layer-minus:before {
  content: "\f5fe";
}

.fa-layer-plus:before {
  content: "\f5ff";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leaf-heart:before {
  content: "\f4cb";
}

.fa-leaf-maple:before {
  content: "\f6f6";
}

.fa-leaf-oak:before {
  content: "\f6f7";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-light-ceiling:before {
  content: "\e016";
}

.fa-light-switch:before {
  content: "\e017";
}

.fa-light-switch-off:before {
  content: "\e018";
}

.fa-light-switch-on:before {
  content: "\e019";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-lightbulb-dollar:before {
  content: "\f670";
}

.fa-lightbulb-exclamation:before {
  content: "\f671";
}

.fa-lightbulb-on:before {
  content: "\f672";
}

.fa-lightbulb-slash:before {
  content: "\f673";
}

.fa-lights-holiday:before {
  content: "\f7b2";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-line-columns:before {
  content: "\f870";
}

.fa-line-height:before {
  content: "\f871";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lips:before {
  content: "\f600";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-music:before {
  content: "\f8c9";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location:before {
  content: "\f601";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-location-circle:before {
  content: "\f602";
}

.fa-location-slash:before {
  content: "\f603";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-loveseat:before {
  content: "\f4cc";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luchador:before {
  content: "\f455";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lungs:before {
  content: "\f604";
}

.fa-lungs-virus:before {
  content: "\e067";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-mace:before {
  content: "\f6f8";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailbox:before {
  content: "\f813";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-mandolin:before {
  content: "\f6f9";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-marker-alt-slash:before {
  content: "\f605";
}

.fa-map-marker-check:before {
  content: "\f606";
}

.fa-map-marker-edit:before {
  content: "\f607";
}

.fa-map-marker-exclamation:before {
  content: "\f608";
}

.fa-map-marker-minus:before {
  content: "\f609";
}

.fa-map-marker-plus:before {
  content: "\f60a";
}

.fa-map-marker-question:before {
  content: "\f60b";
}

.fa-map-marker-slash:before {
  content: "\f60c";
}

.fa-map-marker-smile:before {
  content: "\f60d";
}

.fa-map-marker-times:before {
  content: "\f60e";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-meat:before {
  content: "\f814";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaphone:before {
  content: "\f675";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microphone-stand:before {
  content: "\f8cb";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-microwave:before {
  content: "\e01b";
}

.fa-mind-share:before {
  content: "\f677";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mistletoe:before {
  content: "\f7b4";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-money-check-edit:before {
  content: "\f872";
}

.fa-money-check-edit-alt:before {
  content: "\f873";
}

.fa-monitor-heart-rate:before {
  content: "\f611";
}

.fa-monkey:before {
  content: "\f6fb";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-moon-cloud:before {
  content: "\f754";
}

.fa-moon-stars:before {
  content: "\f755";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mountains:before {
  content: "\f6fd";
}

.fa-mouse:before {
  content: "\f8cc";
}

.fa-mouse-alt:before {
  content: "\f8cd";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mp3-player:before {
  content: "\f8ce";
}

.fa-mug:before {
  content: "\f874";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-mug-marshmallows:before {
  content: "\f7b7";
}

.fa-mug-tea:before {
  content: "\f875";
}

.fa-music:before {
  content: "\f001";
}

.fa-music-alt:before {
  content: "\f8cf";
}

.fa-music-alt-slash:before {
  content: "\f8d0";
}

.fa-music-slash:before {
  content: "\f8d1";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-narwhal:before {
  content: "\f6fe";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-oil-temp:before {
  content: "\f614";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-omega:before {
  content: "\f67a";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-ornament:before {
  content: "\f7b8";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-outlet:before {
  content: "\e01c";
}

.fa-oven:before {
  content: "\e01d";
}

.fa-overline:before {
  content: "\f876";
}

.fa-page-break:before {
  content: "\f877";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-brush-alt:before {
  content: "\f5a9";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-pallet-alt:before {
  content: "\f483";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paragraph-rtl:before {
  content: "\f878";
}

.fa-parking:before {
  content: "\f540";
}

.fa-parking-circle:before {
  content: "\f615";
}

.fa-parking-circle-slash:before {
  content: "\f616";
}

.fa-parking-slash:before {
  content: "\f617";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paw-alt:before {
  content: "\f701";
}

.fa-paw-claws:before {
  content: "\f702";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pegasus:before {
  content: "\f703";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-paintbrush:before {
  content: "\f618";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-pennant:before {
  content: "\f456";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-arrows:before {
  content: "\e068";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-person-carry:before {
  content: "\f4cf";
}

.fa-person-dolly:before {
  content: "\f4d0";
}

.fa-person-dolly-empty:before {
  content: "\f4d1";
}

.fa-person-sign:before {
  content: "\f757";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-alt:before {
  content: "\f879";
}

.fa-phone-laptop:before {
  content: "\f87a";
}

.fa-phone-office:before {
  content: "\f67d";
}

.fa-phone-plus:before {
  content: "\f4d2";
}

.fa-phone-rotary:before {
  content: "\f8d3";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-square-alt:before {
  content: "\f87b";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-photo-video:before {
  content: "\f87c";
}

.fa-php:before {
  content: "\f457";
}

.fa-pi:before {
  content: "\f67e";
}

.fa-piano:before {
  content: "\f8d4";
}

.fa-piano-keyboard:before {
  content: "\f8d5";
}

.fa-pie:before {
  content: "\f705";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pig:before {
  content: "\f706";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza:before {
  content: "\f817";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-plane-slash:before {
  content: "\e069";
}

.fa-planet-moon:before {
  content: "\e01f";
}

.fa-planet-ringed:before {
  content: "\e020";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-podium:before {
  content: "\f680";
}

.fa-podium-star:before {
  content: "\f758";
}

.fa-police-box:before {
  content: "\e021";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poll-people:before {
  content: "\f759";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-popcorn:before {
  content: "\f819";
}

.fa-portal-enter:before {
  content: "\e022";
}

.fa-portal-exit:before {
  content: "\e023";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-presentation:before {
  content: "\f685";
}

.fa-print:before {
  content: "\f02f";
}

.fa-print-search:before {
  content: "\f81a";
}

.fa-print-slash:before {
  content: "\f686";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-projector:before {
  content: "\f8d6";
}

.fa-pump-medical:before {
  content: "\e06a";
}

.fa-pump-soap:before {
  content: "\e06b";
}

.fa-pumpkin:before {
  content: "\f707";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-rabbit:before {
  content: "\f708";
}

.fa-rabbit-fast:before {
  content: "\f709";
}

.fa-racquet:before {
  content: "\f45a";
}

.fa-radar:before {
  content: "\e024";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-radio:before {
  content: "\f8d7";
}

.fa-radio-alt:before {
  content: "\f8d8";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-raindrops:before {
  content: "\f75c";
}

.fa-ram:before {
  content: "\f70a";
}

.fa-ramp-loading:before {
  content: "\f4d4";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-raygun:before {
  content: "\e025";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-record-vinyl:before {
  content: "\f8d9";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-refrigerator:before {
  content: "\e026";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-remove-format:before {
  content: "\f87d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-rings-wedding:before {
  content: "\f81b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocket-launch:before {
  content: "\e027";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-route-highway:before {
  content: "\f61a";
}

.fa-route-interstate:before {
  content: "\f61b";
}

.fa-router:before {
  content: "\f8da";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-triangle:before {
  content: "\f61c";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-rv:before {
  content: "\f7be";
}

.fa-sack:before {
  content: "\f81c";
}

.fa-sack-dollar:before {
  content: "\f81d";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salad:before {
  content: "\f81e";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sandwich:before {
  content: "\f81f";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-sausage:before {
  content: "\f820";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-sax-hot:before {
  content: "\f8db";
}

.fa-saxophone:before {
  content: "\f8dc";
}

.fa-scalpel:before {
  content: "\f61d";
}

.fa-scalpel-path:before {
  content: "\f61e";
}

.fa-scanner:before {
  content: "\f488";
}

.fa-scanner-image:before {
  content: "\f8f3";
}

.fa-scanner-keyboard:before {
  content: "\f489";
}

.fa-scanner-touchscreen:before {
  content: "\f48a";
}

.fa-scarecrow:before {
  content: "\f70d";
}

.fa-scarf:before {
  content: "\f7c1";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-scroll-old:before {
  content: "\f70f";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-scythe:before {
  content: "\f710";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-send-back:before {
  content: "\f87e";
}

.fa-send-backward:before {
  content: "\f87f";
}

.fa-sensor:before {
  content: "\e028";
}

.fa-sensor-alert:before {
  content: "\e029";
}

.fa-sensor-fire:before {
  content: "\e02a";
}

.fa-sensor-on:before {
  content: "\e02b";
}

.fa-sensor-smoke:before {
  content: "\e02c";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-sheep:before {
  content: "\f711";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-shield-cross:before {
  content: "\f712";
}

.fa-shield-virus:before {
  content: "\e06c";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shipping-timed:before {
  content: "\f48c";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shish-kebab:before {
  content: "\f821";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shovel:before {
  content: "\f713";
}

.fa-shovel-snow:before {
  content: "\f7c3";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shredder:before {
  content: "\f68a";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-shuttlecock:before {
  content: "\f45b";
}

.fa-sickle:before {
  content: "\f822";
}

.fa-sigma:before {
  content: "\f68b";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signal-1:before {
  content: "\f68c";
}

.fa-signal-2:before {
  content: "\f68d";
}

.fa-signal-3:before {
  content: "\f68e";
}

.fa-signal-4:before {
  content: "\f68f";
}

.fa-signal-alt:before {
  content: "\f690";
}

.fa-signal-alt-1:before {
  content: "\f691";
}

.fa-signal-alt-2:before {
  content: "\f692";
}

.fa-signal-alt-3:before {
  content: "\f693";
}

.fa-signal-alt-slash:before {
  content: "\f694";
}

.fa-signal-slash:before {
  content: "\f695";
}

.fa-signal-stream:before {
  content: "\f8dd";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sink:before {
  content: "\e06d";
}

.fa-siren:before {
  content: "\e02d";
}

.fa-siren-on:before {
  content: "\e02e";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-skeleton:before {
  content: "\f620";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-ski-jump:before {
  content: "\f7c7";
}

.fa-ski-lift:before {
  content: "\f7c8";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-cow:before {
  content: "\f8de";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sledding:before {
  content: "\f7cb";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-plus:before {
  content: "\f5b9";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoke:before {
  content: "\f760";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snake:before {
  content: "\f716";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snooze:before {
  content: "\f880";
}

.fa-snow-blowing:before {
  content: "\f761";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowflakes:before {
  content: "\f7cf";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowmobile:before {
  content: "\f7d1";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-soap:before {
  content: "\e06e";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-solar-system:before {
  content: "\e02f";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-down-alt:before {
  content: "\f881";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-alpha-up-alt:before {
  content: "\f882";
}

.fa-sort-alt:before {
  content: "\f883";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-down-alt:before {
  content: "\f884";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-amount-up-alt:before {
  content: "\f885";
}

.fa-sort-circle:before {
  content: "\e030";
}

.fa-sort-circle-down:before {
  content: "\e031";
}

.fa-sort-circle-up:before {
  content: "\e032";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-down-alt:before {
  content: "\f886";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-numeric-up-alt:before {
  content: "\f887";
}

.fa-sort-shapes-down:before {
  content: "\f888";
}

.fa-sort-shapes-down-alt:before {
  content: "\f889";
}

.fa-sort-shapes-up:before {
  content: "\f88a";
}

.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}

.fa-sort-size-down:before {
  content: "\f88c";
}

.fa-sort-size-down-alt:before {
  content: "\f88d";
}

.fa-sort-size-up:before {
  content: "\f88e";
}

.fa-sort-size-up-alt:before {
  content: "\f88f";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-soup:before {
  content: "\f823";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-space-station-moon:before {
  content: "\e033";
}

.fa-space-station-moon-alt:before {
  content: "\e034";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-sparkles:before {
  content: "\f890";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker:before {
  content: "\f8df";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-speakers:before {
  content: "\f8e0";
}

.fa-spell-check:before {
  content: "\f891";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spider-black-widow:before {
  content: "\f718";
}

.fa-spider-web:before {
  content: "\f719";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-sprinkler:before {
  content: "\e035";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root:before {
  content: "\f697";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-squirrel:before {
  content: "\f71a";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staff:before {
  content: "\f71b";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-christmas:before {
  content: "\f7d4";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-star-shooting:before {
  content: "\e036";
}

.fa-starfighter:before {
  content: "\e037";
}

.fa-starfighter-alt:before {
  content: "\e038";
}

.fa-stars:before {
  content: "\f762";
}

.fa-starship:before {
  content: "\e039";
}

.fa-starship-freighter:before {
  content: "\e03a";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steak:before {
  content: "\f824";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-steering-wheel:before {
  content: "\f622";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stocking:before {
  content: "\f7d5";
}

.fa-stomach:before {
  content: "\f623";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-stopwatch-20:before {
  content: "\e06f";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-store-alt-slash:before {
  content: "\e070";
}

.fa-store-slash:before {
  content: "\e071";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-stretcher:before {
  content: "\f825";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-sun-cloud:before {
  content: "\f763";
}

.fa-sun-dust:before {
  content: "\f764";
}

.fa-sun-haze:before {
  content: "\f765";
}

.fa-sunglasses:before {
  content: "\f892";
}

.fa-sunrise:before {
  content: "\f766";
}

.fa-sunset:before {
  content: "\f767";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-sword:before {
  content: "\f71c";
}

.fa-sword-laser:before {
  content: "\e03b";
}

.fa-sword-laser-alt:before {
  content: "\e03c";
}

.fa-swords:before {
  content: "\f71d";
}

.fa-swords-laser:before {
  content: "\e03d";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tablet-rugged:before {
  content: "\f48f";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tachometer-alt-average:before {
  content: "\f624";
}

.fa-tachometer-alt-fast:before {
  content: "\f625";
}

.fa-tachometer-alt-fastest:before {
  content: "\f626";
}

.fa-tachometer-alt-slow:before {
  content: "\f627";
}

.fa-tachometer-alt-slowest:before {
  content: "\f628";
}

.fa-tachometer-average:before {
  content: "\f629";
}

.fa-tachometer-fast:before {
  content: "\f62a";
}

.fa-tachometer-fastest:before {
  content: "\f62b";
}

.fa-tachometer-slow:before {
  content: "\f62c";
}

.fa-tachometer-slowest:before {
  content: "\f62d";
}

.fa-taco:before {
  content: "\f826";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tally:before {
  content: "\f69c";
}

.fa-tanakh:before {
  content: "\f827";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-tasks-alt:before {
  content: "\f828";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-telescope:before {
  content: "\e03e";
}

.fa-temperature-down:before {
  content: "\e03f";
}

.fa-temperature-frigid:before {
  content: "\f768";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-hot:before {
  content: "\f76a";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-temperature-up:before {
  content: "\e040";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-tennis-ball:before {
  content: "\f45e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text:before {
  content: "\f893";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-size:before {
  content: "\f894";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-theta:before {
  content: "\f69e";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-thunderstorm:before {
  content: "\f76c";
}

.fa-thunderstorm-moon:before {
  content: "\f76d";
}

.fa-thunderstorm-sun:before {
  content: "\f76e";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-tilde:before {
  content: "\f69f";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tire:before {
  content: "\f631";
}

.fa-tire-flat:before {
  content: "\f632";
}

.fa-tire-pressure-warning:before {
  content: "\f633";
}

.fa-tire-rugged:before {
  content: "\f634";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toilet-paper-alt:before {
  content: "\f71f";
}

.fa-toilet-paper-slash:before {
  content: "\e072";
}

.fa-tombstone:before {
  content: "\f720";
}

.fa-tombstone-alt:before {
  content: "\f721";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-toothbrush:before {
  content: "\f635";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tornado:before {
  content: "\f76f";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-cone:before {
  content: "\f636";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-traffic-light-go:before {
  content: "\f638";
}

.fa-traffic-light-slow:before {
  content: "\f639";
}

.fa-traffic-light-stop:before {
  content: "\f63a";
}

.fa-trailer:before {
  content: "\e041";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-transporter:before {
  content: "\e042";
}

.fa-transporter-1:before {
  content: "\e043";
}

.fa-transporter-2:before {
  content: "\e044";
}

.fa-transporter-3:before {
  content: "\e045";
}

.fa-transporter-empty:before {
  content: "\e046";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-trash-undo:before {
  content: "\f895";
}

.fa-trash-undo-alt:before {
  content: "\f896";
}

.fa-treasure-chest:before {
  content: "\f723";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-tree-christmas:before {
  content: "\f7db";
}

.fa-tree-decorated:before {
  content: "\f7dc";
}

.fa-tree-large:before {
  content: "\f7dd";
}

.fa-tree-palm:before {
  content: "\f82b";
}

.fa-trees:before {
  content: "\f724";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-triangle-music:before {
  content: "\f8e2";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-container:before {
  content: "\f4dc";
}

.fa-truck-couch:before {
  content: "\f4dd";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-truck-plow:before {
  content: "\f7de";
}

.fa-truck-ramp:before {
  content: "\f4e0";
}

.fa-trumpet:before {
  content: "\f8e3";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-turkey:before {
  content: "\f725";
}

.fa-turntable:before {
  content: "\f8e4";
}

.fa-turtle:before {
  content: "\f726";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-alt:before {
  content: "\f8e5";
}

.fa-tv-music:before {
  content: "\f8e6";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typewriter:before {
  content: "\f8e7";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-ufo:before {
  content: "\e047";
}

.fa-ufo-beam:before {
  content: "\e048";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-unicorn:before {
  content: "\f727";
}

.fa-union:before {
  content: "\f6a2";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usb-drive:before {
  content: "\f8e9";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alien:before {
  content: "\e04a";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-chart:before {
  content: "\f6a3";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-cowboy:before {
  content: "\f8ea";
}

.fa-user-crown:before {
  content: "\f6a4";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-hard-hat:before {
  content: "\f82c";
}

.fa-user-headset:before {
  content: "\f82d";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-md-chat:before {
  content: "\f82e";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-music:before {
  content: "\f8eb";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-robot:before {
  content: "\e04b";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-user-unlock:before {
  content: "\e058";
}

.fa-user-visor:before {
  content: "\e04c";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-class:before {
  content: "\f63d";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-users-crown:before {
  content: "\f6a5";
}

.fa-users-medical:before {
  content: "\f830";
}

.fa-users-slash:before {
  content: "\e073";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vacuum:before {
  content: "\e04d";
}

.fa-vacuum-robot:before {
  content: "\e04e";
}

.fa-value-absolute:before {
  content: "\f6a6";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-vest:before {
  content: "\e085";
}

.fa-vest-patches:before {
  content: "\e086";
}

.fa-vhs:before {
  content: "\f8ec";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-plus:before {
  content: "\f4e1";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-violin:before {
  content: "\f8ed";
}

.fa-virus:before {
  content: "\e074";
}

.fa-virus-slash:before {
  content: "\e075";
}

.fa-viruses:before {
  content: "\e076";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-voicemail:before {
  content: "\f897";
}

.fa-volcano:before {
  content: "\f770";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume:before {
  content: "\f6a8";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-slash:before {
  content: "\f2e2";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-nay:before {
  content: "\f771";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-wagon-covered:before {
  content: "\f8ee";
}

.fa-walker:before {
  content: "\f831";
}

.fa-walkie-talkie:before {
  content: "\f8ef";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-wand:before {
  content: "\f72a";
}

.fa-wand-magic:before {
  content: "\f72b";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-warehouse-alt:before {
  content: "\f495";
}

.fa-washer:before {
  content: "\f898";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-watch-calculator:before {
  content: "\f8f0";
}

.fa-watch-fitness:before {
  content: "\f63e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-water:before {
  content: "\f773";
}

.fa-water-lower:before {
  content: "\f774";
}

.fa-water-rise:before {
  content: "\f775";
}

.fa-wave-sine:before {
  content: "\f899";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-wave-triangle:before {
  content: "\f89a";
}

.fa-waveform:before {
  content: "\f8f1";
}

.fa-waveform-path:before {
  content: "\f8f2";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-webcam:before {
  content: "\f832";
}

.fa-webcam-slash:before {
  content: "\f833";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whale:before {
  content: "\f72c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheat:before {
  content: "\f72d";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whistle:before {
  content: "\f460";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wifi-1:before {
  content: "\f6aa";
}

.fa-wifi-2:before {
  content: "\f6ab";
}

.fa-wifi-slash:before {
  content: "\f6ac";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-wind-turbine:before {
  content: "\f89b";
}

.fa-wind-warning:before {
  content: "\f776";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-frame:before {
  content: "\e04f";
}

.fa-window-frame-open:before {
  content: "\e050";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-windsock:before {
  content: "\f777";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wreath:before {
  content: "\f7e2";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.card-container > .card {
  width: calc(100vw / 1);
  display: inline-block;
  transition: all ease-in-out .3s;
}

@media (min-width: 576px) {
  .card-container > .card {
    width: calc(100vw / 2);
  }
}

@media (min-width: 768px) {
  .card-container > .card {
    width: calc(100vw / 3);
  }
}

@media (min-width: 992px) {
  .card-container > .card {
    width: calc(100vw / 4);
  }
}

@media (min-width: 1200px) {
  .card-container > .card {
    width: calc(100vw / 5);
  }
}

.card-container > .card-title {
  color: #ccc;
}

@media (min-width: 992px) {
  .card-container > .card-title {
    color: #040;
  }
}

@media (min-width: 1200px) {
  .card-container > .card-title {
    color: #d75893;
  }
}

:root {
  --fancybox-bg: rgba(0,0,0,.8);
}

.frame-space-before-extra-small {
  padding-top: 16px !important;
}

.frame-space-before-small {
  padding-top: 32px !important;
}

.frame-space-before-medium {
  padding-top: 40px !important;
}

.frame-space-before-large {
  padding-top: 128px !important;
}

.frame-space-before-extra-large {
  padding-top: 256px !important;
}

.frame-space-after-extra-small {
  padding-bottom: 16px !important;
}

.frame-space-after-small {
  padding-bottom: 32px !important;
}

.frame-space-after-medium {
  padding-bottom: 40px !important;
}

.frame-space-after-large {
  padding-bottom: 128px !important;
}

.frame-space-after-extra-large {
  padding-bottom: 256px !important;
}

@media (max-width: 991.98px) {
  .frame-space-before-extra-small {
    padding-top: 16px !important;
  }
  .frame-space-before-small {
    padding-top: 24px !important;
  }
  .frame-space-before-medium {
    padding-top: 64px !important;
  }
  .frame-space-before-large {
    padding-top: 72px !important;
  }
  .frame-space-before-extra-large {
    padding-top: 144px !important;
  }
  .frame-space-after-extra-small {
    padding-bottom: 16px !important;
  }
  .frame-space-after-small {
    padding-bottom: 24px !important;
  }
  .frame-space-after-medium {
    padding-bottom: 64px !important;
  }
  .frame-space-after-large {
    padding-bottom: 72px !important;
  }
  .frame-space-after-extra-large {
    padding-bottom: 144px !important;
  }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  font-size: 16px;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
  /*padding-top: calc(69px - var(--meta,0));*/
}

@media (max-width: 991.98px) {
  body {
    /*padding-top: calc(73px - var(--meta,0));*/
  }
}

body.overflow-hidden {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.content-wrapper {
  padding: 0 10px;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .content-wrapper {
    padding: 0 15px;
  }
}

.element-wrapper {
  margin-bottom: 2rem;
}

.container-narrow .content-wrapper {
  max-width: 844px !important;
}

.navigation-wrap {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}

.narrow-wrapper {
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .narrow-wrapper {
    padding: 0 15px;
  }
}

.narrower-wrapper {
  max-width: 844px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .narrower-wrapper {
    padding: 0 15px;
  }
}

.container-extended-gray,
.container-narrow-gray {
  background: #E9E9E9;
}

.container-narrow-gray > .content-fluid,
.container-narrow-white > .content-fluid {
  padding: 0 10px;
  margin: 0 auto;
  max-width: 1060px;
}

@media (max-width: 991.98px) {
  .container-narrow-gray > .content-fluid,
  .container-narrow-white > .content-fluid {
    padding: 0 15px;
  }
}

.container-extended-gray > .content-fluid,
.container-extended-white > .content-fluid {
  padding: 0 10px;
  margin: 0 auto;
  max-width: 1280px;
}

@media (max-width: 991.98px) {
  .container-extended-gray > .content-fluid,
  .container-extended-white > .content-fluid {
    padding: 0 15px;
  }
}

@media (max-width: 991.98px) {
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.primary {
  color: #2DC6D6;
}

h1, .h1 {
  color: #2DC6D6;
}

@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 28px;
  }
  h2, .h2 {
    font-size: 25px;
  }
  h3, .h3 {
    font-size: 22px;
  }
  h4, .h4 {
    font-size: 20px;
  }
  .btn {
    font-size: 14px;
    padding: 12px 14px;
    line-height: 1;
  }
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

.bg-grey {
  background-color: #E9E9E9;
}

.lead {
  line-height: 1.2;
}

@media (max-width: 991.98px) {
  .lead {
    font-size: 18px;
  }
}

.small {
  font-size: 0.875rem;
  line-height: 1.214;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .small {
    font-size: 14px;
  }
}

.body {
  font-size: 1rem;
  font-family: "Verdana";
  font-weight: 400;
  line-height: 1.5;
}

.frame-type-header .lead {
  margin-bottom: 2.2223rem;
}

@media (max-width: 991.98px) {
  .frame-type-header .lead {
    margin-bottom: 15px;
  }
}

ol,
ul {
  padding-left: 20px;
}

em {
  font-size: 0.83333rem;
}

.svg-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.svg-icon.blue {
  color: #2DC6D6;
  --color1: #185FA5;
}

.svg-icon.red {
  color: #E5352D;
  --color1: #E30613;
}

.svg-icon.green {
  color: #97A719;
  --color1: #84BE41;
}

.svg-icon.black {
  color: #000;
  --color1: #000;
}

.svg-icon.white {
  color: #fff;
  --color1: #FFF;
}

.svg-icon.svg-icon-2x {
  width: 2rem;
  height: 2rem;
}

header .svg-icon {
  width: 2.7778rem;
  height: 2.7778rem;
  margin-bottom: 1.1112rem;
}

table {
  font-size: 14px;
}

table th {
  color: #000;
  border-bottom: 1px solid #CED1D2 !important;
}

table th, table td {
  background-color: transparent;
  padding: 8px 0 !important;
}

table thead th {
  border: 0;
  color: #000 !important;
  vertical-align: top;
}

table tr:first-child th, table tr:first-child td {
  border-top: none;
}

.ce-uploads li {
  padding: 8px 0;
  border-bottom: 1px solid #CFCFCF;
}

.ce-uploads li .ce-uploads-fileName {
  font-size: 1rem;
  padding-left: 9px;
}

.ce-uploads li i {
  color: #CED1D2;
}

.overlay-20:after, .overlay-30:after, .overlay-60:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all .35s linear;
}

.overlay-20:after {
  background: rgba(0, 0, 0, 0.2);
}

.overlay-30:after {
  background: rgba(0, 0, 0, 0.3);
}

.overlay-60:after {
  background: rgba(0, 0, 0, 0.6);
}

.breadcrumb {
  margin-bottom: 0;
  padding: 1.25rem 0;
}

.breadcrumb .breadcrumb-item {
  padding: 0;
}

.breadcrumb .breadcrumb-item:before {
  content: none;
}

.breadcrumb .breadcrumb-item i {
  color: #2DC6D6;
}

.breadcrumb .breadcrumb-item a {
  font-size: 0.875rem;
  color: #000;
  line-height: 1.18;
  text-decoration: none;
}

.breadcrumb .breadcrumb-item a:after {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px;
  font-size: .6rem;
  color: #CED1D2;
}

.breadcrumb .breadcrumb-item:last-child a:after {
  content: none;
}

.drop-shadow {
  filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
}

.landingpage-thermofisher {
  font-family: 'HelveticaNeue';
}

.landingpage-thermofisher .h1, .landingpage-thermofisher h1,
.landingpage-thermofisher .h2, .landingpage-thermofisher h2,
.landingpage-thermofisher .h3, .landingpage-thermofisher h3,
.landingpage-thermofisher .h4, .landingpage-thermofisher h4,
.landingpage-thermofisher .h5, .landingpage-thermofisher h5,
.landingpage-thermofisher .h6, .landingpage-thermofisher h6 {
  font-family: 'HelveticaNeue';
  color: #ff0202;
  font-family: 'HelveticaNeue';
}

.landingpage-thermofisher a {
  color: #000000;
}

.landingpage-thermofisher a:hover {
  color: #ff0202;
}

.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
  color: #2DC6D6;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: #BEBEBE;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background: #2DC6D6;
}

.ratio-container {
  position: relative;
  height: auto !important;
}

.ratio-container:after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;
  content: "";
}

.ratio-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-43:after {
  padding-bottom: 75%;
}

.ratio-box:after {
  padding-bottom: 75%;
}

/* unknown ration variation */
.unknown-ratio-container > * {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.btn {
  outline: none;
  font-weight: bold;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  color: #fff;
  background-color: #CED1D2;
  border-color: #CED1D2;
}

.btn-primary {
  color: #2DC6D6;
  background-color: transparent;
  border: 1px solid #2DC6D6;
  box-shadow: none;
}

.btn-primary.selected, .btn-primary:hover {
  color: #fff;
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

.btn-secondary {
  color: #fff;
  border-color: #2DC6D6;
  background-color: #2DC6D6;
  box-shadow: none;
}

.btn-secondary.selected, .btn-secondary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-secondary.rounded-pill {
  padding: 0.83334rem 1.3888rem;
  line-height: 1;
}

.main-header .stickySearch {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
}

.stickySearch .cs2contenthub-search {
  background: #fff;
  border: none;
  border-radius: 50px !important;
}

.stickySearch .cs2contenthub-search + .input-group-btn .btn:active, .stickySearch .cs2contenthub-search + .input-group-btn .btn:focus {
  border: none;
  box-shadow: none;
}

.tx-cs2-contenthub .main-header .stickySearch {
  position: relative !important;
}

.tx-cs2-contenthub .stickySearch.is_stuck {
  z-index: 1020;
}

.tx-cs2-contenthub .cs2contenthub-pagination-container-top {
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .tx-cs2-contenthub .cs2contenthub-pagination-container-top {
    justify-content: center;
  }
  .tx-cs2-contenthub .cs2contenthub-pagination-container-top .cs2contenthub-pagination {
    display: none !important;
  }
}

.tx-cs2-contenthub .cs2contenthub-pagination .page-item {
  margin: 0 .25rem;
}

.tx-cs2-contenthub .cs2contenthub-pagination .page-item .page-link {
  background: #E9E9E9;
  color: #000;
  border: none !important;
}

.tx-cs2-contenthub .cs2contenthub-pagination .page-item.active .page-link {
  background: #CED1D2;
}

.tx-cs2-contenthub .cs2contenthub-pagination .page-item .prev-page, .tx-cs2-contenthub .cs2contenthub-pagination .page-item .next-page {
  color: #2DC6D6 !important;
}

.tx-cs2-contenthub .cs2contenthub-pagination .page-item.disabled .prev-page, .tx-cs2-contenthub .cs2contenthub-pagination .page-item.disabled .next-page {
  color: #CED1D2 !important;
}

.tx-cs2-contenthub .cs2contenthub-pagination .prev-page, .tx-cs2-contenthub .cs2contenthub-pagination .next-page {
  background: transparent !important;
  color: #CED1D2;
}

.tx-cs2-contenthub .cs2contenthub-item .isNew {
  left: 0;
  top: 0;
  z-index: 10;
}

.tx-cs2-contenthub .cs2contenthub-item .card-img.ratio-container:after {
  padding-bottom: 70.25%;
}

@media (max-width: 767.98px) {
  .tx-cs2-contenthub .cs2contenthub-item .card-body {
    padding: .5rem;
  }
}

.tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-badge {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-markets {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  align-items: flex-start;
  width: 40px;
  right: 10px;
  top: 10px;
  bottom: 10px;
}

.tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-markets .market-icon {
  width: 40px;
  height: 40px;
  margin-bottom: .5rem;
}

.tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-markets .market-icon img {
  width: 100%;
  height: 100%;
}

@media (max-width: 767.98px) {
  .tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-markets {
    width: 15px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }
  .tx-cs2-contenthub .cs2contenthub-item .img-overlay .img-markets .market-icon {
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
  }
}

.tx-cs2-contenthub .cs2contenthub-item .img-overlay.overlay-border:after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.tx-cs2-contenthub .contentHubSlider-wrapper .contentHubSwiper-nav-mobile-wrapper .swiper-button-next:after, .tx-cs2-contenthub .contentHubSlider-wrapper .contentHubSwiper-nav-mobile-wrapper .swiper-button-prev:after {
  font-size: 32px;
}

.tx-cs2-contenthub .contentHubSlider-wrapper .contentHubSwiper-pagination-wrapper .contentHubSwiper-pagination {
  position: relative;
  padding-top: 1.5rem;
  bottom: 0;
  height: calc(1.5rem + 16px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tx-cs2-contenthub .contentHubSlider-wrapper .contentHubSwiper-pagination-wrapper .contentHubSwiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  transition: all 0.2s ease-in-out;
}

.tx-cs2-contenthub .contentHubSlider-wrapper .contentHubSwiper-pagination-wrapper .contentHubSwiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #2DC6D6;
  width: 16px;
  height: 16px;
}

.tx-cs2-contenthub .contentHubSlider-wrapper .productfinderSlider.plusPartImage {
  padding-right: 90px;
  margin-right: -90px;
}

.tx-cs2-contenthub .contentHubSwiper {
  padding-right: 3rem;
}

.tx-cs2-contenthub .cs2contenthub-filter .modal-dialog {
  height: 100%;
  width: 100% !important;
  margin: 0 auto;
  padding: 20px 0;
}

.tx-cs2-contenthub .cs2contenthub-filter .modal-dialog .modal-content {
  height: 100%;
}

.tx-cs2-contenthub .cs2contenthub-filter .modal-dialog .modal-content .modal-body {
  overflow: auto;
}

.tx-cs2-contenthub .cs2contenthub-filter .custom-filter-checkbox {
  padding-left: 0;
}

.tx-cs2-contenthub .cs2contenthub-filter .custom-filter-checkbox .custom-control-label {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  background: #EDEDED;
  border: 1px solid #EDEDED;
}

.tx-cs2-contenthub .cs2contenthub-filter .custom-filter-checkbox .custom-control-label:before {
  display: none;
}

.tx-cs2-contenthub .cs2contenthub-filter .custom-filter-checkbox .custom-control-label:after {
  top: calc(50% - 0.5rem);
  left: auto;
  right: 10px;
}

.tx-cs2-contenthub .cs2contenthub-filter .custom-filter-checkbox .custom-control-input:checked ~ .custom-control-label {
  border: 1px solid #2DC6D6;
  background: #CED1D2;
}

.tx-cs2-contenthub .selectdiv {
  position: relative;
}

.tx-cs2-contenthub .selectdiv.disabled:after {
  color: #CED1D2;
}

.tx-cs2-contenthub .selectdiv:after {
  content: '>';
  font: 16px Consolas, monospace;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 12px;
  top: 0;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  color: #2dc6d6;
}

.tx-cs2-contenthub .selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /*      width: 100%;
            max-width: 516px;
            height: 48px;*/
  margin: 8px 0;
  padding: 0 8px;
  color: #000;
  /*background-color: #fff;*/
  background-image: none;
  border: 1px solid #CED1D2;
  border-radius: 3px;
  -ms-word-break: normal;
  word-break: normal;
}

.tx-cs2-contenthub .selectdiv select:disabled {
  border-color: #CED1D2;
}

.tx-cs2-contenthub .selectdiv option {
  color: #2DC6D6;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: Verdana;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tx-cs2-contenthub .selectdiv option:focus {
  background: #CED1D2;
  color: #fff;
}

.tx-cs2-contenthub .selectdiv select:focus > option:checked {
  background: #CED1D2;
  color: #fff;
}

.new-item {
  height: auto;
}

.frame-type-form_formframework {
  background-color: #EDEDED;
}

label,
.control-label {
  font-weight: 700;
  color: #212529;
  margin-bottom: 0.625rem;
}

.dsgvo-label {
  color: #212529 !important;
}

.custom-control-input:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-file-label {
  cursor: pointer;
}

.custom-file-label:after {
  background: #2DC6D6;
  color: #fff;
  font-family: "Verdana";
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 35px;
  padding: 0 16px;
  height: auto;
}

.custom-file-input {
  min-height: 37px;
  height: 100%;
}

.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  outline: none;
}

.custom-select {
  padding-right: 2.75rem;
}

.has-error .form-label {
  color: #B51639;
}

.has-error .form-border-bottom,
.has-error .hf-invalid,
.has-error .error {
  border-color: #B51639;
}

.has-error .hs-input,
.has-error .form-control {
  border-color: #B51639;
}

.has-error .hs-input:focus,
.has-error .form-control:focus {
  border-color: #6DC9D1;
}

.has-error .hf-warning,
.has-error .invalid-feedback {
  display: block;
  color: #B51639;
  font-size: .8125rem;
  padding: 0;
  margin-top: .25rem;
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;
}

.bootstrap-select .dropdown-toggle {
  transition: none !important;
}

.bootstrap-select.show .dropdown-toggle {
  box-shadow: none !important;
  z-index: 1001;
}

.bootstrap-select.show .dropdown-toggle:after {
  transform: rotate(-180deg);
}

.bootstrap-select.show .inner {
  display: block;
}

.bootstrap-select .dropdown-menu {
  padding: 0;
}

.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  transition: none !important;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #161615;
}

.form-group .custom-radio,
.form-group .custom-checkbox {
  padding-bottom: 10px;
}

.form-group .custom-radio:last-child,
.form-group .custom-checkbox:last-child {
  padding-bottom: 0;
}

.form-group .custom-radio .custom-control-label,
.form-group .custom-checkbox .custom-control-label {
  font-weight: 400;
}

.form-group .custom-radio .custom-control-label:before,
.form-group .custom-checkbox .custom-control-label:before {
  width: 16px;
  height: 16px;
  border: 2px solid #2DC6D6;
  box-shadow: none;
  border-radius: 0;
}

.form-group .custom-radio .custom-control-label:after,
.form-group .custom-checkbox .custom-control-label:after {
  width: 16px;
  height: 16px;
}

.form-group .custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.has-error .error.help-block {
  color: #B51639;
  padding-top: 7px;
  font-size: 0.7222rem;
}

@media (max-width: 767.98px) {
  .actions .form-navigation .btn-toolbar .btn-group {
    width: 100%;
  }
  .actions .form-navigation .btn-toolbar .btn-group .submit {
    width: 100%;
  }
  .actions .form-navigation .btn-toolbar .btn-group .submit button.btn {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .md-text-nowrap {
    white-space: nowrap !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: block;
  transition: all .35s ease;
  z-index: -1;
}

.page-overlay:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.4);
}

.page-overlay .spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  stroke: #2DC6D6;
  opacity: 1;
}

.page-overlay.show {
  z-index: 999;
}

.tx-cs2-keyvisual .bg {
  background: #ededed;
}

.tx-cs2-keyvisual .keyvisual-content-box {
  width: 516px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  margin-top: 215px;
  margin-left: 81px;
  padding-top: 16px;
  padding-right: 20px;
  padding-left: 20px;
  word-break: break-word;
}

.tx-cs2-keyvisual .keyvisual-content-box .description {
  padding-bottom: 16px;
}

.tx-cs2-keyvisual .keyvisual-content-box .title {
  color: #000000;
  margin-bottom: 0;
}

.tx-cs2-keyvisual .image-area {
  margin-top: 179px;
  margin-bottom: 60px;
  margin-right: 189px;
}

.tx-cs2-keyvisual .image-resize {
  width: 407px;
  height: 279px;
}

@media (max-width: 767.98px) {
  .tx-cs2-keyvisual .image-resize {
    width: 100%;
  }
}

.tx-cs2-keyvisual .image-height {
  height: 35.3vw;
  margin-bottom: -1px;
}

.tx-cs2-keyvisual .keyvisual-title {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 81px;
  width: 516px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  margin-top: 215px;
  /**/
  padding-top: 16px;
  padding-right: 20px;
  padding-left: 20px;
  word-break: break-word;
}

.tx-cs2-keyvisual .keyvisual-title .title {
  color: #000000;
}

.tx-cs2-keyvisual .focuspoint img {
  min-width: 0;
}

.tx-cs2-keyvisual .keyvisual-footer {
  background: url("../images/PAL_std_blue_rgb.png") 0 100%;
  padding-bottom: 10px;
}

@media (max-width: 1199.98px) {
  .tx-cs2-keyvisual .keyvisual-content-box {
    width: 386px;
    margin-left: 51px;
  }
  .tx-cs2-keyvisual .image-area {
    margin-right: 109px;
  }
}

@media (max-width: 991.98px) {
  .tx-cs2-keyvisual .keyvisual-content-box {
    width: 286px;
    margin-left: 21px;
  }
  .tx-cs2-keyvisual .image-area {
    margin-right: 25px;
  }
  .tx-cs2-keyvisual .keyvisual-title {
    width: 356px;
    margin-left: 41px;
  }
  .tx-cs2-keyvisual .image-height {
    height: 350px;
  }
}

@media (max-width: 767.98px) {
  .tx-cs2-keyvisual .image-area {
    margin: 16px;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-keyvisual .keyvisual-content-box {
    width: auto;
    margin-top: 90px;
    margin-left: 0;
    margin-bottom: 30px;
    margin-right: 16px;
    padding: 16px;
    width: 75%;
  }
  .tx-cs2-keyvisual .keyvisual-title {
    margin-top: 183px;
    margin-left: 0px;
    width: 245px;
  }
  .tx-cs2-keyvisual .image-height {
    height: 300px;
  }
  .tx-cs2-keyvisual .keyvisual-footer {
    padding-bottom: 3px;
  }
}

.tx-cs2-keyvisual .productpage-title .title-wrap {
  height: 45px;
}

.tx-cs2-keyvisual .productpage-title .title-wrap .keyvisual-title {
  margin-bottom: 10px;
  position: relative;
  padding: 8px;
}

.tx-cs2-keyvisual .landingpage-keyvisual .bg {
  background: #ededed;
}

.tx-cs2-keyvisual .landingpage-keyvisual .image-area {
  margin-top: 179px;
  margin-bottom: 60px;
  margin-right: 189px;
}

.tx-cs2-keyvisual .landingpage-keyvisual .image-resize {
  width: 407px;
  height: 279px;
}

@media (max-width: 767.98px) {
  .tx-cs2-keyvisual .landingpage-keyvisual .image-resize {
    width: 100%;
  }
}

.tx-cs2-keyvisual .landingpage-keyvisual .image-height {
  height: 35.3vw;
  margin-bottom: -1px;
}

.tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-title {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 300px;
  width: auto;
  background: transparent;
  backdrop-filter: blur(7px);
  margin-top: 200px;
  /**/
  padding-top: 16px;
  padding-right: 20px;
  padding-left: 20px;
  word-break: break-word;
}

.tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-title .title {
  color: #ffffff;
}

.tx-cs2-keyvisual .landingpage-keyvisual .focuspoint img {
  min-width: 0;
}

.tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-footer {
  /* background: url('../images/PAL_std_blue_rgb.png') 0 100%; */
  background: #ff0202;
  padding-bottom: 10px;
}

@media (max-width: 1199.98px) {
  .tx-cs2-keyvisual .landingpage-keyvisual .image-area {
    margin-right: 109px;
  }
  .tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-title {
    margin-left: 41px;
  }
}

@media (max-width: 991.98px) {
  .tx-cs2-keyvisual .landingpage-keyvisual .image-area {
    margin-right: 25px;
  }
  .tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-title {
    width: auto;
    margin-left: 41px;
  }
  .tx-cs2-keyvisual .landingpage-keyvisual .image-height {
    height: 350px;
  }
}

@media (max-width: 767.98px) {
  .tx-cs2-keyvisual .landingpage-keyvisual .image-area {
    margin: 16px;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-title {
    margin-top: 183px;
    margin-left: 0px;
    width: 245px;
  }
  .tx-cs2-keyvisual .landingpage-keyvisual .image-height {
    height: 300px;
  }
  .tx-cs2-keyvisual .landingpage-keyvisual .keyvisual-footer {
    padding-bottom: 3px;
  }
}

.tx-cs2-keyvisual .landingpage-keyvisual .productpage-title .title-wrap {
  height: 45px;
}

.tx-cs2-keyvisual .landingpage-keyvisual .productpage-title .title-wrap .keyvisual-title {
  margin-bottom: 10px;
  position: relative;
  padding: 8px;
}

.pic-gallery .h-gal {
  height: 355px;
}

@media (max-width: 767.98px) {
  .pic-gallery .h-gal {
    height: 135px;
  }
}

@media (max-width: 767.98px) {
  .pic-gallery .col-12 .h-gal {
    height: 205px;
  }
}

.pic-gallery .col {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

@media (max-width: 991.98px) {
  .pic-gallery .col {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  }
}

.imagefancybox {
  cursor: pointer;
}

.imagefancybox .overlay {
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(24, 95, 165, 0.8);
  text-align: center;
  transition: all .35s ease;
}

.imagefancybox .overlay i {
  color: #fff;
  font-size: 40px;
  opacity: 0;
}

.imagefancybox:hover .overlay {
  width: 100%;
}

.imagefancybox:hover .overlay i {
  opacity: 1;
}

figure figcaption {
  margin-top: 10px;
  color: #706F6F;
}

.fancybox__container .fancybox__backdrop {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(7px);
}

.fancybox__container .fancybox__toolbar {
  background: none;
  padding-top: 40px;
  padding-right: 80px;
}

@media (max-width: 991.98px) {
  .fancybox__container .fancybox__toolbar {
    padding-top: 16px;
    padding-right: 16px;
  }
}

.fancybox__container .fancybox__toolbar .carousel__button {
  text-shadow: 0px 4px 30px rgba(19, 0, 35, 0.8);
}

.fancybox__container .fancybox__carousel .fancybox__viewport .fancybox__slide .fancybox__content {
  filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
}

.fancybox__container .fancybox__carousel .fancybox__viewport .fancybox__slide .fancybox__caption {
  color: #000;
}

.fancybox__container .carousel__button {
  color: #2DC6D6;
  font-size: 2rem;
}

.fancybox__container .carousel__button.fancybox__button--close {
  color: #000;
}

.fancybox__container .fancybox__nav .carousel__button {
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 991.98px) {
  .fancybox__container .fancybox__nav {
    display: none;
  }
}

.fancybox__container .carousel__dots {
  top: calc(100% - 2.5rem);
}

@media (max-width: 991.98px) {
  .fancybox__container .carousel__dots {
    top: calc(100% - 23px);
  }
}

.fancybox__container .carousel__dots .carousel__dot.is-selected:after {
  width: 16px;
  height: 16px;
  opacity: 1;
}

.fancybox__container .carousel__dots .carousel__dot:after {
  width: 10px;
  height: 10px;
  opacity: .5;
  box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.3);
}

.ce-gall img {
  filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
}

.tx-cs2-accordion .card {
  background-color: #EDEDED;
  border: unset;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .card {
    margin-left: 0;
    margin-right: 0;
  }
}

.tx-cs2-accordion .card > .card-header {
  background-color: unset;
  border: unset;
  padding: 0.89rem;
  cursor: pointer;
}

.tx-cs2-accordion .card > .card-header .title_box {
  color: #2DC6D6;
  padding-right: 1.6rem;
}

.tx-cs2-accordion .card > .card-header .title_box.collapsed {
  color: #000;
}

.tx-cs2-accordion .card > .card-header .icon-box {
  right: 0;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .card > .card-header .icon-box {
    top: -0.3rem;
  }
}

.tx-cs2-accordion .card > .card-header .icon-box .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .card > .card-header .icon-box .svg-icon {
    width: 1rem;
    height: 1rem;
  }
}

.tx-cs2-accordion .card > .card-header .closed {
  display: none;
  color: #000;
}

.tx-cs2-accordion .card > .card-header .opened {
  display: inline-block;
  color: #2DC6D6;
}

.tx-cs2-accordion .card > .card-header .collapsed .closed {
  display: inline-block;
}

.tx-cs2-accordion .card > .card-header .collapsed .opened {
  display: none;
}

.tx-cs2-accordion .card-body {
  padding: 0 0.89rem 0.89rem;
}

.tx-cs2-accordion .card-body .img-fluid {
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .card-body .img-fluid {
    margin-bottom: 1rem;
  }
}

.tx-cs2-accordion .layout-light {
  border-top: 1px solid #CED1D2;
}

.tx-cs2-accordion .layout-light .card {
  background-color: unset;
  border-bottom: 1px solid #CED1D2;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 0;
}

.tx-cs2-accordion .layout-light .card > .card-header {
  padding: 0 0.89rem;
}

.tx-cs2-accordion .layout-light .card > .card-header .title_box {
  color: #000;
}

.tx-cs2-accordion .layout-light .card > .card-header .title_box .body {
  font-family: "Open Sans Condensed Light";
  font-size: 1.563rem;
  font-weight: 300;
  line-height: normal;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .layout-light .card > .card-header .title_box .body {
    font-size: 1.25rem;
  }
}

.tx-cs2-accordion .layout-light .card > .card-header .icon-box .closed {
  color: #2DC6D6;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .layout-light .card > .card-header .icon-box .closed {
    color: #000;
  }
}

.tx-cs2-accordion .layout-light .card .collapse {
  /* padding-bottom: 0.89rem;*/
}

.tx-cs2-accordion .layout-light .card .collapse .card-body {
  /*padding-bottom: 0;*/
  padding: 0.89rem 0.89rem 0;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .layout-light .card .collapse .card-body {
    font-size: 0.875rem;
  }
}

.tx-cs2-accordion .layout-light .card .collapse .card-body a {
  background-color: transparent;
  border: 1px solid #2DC6D6;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  color: #2DC6D6;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.7778rem;
  outline: 0;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (max-width: 767.98px) {
  .tx-cs2-accordion .layout-light .card .collapse .card-body a {
    font-size: 0.875rem;
    padding: 0 0.875rem;
  }
}

.tx-cs2-people {
  /*.people-item {
    min-height: 283px;

    @include media-breakpoint-down(sm) {
      min-height: 152px;
    }
  }
  @include media-breakpoint-down(sm) {
    .img-fluid {
      max-width: 101px;
    }

    .text-part {
      max-width: calc( 100vw - 37px - 101px - 11px);

      &.full_width {
        max-width: calc( 100vw - 37px);
      }
    }
  }*/
}

.tx-cs2-people .people {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 767.98px) {
  .tx-cs2-people .people {
    padding-top: unset;
    padding-bottom: unset;
  }
}

.tx-cs2-people .people .layout-6 .in-overlay {
  padding: 2rem;
}

@media (max-width: 767.98px) {
  .tx-cs2-people .people .layout-6 .in-overlay {
    padding: 1rem !important;
    background-color: transparent;
  }
}

@media (max-width: 767.98px) {
  .tx-cs2-people .people .layout-6 .text-overlay {
    position: relative;
  }
}

.tx-cs2-people .people .layout-6 .text-overlay.order-last:before {
  left: -10%;
  width: unset;
}

.tx-cs2-people .people .layout-6 .text-overlay.order-last .in-overlay {
  padding-right: 5rem;
}

.tx-cs2-people .people .layout-6 .text-overlay.order-first .in-overlay {
  padding-left: 5rem;
}

.tx-cs2-people .people .layout-6 .text-overlay:before {
  left: 0;
  /*right: 0;*/
  width: unset !important;
}

@media (max-width: 767.98px) {
  .tx-cs2-people .people .layout-6 .text-overlay:before {
    left: 0 !important;
    right: 0 !important;
    width: unset !important;
  }
}

.tx-cs2-people .people .layout-6 .image-last {
  text-align: right;
}

.tx-cs2-people .people .layout-6 .img-fluid {
  filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
}

.tx-cs2-iconswithlink .item-box {
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 767.98px) {
  .tx-cs2-iconswithlink .item-box {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.tx-cs2-iconswithlink .item {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.tx-cs2-iconswithlink .item:first-child {
  margin-left: 0;
}

.tx-cs2-iconswithlink .item:last-child {
  margin-right: 0;
}

@media (max-width: 1199.98px) {
  .tx-cs2-iconswithlink .item {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

.tx-cs2-iconswithlink .item .item-link {
  display: inline-block;
}

.tx-cs2-iconswithlink .item .item-link:hover .title-box {
  display: none;
}

.tx-cs2-iconswithlink .item .item-link:hover .description-box {
  z-index: 30;
}

.tx-cs2-iconswithlink .item .item-link:hover .img-box {
  border-color: #CED1D2;
}

.tx-cs2-iconswithlink .item .item-link:hover .img-box .overlay {
  background-color: #2DC6D6;
}

.tx-cs2-iconswithlink .item .text-part {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  overflow: hidden;
}

.tx-cs2-iconswithlink .item .text-part .icon-box {
  position: absolute;
  top: 25%;
  bottom: 0;
  left: 0;
  right: 0;
}

.tx-cs2-iconswithlink .item .text-part .icon-box .svg-icon {
  width: 86px;
  height: 75px;
  margin: auto;
  display: block;
  position: relative;
  z-index: 25;
}

@media (max-width: 991.98px) {
  .tx-cs2-iconswithlink .item .text-part .icon-box .svg-icon {
    width: 46px;
    height: 40px;
  }
}

.tx-cs2-iconswithlink .item .text-part .title-box {
  position: absolute;
  top: 65%;
  left: 0;
  right: 0;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 25;
}

.tx-cs2-iconswithlink .item .text-part .title-box .title {
  margin-top: 5px;
}

@media (max-width: 991.98px) {
  .tx-cs2-iconswithlink .item .text-part .title-box {
    height: 44px;
  }
  .tx-cs2-iconswithlink .item .text-part .title-box .title {
    font-size: 1.5rem;
    margin-top: 0.15rem;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-iconswithlink .item .text-part .title-box {
    height: 30px;
  }
  .tx-cs2-iconswithlink .item .text-part .title-box .title {
    font-size: 1rem;
    margin-top: 0.2rem;
  }
}

.tx-cs2-iconswithlink .item .text-part .description-box {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  height: 56px;
}

.tx-cs2-iconswithlink .item .text-part .description-box .description {
  margin-top: 5px;
}

@media (max-width: 991.98px) {
  .tx-cs2-iconswithlink .item .text-part .description-box {
    height: 30px;
  }
  .tx-cs2-iconswithlink .item .text-part .description-box .description {
    font-size: 0.75rem;
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-iconswithlink .item .text-part .description-box {
    height: 30px;
  }
  .tx-cs2-iconswithlink .item .text-part .description-box .description {
    font-size: 0.5rem;
    margin-top: 0;
  }
}

@media (max-width: 991.98px) {
  .tx-cs2-iconswithlink .item .img-wrap {
    max-width: calc((100vw - 45px)/2);
  }
}

.tx-cs2-iconswithlink .item .img-box {
  border: 5px solid #2DC6D6;
  overflow: hidden;
  position: relative;
  /*      &:hover {
        border-color: $gray-600;
        .overlay {
          background-color: $primary;
        }
      }*/
}

.tx-cs2-iconswithlink .item .img-box .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  border-radius: 50%;
  background-color: rgba(45, 198, 214, 0.4);
  transition: all 0.2s ease-in-out;
}

.tx-cs2-iconswithlink .people-item {
  min-height: 283px;
}

@media (max-width: 767.98px) {
  .tx-cs2-iconswithlink .people-item {
    min-height: 152px;
  }
}

.tx-cs2-iconswithlink .circle-wrap {
  height: 50% !important;
}

.tx-cs2-iconswithlink .circle-wrap::before {
  content: "";
  width: 50%;
  height: 100%;
  float: left;
  shape-outside: polygon(-0.1% 3.16%, 29px 56.53%, 39.64% 72.26%, 55.73% 83.77%, 79.47% 90.39%, 2px 89.74%);
  shape-margin: 2%;
}

.tx-cs2-iconswithlink .circle-wrap p {
  height: 100%;
  margin: 0 !important;
}

.tx-cs2-iconswithlink .circle-wrap p::before {
  content: "";
  width: 50%;
  height: 100%;
  float: right;
  shape-outside: polygon(100% 0px, 100% 100%, 2% 100%, 18.09% 97.31%, 46.77% 90.65%, 71.11% 76.86%, 85.43% 61.92%, 89.08% 49.09%, 95.78% 29.85%);
  shape-margin: 3%;
}

.tx-cs2-socialmedia .socialmedia .svg-icon {
  width: 2.5rem;
  height: 2.5rem;
}

@media (max-width: 767.98px) {
  .tx-cs2-socialmedia .socialmedia .svg-icon {
    width: 1.95rem;
    height: 1.95rem;
  }
}

.tx-cs2-teaser .teaser {
  background-color: #fff;
}

.tx-cs2-teaser .teaser .teaser-text {
  padding: 10px 15px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  bottom: 30px;
  left: 45px;
  right: 45px;
}

.tx-cs2-teaser .teaser .teaser-text h3 {
  font-size: 31px;
  line-height: 42px;
  font-weight: 300;
}

.tx-cs2-teaser .teaser .teaser-text p {
  font-size: 14px;
  line-height: 17px;
}

.tx-cs2-teaser .teaser .teaser-text .description {
  display: none;
}

@media (max-width: 767.98px) {
  .tx-cs2-teaser .teaser .teaser-text .description {
    display: block;
  }
}

.tx-cs2-teaser .teaser .teaser-text .btn {
  display: none;
}

@media (max-width: 767.98px) {
  .tx-cs2-teaser .teaser .teaser-text .btn {
    display: inline-block;
  }
}

@media (max-width: 767.98px) {
  .tx-cs2-teaser .teaser .teaser-text {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    padding: 12px 16px 16px;
    text-align: left;
  }
  .tx-cs2-teaser .teaser .teaser-text h3, .tx-cs2-teaser .teaser .teaser-text p {
    text-shadow: none;
  }
  .tx-cs2-teaser .teaser .teaser-text h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .tx-cs2-teaser .teaser .teaser-text a.btn {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .tx-cs2-teaser .teaser .teaser-text h3, .tx-cs2-teaser .teaser .teaser-text p {
    color: #000;
  }
}

.tx-cs2-teaser .teaser:hover .teaser-text .description {
  display: block;
}

.tx-cs2-teaser .teaser:hover .teaser-text .btn {
  display: inline-block;
}

.tx-cs2-teaser .teaser:hover .overlay-30:after {
  background: rgba(0, 0, 0, 0.6);
}

.tx-cs2-teaser .swiper-button-prev {
  left: -5rem;
}

.tx-cs2-teaser .swiper-button-next {
  right: -5rem;
}

.tx-cs2-teaser .swiper-button-disabled {
  display: none;
}

@media (max-width: 767.98px) {
  .tx-cs2-teaser .teaserslider {
    width: calc(100% - 15px);
  }
}

.tx-cs2-teaser .teaserslider .row {
  margin-left: -15px;
  margin-right: -15px;
}

.tx-cs2-teaser .teaserslider .row .col-md-4,
.tx-cs2-teaser .teaserslider .row .col-md-6,
.tx-cs2-teaser .teaserslider .row .col-md-12 {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767.98px) {
  .tx-cs2-teaser .teaserslider .row {
    margin-left: 0;
    margin-right: 0;
  }
  .tx-cs2-teaser .teaserslider .row .col-md-4,
  .tx-cs2-teaser .teaserslider .row .col-md-6,
  .tx-cs2-teaser .teaserslider .row .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.ratio43 {
  height: 68vw !important;
}

.tx-cs2-blog .blog_list a:last-child .blog_item,
.tx-cs2-blog .blog_moreblogs a:last-child .blog_item,
.tx-cs2-blog .blog_teaser a:last-child .blog_item {
  margin-bottom: 0;
}

.tx-cs2-blog .blog_list .blog_item,
.tx-cs2-blog .blog_moreblogs .blog_item,
.tx-cs2-blog .blog_teaser .blog_item {
  margin-bottom: 1.1112rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
  border: 0;
}

.tx-cs2-blog .blog_list .blog_item .card-body,
.tx-cs2-blog .blog_moreblogs .blog_item .card-body,
.tx-cs2-blog .blog_teaser .blog_item .card-body {
  padding: 0.5556rem;
}

.tx-cs2-blog .blog_list .blog_item .card-body .date,
.tx-cs2-blog .blog_moreblogs .blog_item .card-body .date,
.tx-cs2-blog .blog_teaser .blog_item .card-body .date {
  color: #706F6F;
  padding: 0.5rem 0 1.1112rem 0;
  font-size: 1rem;
}

.tx-cs2-blog .blog_list .blog_item .card-body h4,
.tx-cs2-blog .blog_moreblogs .blog_item .card-body h4,
.tx-cs2-blog .blog_teaser .blog_item .card-body h4 {
  margin-bottom: 1.1112rem;
}

.tx-cs2-blog .blog_list .blog_item .card-body p,
.tx-cs2-blog .blog_moreblogs .blog_item .card-body p,
.tx-cs2-blog .blog_teaser .blog_item .card-body p {
  color: #CED1D2;
  font-size: 1rem;
}

.tx-cs2-blog .blog_list .blog_item:hover,
.tx-cs2-blog .blog_moreblogs .blog_item:hover,
.tx-cs2-blog .blog_teaser .blog_item:hover {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
}

.tx-cs2-blog .blog_list .swiper-slide,
.tx-cs2-blog .blog_moreblogs .swiper-slide,
.tx-cs2-blog .blog_teaser .swiper-slide {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.tx-cs2-blog .blog_list .swiper-slide .blog_item,
.tx-cs2-blog .blog_moreblogs .swiper-slide .blog_item,
.tx-cs2-blog .blog_teaser .swiper-slide .blog_item {
  box-shadow: none;
}

.tx-cs2-blog .categories {
  bottom: 20px;
  left: 0;
  max-width: 25%;
}

@media (max-width: 991.98px) {
  .tx-cs2-blog .categories {
    bottom: 15px;
    max-width: 75%;
  }
}

.tx-cs2-blog .categories .cat {
  margin-top: 10px;
  padding: 5px 7px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .tx-cs2-blog .categories .cat {
    margin-top: 5px;
  }
}

.tx-cs2-blog.detail .datecat {
  margin-top: 1.11112rem;
  margin-bottom: 3.33335rem;
}

@media (max-width: 991.98px) {
  .tx-cs2-blog.detail .datecat {
    margin-top: 1.5rem;
    margin-bottom: 1.2777rem;
  }
}

.tx-cs2-blog.detail .datecat .categories {
  max-width: 100%;
}

.tx-cs2-blog.detail .datecat .categories .cat {
  font-size: 0.83334rem;
  margin-right: 5px !important;
  width: 150px;
  text-align: center;
}

.tx-cs2-blog.detail .datecat .categories .cat:last-child {
  margin-right: 0;
}

.tx-cs2-blog.detail .datecat .date {
  color: #706F6F;
}

.tx-cs2-blog.detail .datecat .back-btn a {
  color: #CED1D2;
  font-size: 0.7222rem;
}

.tx-cs2-blog.detail .datecat .back-btn a i {
  padding-right: 8px;
}

.tx-cs2-blog.detail .lead {
  margin-bottom: 1.4444rem;
}

.tx-cs2-blog.detail .blog-text p:last-child {
  margin-bottom: 0;
}

.tx-cs2-blog.blog-list .filter .btn {
  margin: 8px;
}

@media (max-width: 991.98px) {
  .tx-cs2-blog.blog-list .filter .btn {
    margin: 5px 10px 5px 0;
    padding: 10px 15px;
  }
}

.tx-cs2-blog.header-image .blog-detail-height {
  height: 500px;
}

@media (max-width: 767.98px) {
  .tx-cs2-blog.header-image .blog-detail-height {
    height: 259px;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-blog.header-image .blog-detail-height {
    height: 194px;
  }
}

.cs2blog_list .lead {
  margin-bottom: 4.4445rem;
}

.tx-cs2-events .btn.btn-secondary.rounded-pill {
  font-size: 0.7222rem;
  line-height: 0.7222rem;
}

@media (max-width: 991.98px) {
  .tx-cs2-events .btn.btn-secondary.rounded-pill {
    padding: 0.56rem 0.83rem;
    margin-bottom: 0.56rem;
  }
}

.tx-cs2-events .event-filter .category {
  margin-right: .8rem;
}

@media (max-width: 991.98px) {
  .tx-cs2-events .event-filter .category {
    margin-right: .6rem;
  }
}

.tx-cs2-events .event-filter .category.selected {
  color: #fff;
  background-color: #97A719;
  border-color: #97A719;
}

.tx-cs2-events .event-filter .date-part {
  margin-left: 3rem;
}

.tx-cs2-events .event-filter .date-field {
  position: relative;
  width: 170px;
  margin-right: 5px;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .tx-cs2-events .event-filter .date-field {
    margin-top: 2.5rem;
    width: 100%;
    display: block;
  }
}

.tx-cs2-events .event-filter .date-field .form-control {
  height: 37px;
  display: inline;
  width: 100%;
}

.tx-cs2-events .event-filter .date-field .date-label {
  position: absolute;
  top: -1.95rem;
  font-weight: 700;
}

.tx-cs2-events .event-filter .date-field .icon-wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 37px;
  background-color: #2DC6D6;
  z-index: 50;
}

.tx-cs2-events .event-filter .date-field .icon-wrap .svg-icon {
  width: 1.5rem;
  height: 1.38rem;
  margin-left: 5px;
  margin-top: 6px;
}

.tx-cs2-events a {
  color: #000;
}

.tx-cs2-events a:hover {
  text-decoration: none;
}

.tx-cs2-events a .card {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.tx-cs2-events a .card .image-part {
  position: relative;
}

.tx-cs2-events a .card .image-part .itemDate {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #2DC6D6;
  color: #fff;
  padding: 0.3rem 0.4rem;
  font-size: 0.833rem;
  line-height: 1.111rem;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .tx-cs2-events a .card .image-part .itemDate {
    font-size: 0.722rem;
    line-height: 0.944rem;
  }
}

.tx-cs2-events a .card .itemCategory {
  color: #706F6F;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.11rem;
}

@media (max-width: 991.98px) {
  .tx-cs2-events a .card .itemCategory {
    margin-top: 0.833rem;
  }
}

.tx-cs2-events a .card .title {
  margin-top: 1.11rem;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .tx-cs2-events a .card .title {
    margin-top: 0.833rem;
  }
}

.tx-cs2-events a .card .teaser {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.11rem;
}

@media (max-width: 991.98px) {
  .tx-cs2-events a .card .teaser {
    margin-top: 0.833rem;
  }
}

.tx-cs2-events a .teaser {
  color: #CED1D2;
}

@media (max-width: 991.98px) {
  .tx-cs2-events .h-event {
    height: 160px;
  }
}

.tx-cs2-tabs .nav-tabs {
  flex-wrap: unset;
  /*    li.nav-item {
      @include media-breakpoint-down(sm) {
        border: 1px solid $gray-300;
      }
    }*/
}

@media (max-width: 575.98px) {
  .tx-cs2-tabs .nav-tabs {
    display: block;
  }
  .tx-cs2-tabs .nav-tabs li.nav-item:last-of-type .nav-link.active {
    border: 1px solid #F4F4F4;
  }
}

.tx-cs2-tabs .nav-link {
  padding: 0.89rem;
  background-color: #fff;
  color: #2DC6D6;
  margin-bottom: 0;
  border: unset;
  font-size: 1rem;
  white-space: nowrap;
}

.tx-cs2-tabs .nav-link.active {
  color: #000;
  font-weight: 700;
  background-color: #E9E9E9;
  border-radius: 5px 5px 0 0;
  background-top-color: #E9E9E9;
  background-top-width: 1px;
  background-top-style: solid;
  background-left-color: #E9E9E9;
  background-left-width: 1px;
  background-left-style: solid;
  background-right-color: #E9E9E9;
  background-right-width: 1px;
  background-right-style: solid;
}

.tx-cs2-tabs .tab-content {
  background-color: #E9E9E9;
  margin: 0 -20vw;
  color: #CED1D2;
  /*    @include media-breakpoint-down(sm) {
      margin: 0 -10vw;
    }*/
}

@media (max-width: 575.98px) {
  .tx-cs2-tabs .tab-content {
    margin: 0 -10px;
  }
}

.tx-cs2-tabs .tab-content .fade:not(.show) {
  display: none;
}

.tx-cs2-tabs .tab-content .tab-content-wrapper .tab-pane .table td, .tx-cs2-tabs .tab-content .tab-content-wrapper .tab-pane p {
  color: #CED1D2;
}

.tx-cs2-tabs .tab-content .tab-content-wrapper .tab-pane table th {
  color: #000;
  font-weight: 700;
}

.tx-cs2-tabs .tab-content .tab-content-wrapper .tab-pane .table td, .tx-cs2-tabs .tab-content .tab-content-wrapper .tab-pane .table th {
  background-color: #E9E9E9;
  border: none;
}

@media (max-width: 991.98px) {
  #tx-solr-search h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

#tx-solr-search .tx-solr-search-form .form-control {
  height: initial;
}

@media (max-width: 991.98px) {
  #tx-solr-search .tx-solr-search-form .form-control {
    line-height: 1;
  }
}

#tx-solr-search #tx-solr-faceting .facets-label {
  font-weight: 700;
}

#tx-solr-search #tx-solr-faceting .facet-area-main .facets .facet-option .active {
  background-color: #2DC6D6;
  border-color: #2DC6D6;
}

#tx-solr-search .search-result .results-teaser:not(span.fileLink) a {
  overflow-wrap: anywhere;
}

@media (max-width: 991.98px) {
  #tx-solr-search .search-result h1, #tx-solr-search .search-result h2, #tx-solr-search .search-result h3, #tx-solr-search .search-result h4, #tx-solr-search .search-result h5, #tx-solr-search .search-result h6,
  #tx-solr-search .search-result .h1, #tx-solr-search .search-result .h2, #tx-solr-search .search-result .h3, #tx-solr-search .search-result .h4, #tx-solr-search .search-result .h5, #tx-solr-search .search-result .h6 {
    margin-bottom: 16px;
  }
}

#tx-solr-search #tx-solr-search-form-pi-results {
  margin-bottom: 72px;
}

@media (max-width: 991.98px) {
  #tx-solr-search #tx-solr-search-form-pi-results {
    margin-bottom: 32px;
  }
}

#tx-solr-search #tx-solr-search-form-pi-results .input-box {
  max-width: 650px;
  margin: 0 auto;
}

#tx-solr-search #tx-solr-search-form-pi-results .input-box .form-control {
  height: 43px;
  border: 1px solid #fff;
  background: #E9E9E9;
  border-radius: 50px;
  color: #000;
  max-width: 585px;
}

#tx-solr-search #tx-solr-search-form-pi-results .input-box .form-control:focus {
  border-color: #2DC6D6;
}

#tx-solr-search #tx-solr-search-form-pi-results .input-box .btn {
  padding: 0 1rem;
  box-shadow: none;
  line-height: 1;
}

#tx-solr-search #tx-solr-search-form-pi-results .input-box .btn i {
  font-size: 1.5rem;
  vertical-align: middle;
}

#tx-solr-search .result__type {
  display: inline-block;
  text-align: center;
  font-family: "Verdana";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  vertical-align: bottom;
  padding: 10px 0;
}

#tx-solr-search .results-list .results-entry {
  border-bottom: 1px solid #CED1D2;
}

#tx-solr-search .results-list .results-entry:last-child {
  margin-bottom: 40px;
}

#tx-solr-search .results-list .results-entry:first-child {
  margin-top: 24px;
}

#tx-solr-search .results-list .results-entry .result__title_text {
  margin-left: 16px;
}

@media (max-width: 991.98px) {
  #tx-solr-search .results-list .results-entry .result__title_text {
    display: block;
    margin-top: 16px;
    margin-left: 0;
  }
}

#tx-solr-search .results-list .results-entry .result__type {
  width: 123px;
}

#tx-solr-search .results-list .results-entry .result__type--blue {
  color: #fff;
  background-color: #2DC6D6;
}

#tx-solr-search .facets .facet-option {
  margin-left: 16px;
}

#tx-solr-search .facets .facet-option .facet {
  line-height: 40px;
}

#tx-solr-search .facets .facet-option span.facet {
  color: #CED1D2;
  border-color: #CED1D2;
  cursor: unset;
}

#tx-solr-search .facets .facet-option span.facet:hover {
  background-color: transparent;
  color: #CED1D2;
}

@media (max-width: 991.98px) {
  #tx-solr-search .facets .facet-option {
    margin-left: 0;
    width: 100%;
  }
  #tx-solr-search .facets .facet-option .facet {
    width: 100%;
    padding: 0;
    margin-bottom: 16px;
  }
}

#tx-solr-search .facets .facet-option:first-child {
  margin-left: 0;
}

#tx-solr-search .facets .result__type {
  padding: 12px 16px;
  color: #2DC6D6;
  border: 1px solid #2DC6D6;
}

#tx-solr-search .facets .result__type.active {
  color: #fff;
  background-color: #2DC6D6;
}

@media (max-width: 991.98px) {
  #tx-solr-search .searchinfo {
    text-align: center;
  }
}

#tx-solr-search .result-content .results-highlight {
  /*font-weight: bold;*/
  color: #000;
  background-color: rgba(45, 198, 214, 0.2);
  padding: 2px 4px;
}

#tx-solr-search #results-per-page {
  text-align: right;
}

@media (max-width: 991.98px) {
  #tx-solr-search #results-per-page {
    text-align: center;
  }
}

#tx-solr-search #results-per-page .form-inline {
  display: inline;
}

#tx-solr-search #results-per-page .form-inline .btn-like-link {
  border: unset;
  background-color: transparent;
  color: #2DC6D6;
  cursor: pointer;
  text-decoration: underline;
}

#searchOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
}

#searchOverlay.open {
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 2000;
}

#searchOverlay .tx_solr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  color: #000;
  outline: none;
  border-bottom: 1px solid #CED1D2;
}

@media (max-width: 991.98px) {
  #searchOverlay .tx_solr {
    width: 95%;
  }
}

#searchOverlay .tx_solr input {
  border: 0;
  box-shadow: none;
}

#searchOverlay .tx_solr .input-group-btn {
  border: 0;
}

#searchOverlay .tx_solr .input-group-btn .btn {
  border-bottom-width: 0;
}

#searchOverlay .tx_solr .input-group-btn .btn:focus {
  box-shadow: none;
}

#searchOverlay .close {
  position: fixed;
  top: 15px;
  right: 15px;
}

.tx-solr-autosuggest {
  border: 1px solid #EDEDED;
  background: #fff;
  overflow: auto;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

.tx-solr-autosuggest a {
  text-decoration: none;
}

.tx-solr-autosuggest strong {
  font-weight: bolder;
  color: #2DC6D6;
}

.tx-solr-autosuggest .autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.tx-solr-autosuggest .autocomplete-selected {
  background: #F4F4F4;
}

.tx-solr-autosuggest .autocomplete-group {
  padding: 10px 5px;
}

.tx-solr-autosuggest .autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #707070;
}

/* cs2_brandslider */
.tx-cs2-brandslider .brand-wrapper {
  max-width: 1160px;
}

.tx-cs2-brandslider .swiper {
  width: 100%;
  height: 100%;
  overflow: initial;
}

.tx-cs2-brandslider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.tx-cs2-brandslider .swiper-slide img {
  display: block;
  opacity: 0.5;
}

.tx-cs2-brandslider .swiper-slide img:hover {
  opacity: 1;
}

.tx-cs2-brandslider .swiper-wrapper {
  align-items: center;
  transition-timing-function: linear;
}

@media only screen and (max-width: 430px) {
  .tx-cs2-brandslider .brandSliderDescription {
    text-align: center;
  }
}

.tx-cs2-brandslider .brandSliderHeader {
  width: 648px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.tx-cs2-heroslider .heroSlider {
  position: relative;
  height: 100%;
}

.tx-cs2-heroslider .swiper-wrapper {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.tx-cs2-heroslider .swiper-container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.tx-cs2-heroslider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.tx-cs2-heroslider .caption-align {
  display: flex;
  -ms-flex-align: center;
  z-index: 1;
  align-items: center;
  position: absolute;
  left: 81px;
  width: 516px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
}

.tx-cs2-heroslider .caption-align .caption-btn {
  margin-left: 20px;
  margin-bottom: 16px;
  display: inline-block;
  float: left;
  font-size: 14px;
}

.tx-cs2-heroslider .slider-title {
  padding-top: 14px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 4px;
  text-align: start;
  word-break: break-word;
  margin-bottom: 8px;
}

.tx-cs2-heroslider .full-width {
  width: 1920px;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tx-cs2-heroslider .swiper-button-prev {
  left: 12px;
  color: #fff;
}

.tx-cs2-heroslider .swiper-button-next {
  right: 51px;
  color: #fff;
}

.tx-cs2-heroslider .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background-color: #fff;
}

.tx-cs2-heroslider .swiper-pagination {
  bottom: 18px;
}

.tx-cs2-heroslider .button .text {
  font-weight: 700;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 16px;
  padding-left: 16px;
  word-break: break-all;
}

.tx-cs2-heroslider .swiper-socialmedia-logos {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 80px;
  margin-bottom: 48px;
  align-items: center;
  z-index: 10;
}

.tx-cs2-heroslider .swiper-socialmedia-logos .link-item {
  margin-top: 16px;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.tx-cs2-heroslider .swiper-socialmedia-logos .link-item .label {
  color: #fff;
  margin-right: 10px;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 55%;
  transform: translateY(-55%);
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.tx-cs2-heroslider .swiper-socialmedia-logos .link-item .logo-item {
  position: relative;
  z-index: 10;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.tx-cs2-heroslider .swiper-socialmedia-logos .link-item:hover .label {
  visibility: visible;
  right: 100%;
}

.tx-cs2-heroslider .swiper-socialmedia-logos .link-item:hover .logo-item {
  color: #2DC6D6;
}

.tx-cs2-heroslider .heroSlider-footer {
  background: url(../images/PAL_std_blue_rgb.png) 0 100%;
  position: absolute;
  left: 0;
  width: 100%;
  height: 10px;
}

.tx-cs2-heroslider .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tx-cs2-heroslider .swiper-slide .title {
  position: absolute;
  top: 210px;
  left: 10px;
  color: white;
}

.tx-cs2-heroslider .full-width {
  width: 100%;
}

@media (max-width: 991.98px) {
  .tx-cs2-heroslider .caption-align {
    width: 345px;
    font-size: 15px;
  }
}

@media (max-width: 575.98px) {
  .tx-cs2-heroslider .caption-align {
    margin-top: 0;
    width: 245px;
    left: 0;
    bottom: 0;
    margin-bottom: 160px;
  }
  .tx-cs2-heroslider .caption-align .caption-btn {
    font-size: 14px;
  }
  .tx-cs2-heroslider .button .text {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 14px;
    padding-left: 14px;
  }
  .tx-cs2-heroslider .slider-title {
    font-weight: 300;
    font-size: 25px;
    line-height: 34px;
    padding-bottom: 8px;
  }
  .tx-cs2-heroslider .swiper-button-prev {
    display: none;
  }
  .tx-cs2-heroslider .swiper-button-next {
    display: none;
  }
  .tx-cs2-heroslider .swiper-slide-custom {
    justify-content: end;
  }
  .tx-cs2-heroslider .swiper-socialmedia-logos {
    margin-right: 16px;
    margin-bottom: 60px;
  }
  .tx-cs2-heroslider .heroSlider-footer {
    background: url("../images/PAL_std_blue_rgb.png") 0 100%;
    padding-bottom: 3px;
  }
}

.tx-cs2-heroslider .div-only-mobile {
  height: 640px;
}

.tx-cs2-heroslider .div-no-mobile {
  height: 1014px;
}

.tx-cs2-heroslider .height-resize {
  height: calc(100vh - 10px);
}

@media screen and (max-width: 849px) {
  .tx-cs2-heroslider .height-resize {
    height: 640px;
  }
}

.v-vlite {
  --vlite-colorPrimary: $blue;
  --vlite-transition: 0.25s ease;
  /* Control bar */
  --vlite-controlBarHeight: 50px;
  --vlite-controlBarHorizontalPadding: 10px;
  --vlite-controlBarBackground: linear-gradient(to top, #000 -50%, transparent);
  /* Controls */
  --vlite-controlsColor: #fff;
  --vlite-controlsOpacity: 0.9;
  --vlite-controlsIconWidth: 28px;
  --vlite-controlsIconHeight: 28px;
  /* Progress bar */
  --vlite-progressBarHeight: 5px;
  --vlite-progressBarBackground: rgba(0 0 0 / 25%);
  font-family: -apple-system, blinkmacsystemfont, segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  position: relative;
  overflow: hidden;
}

.v-vlite,
.v-vlite * {
  box-sizing: border-box;
}

.v-vlite.v-audio {
  --vlite-controlsColor: #000;
  box-shadow: 0 2px 15px black;
  border-radius: 8px;
}

.v-vlite.v-video {
  background-color: #000;
  height: 0;
  padding-top: 56.25%;
}

.v-vlite:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

:-moz-full-screen .v-vlite {
  width: 100%;
  height: 100%;
}

.v-vlite:-moz-full-screen {
  width: 100%;
  height: 100%;
}

.v-vlite video:-webkit-media-controls {
  display: none !important;
}

.v-vlite.v-fullscreenButton-display .v-controlBar,
.v-vlite.v-fullscreenButton-display .v-bigPlay {
  position: fixed;
  z-index: 2147483647;
}

.v-vlite.v-paused .v-controlBar.hidden {
  opacity: 1;
}

.v-vlite.v-playing .v-poster, .v-vlite.v-paused:not(.v-firstStart) .v-poster {
  visibility: hidden;
  opacity: 0;
}

.v-vlite.v-playing .v-bigPlay {
  visibility: hidden;
  opacity: 0;
}

.v-vlite iframe.vlite-js {
  pointer-events: none;
}

.v-vlite .vlite-js,
.v-vlite .vlite-js > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.v-controlButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  transition: opacity var(--vlite-transition);
  opacity: var(--vlite-controlsOpacity);
  padding: 0;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.v-controlButton * {
  pointer-events: none;
}

.v-controlButton:hover {
  opacity: 1;
}

.v-controlButton.v-controlPressed .v-iconUnpressed,
.v-controlButton:not(.v-controlPressed) .v-iconPressed {
  display: none;
}

.v-controlButton:focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: -4px;
}

.v-controlButton svg {
  fill: var(--vlite-controlsColor);
  width: var(--vlite-controlsIconWidth);
  height: var(--vlite-controlsIconHeight);
}

.v-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.v-poster.v-active {
  opacity: 1;
  visibility: visible;
}

.v-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - var(--vlite-controlBarHeight));
}

.v-controlButtonIcon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.v-playPauseButton {
  flex-shrink: 0;
}

.v-time {
  color: var(--vlite-controlsColor);
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.v-progressBar {
  transition: height var(--vlite-transition), top var(--vlite-transition);
  z-index: 1;
  width: calc(100% - 2 * var(--vlite-controlBarHorizontalPadding));
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.v-progressBar:focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: 2px;
}

.v-progressBar::-webkit-slider-runnable-track {
  height: var(--vlite-progressBarHeight);
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  background: linear-gradient(to right, var(--vlite-colorPrimary) var(--value, 0), var(--vlite-progressBarBackground) var(--value, 0));
}

.v-progressBar::-moz-range-track {
  height: 100%;
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  background: linear-gradient(to right, var(--vlite-colorPrimary) var(--value, 0), var(--vlite-progressBarBackground) var(--value, 0));
}

.v-progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background: var(--vlite-colorPrimary);
  margin-top: -3px;
}

.v-progressBar::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background: var(--vlite-colorPrimary);
  margin-top: -3px;
}

.v-volumeButton {
  transition: transform var(--vlite-transition), opacity var(--vlite-transition);
  flex-shrink: 0;
}

.v-volumeButton.v-animate {
  transform: translateY(-3px);
}

.v-controlBar {
  position: relative;
  width: 100%;
  height: var(--vlite-controlBarHeight);
  padding-top: var(--vlite-progressBarHeight);
  text-align: right;
  padding-left: var(--vlite-controlBarHorizontalPadding);
  padding-right: var(--vlite-controlBarHorizontalPadding);
  transition: opacity var(--vlite-transition);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.v-controlBar:hover {
  opacity: 1 !important;
}

.v-controlBar.hidden {
  opacity: 0;
}

.v-audio .v-progressBar {
  order: 2;
  height: 25px;
}

.v-audio .v-controlBar {
  padding-top: 0;
}

.v-audio .v-playPauseButton {
  order: 1;
}

.v-audio .v-time {
  order: 3;
  padding: 0 8px 0 12px;
}

.v-audio .v-volumeButton {
  order: 4;
}

.v-video .v-progressBar {
  height: 5px;
}

.v-video .v-controlBar {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--vlite-controlBarBackground);
}

.v-video .v-controlBar .v-progressBar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.v-bigPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  transition: opacity var(--vlite-transition);
}

.v-bigPlay svg {
  fill: var(--vlite-controlsColor);
  transition: opacity var(--vlite-transition);
  opacity: var(--vlite-controlsOpacity);
  width: 100%;
  height: 100%;
}

.v-bigPlay:hover svg {
  opacity: 1;
}

.v-loading .v-bigPlay {
  opacity: 0;
}

.mb-gap {
  margin-bottom: 10px;
}

.vshadow-box {
  box-shadow: 0 0 26px rgba(214, 214, 214, 0.3);
}

.tx-cs2-map select::-ms-expand {
  display: none;
}

.tx-cs2-map .selectdiv {
  position: relative;
}

.tx-cs2-map .selectdiv.disabled:after {
  color: #CED1D2;
}

.tx-cs2-map .selectdiv:after {
  content: '>';
  font: 16px Consolas, monospace;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 16px;
  top: 16px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  color: #2dc6d6;
}

.tx-cs2-map .selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  max-width: 516px;
  height: 48px;
  margin: 5px 0;
  padding: 0 17px;
  color: #CED1D2;
  background-color: #fff;
  background-image: none;
  border: 1px solid #000000;
  border-radius: 3px;
  -ms-word-break: normal;
  word-break: normal;
}

.tx-cs2-map .selectdiv select:disabled {
  border-color: #CED1D2;
}

.tx-cs2-map .selectdiv option {
  color: #2DC6D6;
  padding-top: 18px;
  padding-bottom: 18px;
}

.tx-cs2-map .selectdiv option:focus {
  background: #2DC6D6;
  color: #fff;
}

.tx-cs2-map .selectdiv select:focus > option:checked {
  background: #2DC6D6;
  color: #fff;
}

.tx-cs2-map .filter-box .btn.find {
  width: 100%;
}

@media (min-width: 768px) {
  .tx-cs2-map .filter-box .btn.find {
    width: auto;
  }
}

.tx-cs2-map .filter-box .findall {
  text-align: center;
  width: 100%;
  display: inline-block;
}

@media (min-width: 768px) {
  .tx-cs2-map .filter-box .findall {
    text-align: unset;
    display: unset;
  }
}

.tx-cs2-map .item-wrap:hover {
  background-color: #EDEDED;
}

.tx-cs2-map .item-wrap .item-box {
  border: 2px solid #E9E9E9;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .tx-cs2-map .item-wrap .item-box {
    border: none;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tx-cs2-map .item-wrap .item-box .email a {
  overflow-wrap: anywhere;
}

.tx-cs2-map .image-box {
  background-color: #E9E9E9;
}

.tx-cs2-map .image-box img {
  width: 100px;
  height: 100px;
  margin: 16px 11px;
}

@media (min-width: 768px) {
  .tx-cs2-map .image-box {
    background-color: transparent;
  }
  .tx-cs2-map .image-box img {
    width: 186px;
    height: 186px;
    margin: 0;
  }
}

@media (max-width: 991.98px) {
  .tx-cs2-map .cs2map-teaser .h-map img {
    width: 100%;
  }
}

.tx-cs2-textimage .btn + .btn {
  margin-left: 1rem;
}

.textimg-wrapper {
  padding: 70px 0;
}

@media (max-width: 767.98px) {
  .textimg-wrapper {
    padding: 1rem 0;
  }
}

.text-overlay {
  height: 100%;
}

@media (max-width: 767.98px) {
  .text-overlay {
    height: auto;
    order: 1 !important;
    position: static;
  }
  .text-overlay:before {
    width: 100vw !important;
    top: 0 !important;
    bottom: 0 !important;
    left: -15px !important;
    right: -15px !important;
  }
}

.text-caption {
  font-size: 14px;
  line-height: 17px;
}

.layout-12 .text-overlay {
  margin-left: -100%;
}

@media (max-width: 767.98px) {
  .layout-12 .text-overlay {
    margin-left: 0 !important;
  }
}

.layout-12 .text-overlay:before {
  display: block;
  position: absolute;
  content: "";
  z-index: -1;
  background: #EDEDED;
  top: -70px;
  bottom: -70px;
  right: 0;
  width: calc(100vw - 25%);
}

.layout-12 .in-overlay {
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
}

.layout-12 .in-overlay .head-title:after {
  content: '';
  width: 100px;
  border-bottom: 5px solid #2DC6D6;
  display: block;
  padding-top: 1.375rem;
}

@media (max-width: 767.98px) {
  .layout-12 .in-overlay .head-title:after {
    padding-top: 8px;
  }
}

.layout-12 .order-first {
  order: 1;
}

.layout-12 .order-last {
  margin-left: -50%;
}

.layout-12 .order-last:before {
  left: -25%;
}

.layout-12 .caption-margin-left {
  margin-left: calc(31%);
  padding: 0;
}

@media (max-width: 767.98px) {
  .layout-12 .caption-margin-left {
    margin-left: 0;
    margin-right: 0;
  }
}

.layout-12 .caption-margin-right {
  margin-right: calc(25%);
  padding: 0;
}

@media (max-width: 767.98px) {
  .layout-12 .caption-margin-right {
    margin-left: 0;
    margin-right: 0;
  }
}

.layout-6 .text-overlay:before {
  display: block;
  position: absolute;
  content: "";
  z-index: -1;
  background: #EDEDED;
  top: -70px;
  bottom: -70px;
  right: -10%;
  width: calc(100vw - 90%);
}

.layout-6 .in-overlay {
  background: none;
  padding: 1rem;
}

@media (max-width: 767.98px) {
  .layout-6 .in-overlay {
    background: rgba(255, 255, 255, 0.8);
    margin-bottom: 1rem;
  }
}

.layout-6 .order-last:before {
  left: -10%;
  right: 0;
}

.layout-6 .caption-margin-left {
  /*margin-left: calc(10%);*/
  margin-left: 63px;
  padding: 0;
}

@media (max-width: 767.98px) {
  .layout-6 .caption-margin-left {
    margin-left: 0;
    margin-right: 0;
  }
}

.layout-6 .caption-margin-right {
  margin-right: calc(10%);
  padding: 0;
}

@media (max-width: 767.98px) {
  .layout-6 .caption-margin-right {
    margin-left: 0;
    margin-right: 0;
  }
}

.ratio-textimage6:after {
  padding-bottom: 68.49%;
}

.ratio-textimage12:after {
  padding-bottom: 38.46%;
}

.header-underlined {
  position: relative;
}

.header-underlined:after {
  display: block;
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100px;
  background: #2DC6D6;
  content: "";
}

.image-border {
  border: 1px solid #CED1D2;
}

.image-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.tx-cs2-textimage .landingpage-textimage .btn + .btn {
  margin-left: 1rem;
}

.textimg-wrapper {
  padding: 70px 0;
}

@media (max-width: 767.98px) {
  .textimg-wrapper {
    padding: 1rem 0;
  }
}

.text-overlay {
  height: 100%;
}

@media (max-width: 767.98px) {
  .text-overlay {
    height: auto;
    order: 1 !important;
    position: static;
  }
  .text-overlay:before {
    width: 100vw !important;
    top: 0 !important;
    bottom: 0 !important;
    left: -15px !important;
    right: -15px !important;
  }
}

.layout-12 .text-overlay {
  margin-left: -100%;
}

@media (max-width: 767.98px) {
  .layout-12 .text-overlay {
    margin-left: 0 !important;
  }
}

.layout-12 .text-overlay:before {
  display: block;
  position: absolute;
  content: "";
  z-index: -1;
  background: #EDEDED;
  top: -70px;
  bottom: -70px;
  right: 0;
  width: calc(100vw - 25%);
}

.layout-12 .in-overlay {
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
}

.layout-12 .in-overlay .head-title:after {
  content: '';
  width: 100px;
  border-bottom: 5px solid #2DC6D6;
  display: block;
  padding-top: 1.375rem;
}

@media (max-width: 767.98px) {
  .layout-12 .in-overlay .head-title:after {
    padding-top: 8px;
  }
}

.layout-12 .order-first {
  order: 1;
}

.layout-12 .order-last {
  margin-left: -50%;
}

.layout-12 .order-last:before {
  left: -25%;
}

.layout-6 .text-overlay:before {
  display: block;
  position: absolute;
  content: "";
  z-index: -1;
  background: #EDEDED;
  top: -70px;
  bottom: -70px;
  right: -10%;
  width: calc(100vw - 90%);
}

.layout-6 .in-overlay {
  background: none;
  padding: 1rem;
}

@media (max-width: 767.98px) {
  .layout-6 .in-overlay {
    background: rgba(255, 255, 255, 0.8);
    margin-bottom: 1rem;
  }
}

.layout-6 .order-last:before {
  left: -10%;
  right: 0;
}

.ratio-textimage6:after {
  padding-bottom: 68.49%;
}

.ratio-textimage12:after {
  padding-bottom: 38.46%;
}

.header-underlined {
  position: relative;
}

.header-underlined:after {
  display: block;
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100px;
  background: #2DC6D6;
  content: "";
}

.image-border {
  border: 1px solid #CED1D2;
}

.image-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.location-googlemap-part {
  background: #F2F2F2;
  flex-direction: column;
  padding: 0 0;
}

.location-googlemap-part .location-googlemap-part-title {
  font-size: 2.5rem;
  padding: 3.5rem 0 0.4rem 3.2rem;
}

.location-googlemap-part .location-googlemap-select-area {
  margin-bottom: 2rem;
}

.location-googlemap-part .location-googlemap-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 45%;
}

.location-googlemap-part .location-googlemap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.location-googlemap-part .location-records {
  display: none;
}

.location-googlemap-part .gm-style-iw-d h3 {
  font-family: Roboto;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
}

.location-googlemap-part .gm-style .gm-style-iw-t::after {
  background: none;
}

.location-googlemap-part .gm-style .gm-style-iw-c {
  border-radius: 0;
}

.watchlist-tooltip:after, .watchlist-tooltipmobile:after {
  content: '';
  position: absolute;
  left: 125px;
  top: 5px;
  width: 20px;
  height: 20px;
  background: #f4f4f4;
  -webkit-transform: rotate(45deg);
  transform: rotate(225deg);
  box-shadow: inset -6px -6px 0px -4px #2DC6D6;
}

.watchlist-tooltipmobile:after {
  right: 75px;
  left: unset;
}

.watchlist-dropdown-toggle:after {
  display: none;
}

.tx-cs2-productfinder .filter-button .svg-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.tx-cs2-triallicencemanager .form__group {
  margin: 10px 0 0;
}

.tx-cs2-triallicencemanager .form__group--error.form__serialno > input {
  background-color: #eb3b3b;
}

.tx-cs2-triallicencemanager .form__group--error.form__serialno > input[disabled] {
  background-color: #eb3b3b;
  color: #fff;
  opacity: 1;
}

.tx-cs2-triallicencemanager .form__group--success.form__serialno > input {
  background-color: #32c832;
}

.tx-cs2-triallicencemanager .form__group--success.form__serialno > input[disabled] {
  background-color: #32c832;
  color: #fff;
  opacity: 1;
}

.tx-cs2-triallicencemanager .form__serialno {
  /* display: block; */
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  border: 1px solid #ced1d2;
  height: 75px;
}

.tx-cs2-triallicencemanager .form__serialno:before,
.tx-cs2-triallicencemanager .form__serialno:after {
  display: table;
  content: '';
}

.tx-cs2-triallicencemanager .form__serialno:after {
  clear: both;
}

.tx-cs2-triallicencemanager .form__serialno > label {
  display: block;
  text-align: center;
  margin: 10px 0;
}

.tx-cs2-triallicencemanager .form__serialno > input[type="number"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.tx-cs2-triallicencemanager .form__serialno > input {
  display: inline-block;
  float: left;
  /* width: 15%; */
  /* height: 50px; */
  line-height: 48px;
  text-align: center;
  font-size: 36px;
  color: #181819;
  border: 0;
  border-bottom: 5px solid #000000;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;
  cursor: default;
  user-select: none;
  margin: 0;
  margin-top: 10px;
  /* margin-left: 2%; */
  /* margin-right: 2%; */
  /* padding: 0; */
}

.tx-cs2-triallicencemanager .form__serialno > input.invalid {
  color: #e5352d;
}

.tx-cs2-triallicencemanager .form__serialno.is-invalid > input,
.tx-cs2-triallicencemanager .form__serialno > input:is(:placeholder-shown) {
  border-bottom: 5px solid #e5352d;
}

.tx-cs2-triallicencemanager .form__serialno > input:focus {
  outline: 0;
  box-shadow: none;
  border-color: #000;
  animation: border-pulsate 1.5s infinite;
  -webkit-tap-highlight-color: transparent;
}

.tx-cs2-triallicencemanager .form__serialno > input:last-child {
  margin-right: 0;
}

.tx-cs2-triallicencemanager .form__serialno > input[disabled] {
  background: #eee;
  opacity: 1;
}

.tx-cs2-triallicencemanager label {
  font-weight: normal;
}

.tx-cs2-triallicencemanager .form-control.is-invalid {
  border-top: 1px solid #ced1d2;
  border-left: 1px solid #ced1d2;
  border-right: 1px solid #ced1d2;
  border-bottom: 5px solid #e5352d;
}

.tx-cs2-triallicencemanager .custom-control-label:before {
  border: #ced1d2 solid 2px !important;
}

.tx-cs2-triallicencemanager .custom-control-input.is-invalid ~ .custom-control-label::before {
  border: #e5352d solid 2px !important;
}

.tx-cs2-triallicencemanager .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff !important;
}

.tx-cs2-triallicencemanager .custom-control-input:checked ~ .custom-control-label::after {
  color: #000000 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.tx-cs2-triallicencemanager .alert-danger {
  background-color: #ffffff;
  border: 2px solid #e5352d;
  color: #e5352d;
  font-weight: bold;
}

.tx-cs2-triallicencemanager table#licences-list th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  padding-right: 28px !important;
}

.tx-cs2-triallicencemanager table#licences-list td {
  padding: 8px !important;
}

/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/dt-1.13.8
 *
 * Included libraries:
 *   DataTables 1.13.8
 */
:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: rgb(33, 37, 41);
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  content: "▶";
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  content: "▼";
}

html.dark table.dataTable td.dt-control:before {
  color: rgba(255, 255, 255, 0.5);
}

html.dark table.dataTable tr.dt-hasChild td.dt-control:before {
  color: rgba(255, 255, 255, 0.5);
}

table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting_asc_disabled, table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  padding-right: 26px;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.6;
  right: 10px;
  line-height: 9px;
  font-size: 0.8em;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  bottom: 50%;
  content: "▲";
  content: "▲"/"";
  opacity: 0.6;
}

table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 50%;
  content: "▼";
  content: "▼"/"";
}

table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before {
  display: none;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

div.dataTables_scrollBody > table.dataTable > thead > tr > th:before, div.dataTables_scrollBody > table.dataTable > thead > tr > th:after,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:before,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:after {
  display: none;
}

div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 2px;
  z-index: 10;
}

div.dataTables_processing > div:last-child {
  position: relative;
  width: 80px;
  height: 15px;
  margin: 1em auto;
}

div.dataTables_processing > div:last-child > div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0d6efd;
  background: #rgb var(--dt-row-selected);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

div.dataTables_processing > div:last-child > div:nth-child(1) {
  left: 8px;
  animation: datatables-loader-1 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(2) {
  left: 8px;
  animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(3) {
  left: 32px;
  animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(4) {
  left: 56px;
  animation: datatables-loader-3 0.6s infinite;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: left;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */
}

table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable > thead > tr > th,
table.dataTable > thead > tr > td {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
  outline: none;
}

table.dataTable > tfoot > tr > th,
table.dataTable > tfoot > tr > td {
  padding: 10px 10px 6px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

table.dataTable tbody tr {
  background-color: transparent;
}

table.dataTable tbody tr.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);
  color: white;
  color: #rgb var(--dt-row-selected-text);
}

table.dataTable tbody tr.selected a {
  color: #090a0b;
  color: #rgb var(--dt-row-selected-link);
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable.row-border > tbody > tr > th,
table.dataTable.row-border > tbody > tr > td, table.dataTable.display > tbody > tr > th,
table.dataTable.display > tbody > tr > td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.row-border > tbody > tr:first-child > th,
table.dataTable.row-border > tbody > tr:first-child > td, table.dataTable.display > tbody > tr:first-child > th,
table.dataTable.display > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td, table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0262ef;
}

table.dataTable.cell-border > tbody > tr > th,
table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border > tbody > tr > th:first-child,
table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border > tbody > tr:first-child > th,
table.dataTable.cell-border > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.stripe > tbody > tr.odd > *, table.dataTable.display > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), 0.023);
}

table.dataTable.stripe > tbody > tr.odd.selected > *, table.dataTable.display > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.923);
}

table.dataTable.hover > tbody > tr:hover > *, table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.035);
}

table.dataTable.hover > tbody > tr.selected:hover > *, table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}

table.dataTable.order-column > tbody tr > .sorting_1,
table.dataTable.order-column > tbody tr > .sorting_2,
table.dataTable.order-column > tbody tr > .sorting_3, table.dataTable.display > tbody tr > .sorting_1,
table.dataTable.display > tbody tr > .sorting_2,
table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
}

table.dataTable.order-column > tbody tr.selected > .sorting_1,
table.dataTable.order-column > tbody tr.selected > .sorting_2,
table.dataTable.order-column > tbody tr.selected > .sorting_3, table.dataTable.display > tbody tr.selected > .sorting_1,
table.dataTable.display > tbody tr.selected > .sorting_2,
table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}

table.dataTable.display > tbody > tr.odd > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.054);
}

table.dataTable.display > tbody > tr.odd > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.047);
}

table.dataTable.display > tbody > tr.odd > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.039);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.954);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.947);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.939);
}

table.dataTable.display > tbody > tr.even > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.019);
}

table.dataTable.display > tbody > tr.even > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.011);
}

table.dataTable.display > tbody > tr.even > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.003);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.911);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.903);
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.082);
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.074);
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.062);
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.982);
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.974);
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.962);
}

table.dataTable.no-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

table.dataTable.compact thead th,
table.dataTable.compact thead td,
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td,
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: inherit;
  padding: 4px;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: inherit;
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: inherit !important;
  border: 1px solid transparent;
  border-radius: 2px;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: inherit !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.05);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(230, 230, 230, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #111;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #0c0c0c;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: inherit;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

html.dark {
  --dt-row-hover: 255, 255, 255;
  --dt-row-stripe: 255, 255, 255;
  --dt-column-ordering: 255, 255, 255;
}

html.dark table.dataTable > thead > tr > th,
html.dark table.dataTable > thead > tr > td {
  border-bottom: 1px solid #595b5e;
}

html.dark table.dataTable > thead > tr > th:active,
html.dark table.dataTable > thead > tr > td:active {
  outline: none;
}

html.dark table.dataTable > tfoot > tr > th,
html.dark table.dataTable > tfoot > tr > td {
  border-top: 1px solid #595b5e;
}

html.dark table.dataTable.row-border > tbody > tr > th,
html.dark table.dataTable.row-border > tbody > tr > td, html.dark table.dataTable.display > tbody > tr > th,
html.dark table.dataTable.display > tbody > tr > td {
  border-top: 1px solid #404346;
}

html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td, html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0257d5;
}

html.dark table.dataTable.cell-border > tbody > tr > th,
html.dark table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #404346;
  border-right: 1px solid #404346;
}

html.dark table.dataTable.cell-border > tbody > tr > th:first-child,
html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #404346;
}

html.dark .dataTables_wrapper .dataTables_filter input,
html.dark .dataTables_wrapper .dataTables_length select {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--dt-html-background);
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: 1px solid #595b5e;
  background: rgba(255, 255, 255, 0.15);
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #666 !important;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #353535;
  background: #353535;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3a3a3a;
}

.tx-cs2-newsroom.bg-hub {
  padding-bottom: 30px;
}

.object-cover {
  object-fit: cover;
  object-position: center;
}

.mb-grid {
  margin-bottom: 20px;
}

.gap-grid {
  gap: 20px;
}

.news-item {
  background: #fff;
}

.news-item.detail-item .news-image img {
  aspect-ratio: 4;
}

.news-item.featured-item {
  height: 100%;
}

.news-item.featured-item .news-image {
  height: 0;
  padding-bottom: 44.4%;
  overflow: hidden;
}

.news-item.featured-item .news-image img {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  aspect-ratio: 2.25;
}

.news-item.small-item {
  height: 33%;
}

.news-item.small-item .news-image {
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .news-item.small-item .news-image {
    padding-bottom: 44.4%;
  }
}

.news-item.small-item .news-image img {
  aspect-ratio: 1;
  min-height: 100%;
  min-width: 100%;
  height: auto;
}

@media (max-width: 991.98px) {
  .news-item.small-item .news-image img {
    aspect-ratio: 2.25;
  }
}

.news-item .news-title,
.news-item .news-text {
  overflow: hidden;
  line-clamp: 3;
  margin-bottom: 0;
}

.news-item .news-time {
  font-size: 80%;
}

.news-item .news-date,
.news-item .news-author {
  font-size: 80%;
  font-style: italic;
}

.news-item .news-tags {
  position: absolute;
  background: #2DC6D6;
  color: #fff;
  font-size: 0.8125rem;
  bottom: 0;
  left: 0;
  padding: 3px;
  line-height: 1;
}

.news-item .news-type {
  position: absolute;
  background: #000;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
}

.news-item .news-infos {
  position: absolute;
  background: rgba(244, 244, 244, 0.75);
  bottom: 0;
  right: 10px;
  line-height: 1;
  font-style: normal;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .news-item .news-infos {
    right: 5px;
  }
}

.news-item .news-infos .news-date {
  font-style: normal;
}

.font-size-small {
  font-size: 80%;
}

.author-image img {
  aspect-ratio: 1;
  border: 2px solid #fff;
  /*@include media-breakpoint-down(md) {
    aspect-ratio: 2.25;
  }*/
}

.circle-icon {
  display: inline-flex;
  position: relative;
  margin: .35rem;
  width: 2rem;
  height: 2rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle-icon:after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  z-index: -1;
  background: #2DC6D6;
  border-radius: 50%;
}

.page-normal .news-item.small-item {
  height: auto;
}

.header-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767.98px) {
  .header-content {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.header-content .hamburger {
  margin-left: 4.875rem !important;
}

@media (max-width: 767.98px) {
  .header-content .hamburger {
    margin-left: 45px !important;
  }
}

.header-content .hamburger .hamburger-box {
  width: 33px;
  height: 30px;
}

.header-content .hamburger .hamburger-box .hamburger-inner, .header-content .hamburger .hamburger-box .hamburger-inner::after, .header-content .hamburger .hamburger-box .hamburger-inner::before {
  width: 33px;
  height: 6px;
  background-color: #2DC6D6;
  border-radius: 3px;
}

@media (max-width: 767.98px) {
  .header-content .hamburger .hamburger-box .hamburger-inner, .header-content .hamburger .hamburger-box .hamburger-inner::after, .header-content .hamburger .hamburger-box .hamburger-inner::before {
    height: 4px;
    width: 25px;
  }
}

.header-content .hamburger .hamburger-box .hamburger-inner:before {
  top: -11px;
}

@media (max-width: 767.98px) {
  .header-content .hamburger .hamburger-box .hamburger-inner:before {
    top: -9px;
  }
}

.header-content .hamburger .hamburger-box .hamburger-inner:after {
  bottom: -11px;
}

@media (max-width: 767.98px) {
  .header-content .hamburger .hamburger-box .hamburger-inner:after {
    bottom: -9px;
  }
}

.header-content .hamburger.hamburger--spin.is-active .hamburger-inner, .header-content .hamburger.hamburger--spin.is-active .hamburger-inner::after, .header-content .hamburger.hamburger--spin.is-active .hamburger-inner::before {
  background-color: #000;
}

.header-content .hamburger.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
}

.header-content.fly-open {
  background-color: #fff !important;
}

.header-content.sticky:not(.fly-open) {
  background-color: #fff;
}

.header-content.sticky:not(.fly-open) .header-content__logo-image._negative {
  display: inline !important;
}

.header-content.sticky:not(.fly-open) .header-content__logo-image._positive {
  display: none;
}

.header-content .logo {
  padding: 0 26px;
  margin-left: -26px;
}

.header-content .logo img {
  width: 224px;
  height: auto;
}

@media (max-width: 767.98px) {
  .header-content .logo img {
    width: 107px;
  }
}

.header-content .main-navigation {
  position: initial;
}

.header-content .main-navigation .flyoutmenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  border: 0;
  border-radius: 0;
  backdrop-filter: blur(7px);
  z-index: 1;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout {
  padding-top: 4.6875rem;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .padding {
  padding-right: 20%;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item .nav-link {
  color: #000;
  margin: 0;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item .nav-link.active, .header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item .nav-link:hover, .header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item .nav-link.has-sub:not(.collapsed) {
  color: #000;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item .nav-link.overview {
  color: #2DC6D6;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item + .nav-item .nav-link {
  margin-top: 1rem;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .nav-item.highlight {
  opacity: .3;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .level2 .nav .nav-item:first-child .nav-link,
.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .level3 .nav .nav-item:first-child .nav-link {
  padding-top: 0;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .level2 .nav .nav-item .nav-link,
.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .level3 .nav .nav-item .nav-link {
  border-bottom: 1px solid #CED1D2;
  padding: 1rem 0;
  margin: 0;
}

.header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .menu-level2.show, .header-content .main-navigation .flyoutmenu .flyout-wrapper .flyout .menu-level3.show {
  position: sticky;
  top: 30px;
}

.header-content .metaNavigation-spacer {
  height: 30px;
  width: 1px;
  background-color: #CED1D2;
  margin-left: 13px;
  margin-right: 16px;
}

.header-content #regionsDropdown .fa-globe {
  font-size: 14px;
  color: #646567;
  margin-right: 4px;
}

.header-content #regionsDropdown .regionLabel {
  color: #646567;
  font-size: 14px;
}

.header-content .metaNavigation {
  padding-top: 26px;
}

.header-content .metaNavigation .navbar-nav .nav-item:last-child a {
  padding-right: 0;
}

.header-content .metaNavigation nav .navbar-nav .nav-item .nav-link {
  padding-top: 3px;
  padding-bottom: 3px;
}

.header-content .hamburgersearch {
  padding: 26px 0;
}

.header-content .hamburgersearch:last-child .tx_solr .tx-solr-submit {
  padding-right: 0 !important;
}

.header-content .hamburgersearch .nav-item:last-child .nav-link {
  padding-right: 0;
}

@media (max-width: 767.98px) {
  .header-content .hamburgersearch {
    padding: 14px 0 !important;
  }
}

.header-content .hamburgersearch #loginNav .nav-link {
  font-variant: normal;
  text-transform: capitalize;
}

.header-content .hamburgersearch #loginNav #loginDropdown {
  padding: 8px;
  border: 1px solid #CED1D2;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.header-content .hamburgersearch #loginNav #loginDropdown i {
  padding-left: 10px;
  color: #2DC6D6;
}

.header-content .hamburgersearch #langNav .border-rightlang {
  border-color: #CED1D2;
  padding-right: 1rem;
}

.header-content .hamburgersearch #langNav .border-rightlang #langsDropdown {
  padding: 8px;
  border: 1px solid #CED1D2;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.header-content .hamburgersearch #langNav .border-rightlang #langsDropdown i {
  padding-left: 10px;
  color: #2DC6D6;
}

.header-content .hamburgersearch .tx_solr .input-group .form-control {
  height: 43px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 50px;
  color: #000;
}

.header-content .hamburgersearch .tx_solr .input-group .form-control:focus {
  border-color: #2DC6D6;
}

.header-content .hamburgersearch .tx_solr .input-group .btn {
  padding: 0 1rem;
  box-shadow: none;
  line-height: 1;
}

.header-content .hamburgersearch .tx_solr .input-group .btn i {
  font-size: 1.5rem;
  vertical-align: middle;
}

.main-header .stickySearch {
  top: auto !important;
}

.main-header.nokeyvisual {
  box-shadow: -13px 13px 26px rgba(214, 214, 214, 0.3), 13px -13px 26px rgba(214, 214, 214, 0.3);
}

.main-header.nokeyvisual .header-content, .main-header.active .header-content, .main-header:hover .header-content, .main-header.sticky .header-content, .main-header.hideme .header-content {
  background-color: #fff;
  transition: all .35s ease;
}

.main-header.nokeyvisual .tx_solr .input-group .form-control, .main-header.active .tx_solr .input-group .form-control, .main-header:hover .tx_solr .input-group .form-control, .main-header.sticky .tx_solr .input-group .form-control, .main-header.hideme .tx_solr .input-group .form-control {
  background: #EDEDED;
}

.main-header.hideme .header-content .hamburgersearch {
  padding: 33px 0;
}

.main-header.hideme .metaNavigation {
  display: none !important;
}

.main-header:not(.active) .header-content {
  backdrop-filter: blur(7px);
}

#mobileSearchMenu,
#mobileMainNavigation {
  transform: translate3d(-100%, 0, 0);
  transition: all .5s;
  z-index: 10;
  /* cover */
}

#mobileSearchMenu.active,
#mobileMainNavigation.active {
  transform: translate3d(0, 0, 0);
}

#mobileSearchMenu .search-icon,
#mobileSearchMenu .mp-back,
#mobileMainNavigation .search-icon,
#mobileMainNavigation .mp-back {
  display: block;
  border-bottom: 1px solid #CED1D2;
}

#mobileSearchMenu .navbar-nav .nav-item,
#mobileMainNavigation .navbar-nav .nav-item {
  border-bottom: 1px solid #CED1D2;
}

#mobileSearchMenu .navbar-nav .nav-item .nav-link,
#mobileMainNavigation .navbar-nav .nav-item .nav-link {
  padding: 1rem 0 !important;
  justify-content: space-between;
  display: flex;
  color: #000;
  font-family: "Open Sans Condensed Light";
}

#mobileSearchMenu .navbar-nav .nav-item .nav-link i,
#mobileMainNavigation .navbar-nav .nav-item .nav-link i {
  color: #2DC6D6;
  font-size: 32px;
}

#mobileSearchMenu .navbar-nav .nav-item.active > a, #mobileSearchMenu .navbar-nav .nav-item:hover > a,
#mobileMainNavigation .navbar-nav .nav-item.active > a,
#mobileMainNavigation .navbar-nav .nav-item:hover > a {
  color: #2DC6D6;
}

#mobileSearchMenu .mobileNavFooter,
#mobileMainNavigation .mobileNavFooter {
  background-color: #EDEDED;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin {
  background-color: #CED1D2;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .loginNav .nav-link,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .loginNav .nav-link {
  padding-left: 32px;
  font-variant: normal;
  text-transform: capitalize;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .loginNav #loginDropdown,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .loginNav #loginDropdown {
  padding: 8px;
  border: 1px solid #000;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .loginNav #loginDropdown i,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .loginNav #loginDropdown i {
  padding-left: 10px;
  color: #2DC6D6;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .langNav .border-rightlang,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .langNav .border-rightlang {
  border-color: #000 !important;
  padding-right: 32px;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .langNav .border-rightlang #langsDropdown,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .langNav .border-rightlang #langsDropdown {
  padding: 8px;
  border: 1px solid #000;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

#mobileSearchMenu .mobileNavFooter .mobileLangLogin .langNav .border-rightlang #langsDropdown i,
#mobileMainNavigation .mobileNavFooter .mobileLangLogin .langNav .border-rightlang #langsDropdown i {
  padding-left: 10px;
  color: #2DC6D6;
}

#mobileSearchMenu .mobileNavFooter .mobileMeta .nav-item,
#mobileMainNavigation .mobileNavFooter .mobileMeta .nav-item {
  border: 0;
}

#mobileSearchMenu .mobileNavFooter .mobileMeta .nav-item .nav-link,
#mobileMainNavigation .mobileNavFooter .mobileMeta .nav-item .nav-link {
  padding: 8px 0 !important;
}

#mobileSearchMenu .mobileNavFooter .mobileMeta .nav-item:first-child .nav-link,
#mobileMainNavigation .mobileNavFooter .mobileMeta .nav-item:first-child .nav-link {
  padding-top: 2px !important;
}

#mobileSearchMenu.flyout-menu,
#mobileMainNavigation.flyout-menu {
  left: 0;
  top: auto;
  position: fixed;
  width: 80%;
  height: 100%;
}

#mobileSearchMenu .mp-level.mp-level-open,
#mobileMainNavigation .mp-level.mp-level-open {
  transform: translate3d(0, 0, 0);
}

#mobileSearchMenu .mp-level.mp-level-open.active > .mp-back,
#mobileSearchMenu .mp-level.mp-level-open.active > .nav,
#mobileMainNavigation .mp-level.mp-level-open.active > .mp-back,
#mobileMainNavigation .mp-level.mp-level-open.active > .nav {
  visibility: visible;
}

#mobileSearchMenu .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open),
#mobileMainNavigation .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  transform: translate3d(100%, 0, 0);
}

#mobileSearchMenu .mp-level,
#mobileMainNavigation .mp-level {
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  transition: all 0.5s;
  z-index: 1;
}

#mobileSearchMenu .mp-level > .mp-back,
#mobileSearchMenu .mp-level > .nav,
#mobileMainNavigation .mp-level > .mp-back,
#mobileMainNavigation .mp-level > .nav {
  visibility: hidden;
}

#mobileSearchMenu .level-0,
#mobileMainNavigation .level-0 {
  font-size: 25px;
  font-weight: 300;
  font-family: "Open Sans Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#mobileSearchMenu .level-1,
#mobileMainNavigation .level-1 {
  font-size: 22px;
}

#mobileSearchMenu .metanav .nav-link,
#mobileMainNavigation .metanav .nav-link {
  line-height: 1.5;
  font-size: 1.125rem;
}

#mobileSearchMenu .metanav .nav-link .svg-icon,
#mobileMainNavigation .metanav .nav-link .svg-icon {
  margin-top: -5px;
  margin-right: 10px;
}

#mobileSearchMenu .mp-level {
  background-color: rgba(255, 255, 255, 0.8);
}

#mobileSearchMenu .tx_solr {
  padding: 1rem;
  background-color: #fff;
}

#mobileSearchMenu .tx_solr .form-control {
  background-color: #fff;
}

#mobileSearch.active i {
  font-weight: 400;
}

#mobileSearch.active i:before {
  content: "\f00d";
}

.main-footer {
  padding: 0;
  background: #fff;
}

.main-footer .mainFooter {
  margin: 4.875rem 0 3.75rem 0;
}

@media (max-width: 991.98px) {
  .main-footer .mainFooter {
    margin: 15px 0;
  }
}

.main-footer .mainFooter .footer-list .nav-item .nav-link {
  padding: .5rem 0;
}

.main-footer .mainFooter .footer-list .nav-item:first-child .nav-link {
  padding-top: 0;
}

@media (min-width: 768px) {
  .main-footer .mainFooter .collapse {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .main-footer .mainFooter .collapsing {
    position: relative;
    height: unset !important;
    overflow: hidden;
  }
}

.main-footer #footerbar {
  background: #EDEDED;
  padding: 0.75rem 0;
}

.main-footer h3 {
  -webkit-appearance: none;
}

.main-footer h3 i {
  transition: all 0.2s ease-in-out;
}

.main-footer h3:not(.collapsed) i {
  transform: rotate(180deg);
}

.main-footer a:not(.btn) {
  text-decoration: none;
}

.main-footer a:not(.btn):hover {
  text-decoration: underline;
  color: #000;
}

@media (max-width: 575.98px) {
  .main-footer.offer-visible {
    margin-bottom: var(--offer) !important;
  }
}

#mobilefooterbar {
  background-color: #0B72B5;
}

#mobilefooterbar .copyright {
  font-size: 15px;
  padding: 15px 0;
}

.footerRowWrapper {
  background-color: #2DC6D6;
  color: #fff;
  padding: 1rem 0;
}

@media (max-width: 767.98px) {
  .footerRowWrapper .nav:not(.social) > .nav-item + .nav-item {
    margin-top: 1rem;
  }
}

.footerRowWrapper a {
  color: #fff;
}

.footerRowWrapper a i {
  padding-right: 10px;
}

.footerRowWrapper .social .nav-item .nav-link {
  font-size: 2rem;
}

.footerRowWrapper .social .nav-item + .nav-item {
  padding-left: 1rem;
}

.landingpage-thermofisher .main-footer a {
  color: #000 !important;
  text-decoration: underline;
}

.landingpage-thermofisher .main-footer a:hover {
  color: #e5352d !important;
}

.landingpage-thermofisher .main-footer .landingpage-footer p:last-child {
  margin-bottom: 0;
}

.stickyButtons {
  right: 10px;
  bottom: 6.25rem;
  z-index: 10;
}

@media (max-width: 575.98px) {
  .stickyButtons {
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.stickyButtons .closebtn .collapsbuttons .closeicon {
  display: block;
}

.stickyButtons .closebtn .collapsbuttons .openicon {
  display: none;
}

.stickyButtons .closebtn .collapsed .closeicon {
  display: none;
}

.stickyButtons .closebtn .collapsed .openicon {
  display: block;
}

.stickyButtons .stickyBtn {
  border-radius: 50px;
  line-height: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

.stickyButtons .stickyBtn i {
  font-size: 2rem;
}

.stickyButtons .stickyFaq span {
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  max-width: 0;
  overflow: hidden;
  word-break: keep-all;
}

@media (max-width: 575.98px) {
  .stickyButtons .stickyFaq {
    margin-right: 10px;
  }
  .stickyButtons .stickyFaq i {
    font-size: 1.5rem;
  }
  .stickyButtons .stickyFaq svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.stickyButtons .stickyFaq:hover span {
  max-width: 100%;
  margin-left: 8px;
}

@media (max-width: 575.98px) {
  .stickyButtons .offerWrap {
    padding: 12px;
    background-color: #fff;
    width: 100%;
  }
}

.stickyButtons .offerWrap .stickyOffer i {
  font-size: 45px;
}

@media (max-width: 575.98px) {
  .stickyButtons .offerWrap .stickyOffer {
    margin-right: 10px;
  }
  .stickyButtons .offerWrap .stickyOffer i {
    font-size: 24px;
  }
}

.main-container.nokeyvisual {
  padding-top: var(--header) !important;
}

.main-container .news-item.detail-item {
  padding-top: var(--header) !important;
}
