.pic-gallery {
  .h-gal {
    height: 355px;
    @include media-breakpoint-down(sm) {
      height: 135px;
    }
  }
  .col-12 {
    .h-gal {
      @include media-breakpoint-down(sm) {
        height: 205px;
      }
    }
  }
  .col {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    @include media-breakpoint-down(md) {
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 8px;
    }
  }
}
.imagefancybox {
  cursor: pointer;
  .overlay {
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(24, 95, 165, 0.8);
    text-align: center;
    transition: all .35s ease;
    i {
      color: $white;
      font-size: 40px;
      opacity: 0;
    }
  }
  &:hover {
    .overlay {
      width: 100%;
      i {
        opacity: 1;
      }
    }
  }
}
figure {
  figcaption {
    margin-top: 10px;
    color: #706F6F;
  }
}
.fancybox__container {
  .fancybox__backdrop {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(7px);
  }
  .fancybox__toolbar {
    background: none;
    padding-top: 40px;
    padding-right: 80px;
    @include media-breakpoint-down(md) {
      padding-top: 16px;
      padding-right: 16px;
    }
    .carousel__button {
      text-shadow: 0px 4px 30px rgba(19, 0, 35, 0.8);
    }
  }
  .fancybox__carousel {
    .fancybox__viewport {
      .fancybox__slide {
        .fancybox__content {
          filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
        }
        .fancybox__caption {
          color: $black;
        }
      }
    }
  }
  .carousel__button {
    color: $primary;
    font-size: 2rem;
    &.fancybox__button--close {
      color: $black;
    }
  }
  .fancybox__nav {
    .carousel__button {
      text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .carousel__dots {
    top: calc(100% - 2.5rem);
    @include media-breakpoint-down(md) {
      top: calc(100% - 23px);
    }
    .carousel__dot {
      &.is-selected {
        &:after {
          width: 16px;
          height: 16px;
          opacity: 1;
        }
      }
      &:after {
        width: 10px;
        height: 10px;
        opacity: .5;
        box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.ce-gall {
  img {
    filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
  }
}
