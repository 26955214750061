.tx-cs2-map {
  select::-ms-expand {
    display: none;
  }

  .selectdiv {
    position: relative;

    &.disabled {
      &:after {
        color: $gray-600;
      }
    }

    &:after {
      content: '>';
      font: 16px Consolas, monospace;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 16px;
      top: 16px;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
      color: #2dc6d6;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      max-width: 516px;
      height: 48px;
      margin: 5px 0;
      padding: 0 17px;
      color: #CED1D2;
      background-color: #fff;
      background-image: none;
      border: 1px solid #000000;
      border-radius: 3px;
      -ms-word-break: normal;
      word-break: normal;

      &:disabled {
        border-color: $gray-600;
      }
    }

    option {
      color: $primary;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    option:focus {
      background: $primary;
      color: $white;
    }

    select:focus > option:checked {
      background: $primary;
      color: $white;
    }
  }

  .filter-box {
    .btn.find {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    .findall {
      text-align: center;
      width: 100%;
      display: inline-block;

      @include media-breakpoint-up(md) {
        text-align: unset;
        display: unset;
      }
    }
  }

  .item-wrap {

    &:hover {
      background-color: $gray-400;
    }


    .item-box {
      border: 2px solid $gray-300;
      margin-left: 0;
      margin-right: 0;

      @include media-breakpoint-up(md) {
        border: none;
        margin-left: 10px;
        margin-right: 10px;
      }
      .email {
        a {
          overflow-wrap: anywhere;
        }
      }
    }
  }

  .image-box {
    background-color: $gray-300;

    img {
      width: 100px;
      height: 100px;
      margin: 16px 11px;
    }
    @include media-breakpoint-up(md) {
      background-color: transparent;

      img {
        width: 186px;
        height: 186px;
        margin: 0;
      }
    }
  }


  .cs2map-teaser {
    .h-map {
      img {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
}

