.tx-cs2-textimage .landingpage-textimage {
  .btn + .btn {
    margin-left: 1rem;
  }
}

.textimg-wrapper {
  padding: 70px 0;
  @include media-breakpoint-down(sm) {
    padding: 1rem 0;
  }
}
.text-overlay {
  height: 100%;
  @include media-breakpoint-down(sm) {
    height: auto;
    order: 1 !important;
    position: static;
    &:before {
      width: 100vw !important;
      top: 0 !important;
      bottom: 0 !important;
      left: -15px !important;
      right: -15px !important;
    }
  }
}

.layout-12 {

  .text-overlay {
    margin-left: -100%;
    @include media-breakpoint-down(sm) {
      margin-left: 0 !important;
    }
  }
  .text-overlay:before {
    display: block;
    position: absolute;
    content: "";
    z-index: -1;
    background: $gray-400;
    top: -70px;
    bottom: -70px;
    right: 0;
    width: calc(100vw - 25%);
  }
  .in-overlay {
    background: rgba($white, 0.8);
    padding: 1rem;
    .head-title {
      &:after {
        content:'';
        width: 100px;
        border-bottom: 5px solid $primary;
        display: block;
        padding-top: 1.375rem;
        @include media-breakpoint-down(sm) {
          padding-top: 8px;
        }
      }
    }
  }

  .order-first {
    order: 1;
  }
  .order-last {
    margin-left: -50%;
    &:before {
      left: -25%;
    }
  }
}

.layout-6 {
  .text-overlay:before {
    display: block;
    position: absolute;
    content: "";
    z-index: -1;
    background: $gray-400;
    top: -70px;
    bottom: -70px;
    right: -10%;
    width: calc(100vw - 90%);
  }
  .in-overlay {
    background: none;
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      background: rgba($white, 0.8);
      margin-bottom: 1rem;
    }
  }
  .order-last {
    &:before {
      left: -10%;
      right: 0;
    }
  }
}
.ratio-textimage6 {
  &:after {
    padding-bottom: 68.49%;
  }
}
.ratio-textimage12 {
  &:after {
    padding-bottom: 38.46%;
  }
}
.header-underlined {
  position: relative;
  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 100px;
    background: $primary;
    content: "";
  }
}

.image-border {
    border: 1px solid $gray-600;
}


.image-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
