.header-content {
  position: relative;
  background-color: $white-50;

  @include media-breakpoint-down(sm) {
    background-color: $white-90;
  }
  .hamburger {
    margin-left: 4.875rem !important;
    @include media-breakpoint-down(sm) {
      margin-left: 45px !important;
    }
    .hamburger-box {
      width: 33px;
      height: 30px;

      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        width: 33px;
        height: 6px;
        background-color: $blue;
        border-radius: 3px;
        @include media-breakpoint-down(sm) {
          height: 4px;
          width: 25px;
        }
      }
      .hamburger-inner {
        &:before {
          top: -11px;
          @include media-breakpoint-down(sm) {
            top: -9px;
          }
        }
        &:after {
          bottom: -11px;
          @include media-breakpoint-down(sm) {
            bottom: -9px;
          }
        }
      }
    }
    &.hamburger--spin {
      &.is-active{
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
          background-color: $black;
        }
        .hamburger-inner {
          &:after {
            bottom: 0;
          }
        }
      }
    }
  }
  &.fly-open {
    background-color: $white !important;
  }
  &.sticky:not(.fly-open) {
    background-color: $white;
    .header-content__logo-image {
      &._negative {
        display: inline !important;
      }
      &._positive {
        display: none;
      }
    }
  }
  .logo {
    padding: 0 26px;
    margin-left: -26px;
    img {
      width: 224px;
      height: auto;
      @include media-breakpoint-down(sm) {
        width: 107px;
      }
    }
  }
  .main-navigation {
    position: initial;
    .flyoutmenu {
      position: absolute;
      top: 100%;
      left: 0;
      background: $white-80;
      width: 100%;
      border: 0;
      border-radius: 0;
      backdrop-filter: blur(7px);
      z-index: 1;
      .flyout-wrapper {

        .flyout {
          .padding {
            padding-right: 20%;
          }
          padding-top: 4.6875rem;
          .nav-item {
            .nav-link {
              color: $black;
              margin: 0;
              &.active,
              &:hover,
              &.has-sub:not(.collapsed){
                color: $black;
              }
              &.overview {
                color: $blue;
              }
            }
            & + .nav-item {
              .nav-link {
                margin-top: 1rem;
              }
            }
            &.highlight {
              opacity: .3;
            }
          }
          .level2,
          .level3 {
            .nav {
              .nav-item {
                &:first-child {
                  .nav-link {
                    padding-top: 0;
                  }
                }
                .nav-link {
                  border-bottom: 1px solid $gray-600;
                  padding: 1rem 0;
                  margin: 0;
                }
              }
            }
          }
          .menu-level2, .menu-level3 {
            &.show {
              position: sticky;
              top: 30px;
            }
          }
        }
      }
    }
  }
  .metaNavigation-spacer {
    height: 30px;
    width: 1px;
    background-color: $gray-600;
    margin-left: 13px;
    margin-right: 16px;
    //margin-top: 26px;
  }
  #regionsDropdown {
    .fa-globe {
      font-size: 14px;
      color: #646567;
      //margin-top: 29px;
      margin-right: 4px;
    }
    .regionLabel {
      color: #646567;
      font-size: 14px;
    }
  }
  .metaNavigation {
    .navbar-nav {
      .nav-item {
        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }
    }

    padding-top: 26px;
    nav {
      .navbar-nav {
        .nav-item {
          .nav-link {
            //padding-top: 26px;
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }
      }
    }
  }
  .hamburgersearch {
    &:last-child {
      .tx_solr {
        .tx-solr-submit {
          padding-right: 0 !important;
        }
      }
    }
    .nav-item {
      &:last-child {
        .nav-link {
          padding-right: 0;
        }
      }
    }
    padding: 26px 0;
    @include media-breakpoint-down(sm) {
      padding: 14px 0 !important;
    }
    #loginNav {
      //margin-right: -0.5rem;
      .nav-link {
        font-variant: normal;
        text-transform: capitalize;
      }
      #loginDropdown {
        padding: 8px;
        border: 1px solid $gray-600;
        font-size: 14px;
        color: $black;
        text-decoration: none;
        i {
          padding-left: 10px;
          color: $blue;
        }
      }
    }
    #langNav {
      .border-rightlang {
        border-color: $gray-600;
        padding-right: 1rem;
        #langsDropdown {
          padding: 8px;
          border: 1px solid $gray-600;
          font-size: 14px;
          color: $black;
          text-decoration: none;
          i {
            padding-left: 10px;
            color: $blue;
          }
        }
      }
    }
    .tx_solr {
      .input-group {
        .form-control {
          height: 43px;
          border: 1px solid $white;
          background: transparent;
          border-radius: 50px;
          color: $black;
          &:focus {
            border-color: $blue;
          }
        }
        .btn {
          padding: 0 1rem;
          box-shadow: none;
          line-height: 1;
          i {
            font-size: 1.5rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.main-header {
  .stickySearch {
    top: auto !important;
  }
  &.nokeyvisual {
    box-shadow: -13px 13px 26px rgba(214, 214, 214, 0.3), 13px -13px 26px rgba(214, 214, 214, 0.3);
  }
  &.nokeyvisual,
  &.active,
  &:hover,
  &.sticky,
  &.hideme {
    .header-content {
      background-color: $white;
      transition: all .35s ease;
    }
    .tx_solr {
      .input-group {
        .form-control {
          background: $gray-400;
        }
      }
    }
  }
  &.hideme {
    .header-content {
      .hamburgersearch {
        padding: 33px 0;
      }
    }
    .metaNavigation {
      display: none !important;
    }
  }
  &:not(.active){
    .header-content {
      backdrop-filter: blur(7px);
    }
  }
}
#mobileSearchMenu,
#mobileMainNavigation {
  transform: translate3d(-100%,0,0);
  transition: all .5s;
  z-index: 10;
  &.active {
    transform: translate3d(0,0,0);
  }
  .search-icon,
  .mp-back {
    display: block;
    border-bottom: 1px solid $gray-600;
  }
  .navbar-nav {
    .nav-item {
      border-bottom: 1px solid $gray-600;
      .nav-link {
        padding: 1rem 0 !important;
        justify-content: space-between;
        display: flex;
        color: $black;
        font-family: $headings-font-family;

        i {
          color: $blue;
          font-size: 32px;
        }
      }
      &.active,
      &:hover {
        > a {
          color: $blue;
        }
      }
    }
  }

  .mobileNavFooter {
    background-color: $gray-400;
    .mobileLangLogin {
      background-color: $gray-600;
      .loginNav {
        .nav-link {
          padding-left: 32px;
          font-variant: normal;
          text-transform: capitalize;
        }
        #loginDropdown {
          padding: 8px;
          border: 1px solid $black;
          font-size: 14px;
          color: $black;
          text-decoration: none;
          i {
            padding-left: 10px;
            color: $blue;
          }
        }
      }
      .langNav {
        .border-rightlang {
          border-color: $black !important;
          padding-right: 32px;
          #langsDropdown {
            padding: 8px;
            border: 1px solid $black;
            font-size: 14px;
            color: $black;
            text-decoration: none;
            i {
              padding-left: 10px;
              color: $blue;
            }
          }
        }
      }
    }
    .mobileMeta {
      .nav-item {
        border: 0;
        .nav-link {
          padding: 8px 0 !important;
        }
        &:first-child {
          .nav-link{
            padding-top: 2px !important;
          }
        }
      }
    }
  }

  &.flyout-menu {
    left: 0;
    top: auto;
    position: fixed;
    width: 80%;
    height: 100%;
  }

  /* cover */
  .mp-level.mp-level-open {
    transform: translate3d(0, 0, 0);
    &.active {
      > .mp-back,
      > .nav {
        visibility: visible;
      }
    }
  }

  .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    transform: translate3d(100%, 0, 0);
  }

  .mp-level {
    position: absolute;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translate3d(100%, 0, 0);
    transition: all 0.5s;
    z-index: 1;
    > .mp-back,
    > .nav  {
      visibility: hidden;
    }
  }
  .level-0 {
    font-size: 25px;
    font-weight: 300;
    font-family: $font-family-sans-serif;
  }
  .level-1 {
    font-size: 22px;
    .nav-item {
      .nav-link {
      }
    }
  }
  .metanav {
    .nav-link {
      line-height: 1.5;
      font-size: 1.125rem;
      .svg-icon {
        margin-top: -5px;
        margin-right: 10px;
      }
    }
  }
}

#mobileSearchMenu {
  .mp-level {
    background-color: $white-80;
  }
  .tx_solr {
    padding: 1rem;
    background-color: $white;
    .form-control {
      background-color: $white;
    }
  }
}
#mobileSearch {
  &.active {
    i {
      font-weight: 400;
      &:before {
        content: "\f00d";
      }
    }
  }
}
