.tx-cs2-keyvisual .landingpage-keyvisual {
  .bg {
    background: #ededed;
  }

  .image-area {
    margin-top: 179px;
    margin-bottom: 60px;
    margin-right: 189px;
  }

  .image-resize {
    width: 407px;
    height: 279px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .image-height {
    height: 35.3vw;
    margin-bottom: -1px;
  }

  .keyvisual-title {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 300px;
    width: auto;
    background: transparent;
    backdrop-filter: blur(7px);
    margin-top: 200px;
    /**/
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 20px;
    word-break: break-word;

    .title {
      color: #ffffff;
    }
  }

  .focuspoint {
    img {
      min-width: 0;
    }
  }

  .keyvisual-footer {
    /* background: url('../images/PAL_std_blue_rgb.png') 0 100%; */
    background: #ff0202;
    padding-bottom: 10px;
  }

  @include media-breakpoint-down(xl) {
    .focuspoint {
      img {
        //min-width: 100%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .image-area {
      margin-right: 109px;
    }
    .keyvisual-title {
      margin-left: 41px;
    }
  }

  @include media-breakpoint-down(md) {
    .image-area {
      margin-right: 25px;
    }
    .keyvisual-title {
      width: auto;
      margin-left: 41px;
    }
    .image-height {
      height: 350px;
    }
  }

  @include media-breakpoint-down(sm) {
    .image-area {
      margin: 16px;
    }
  }

  @include media-breakpoint-down(xs) {
    .image-area {
      //display: none;
    }
    .keyvisual-title {
      margin-top: 183px;
      margin-left: 0px;
      width: 245px;
    }
    .image-height {
      height: 300px;
    }
    .keyvisual-footer {
      padding-bottom: 3px;
    }
  }
  .productpage-title {
    .title-wrap {
      height: 45px;
      .keyvisual-title {
        margin-bottom: 10px;
        position: relative;
        padding: 8px;
      }
    }
  }
}
