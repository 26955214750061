
.main-header .stickySearch {
  box-shadow: 0 0 6px rgba(0,0,0,.12)
}

.stickySearch {


  .cs2contenthub-search {
    background: $white;
    border: none;
    border-radius: 50px !important;

    &+.input-group-btn .btn {
      &:active, &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }



}

.tx-cs2-contenthub {

  .main-header .stickySearch {
    position: relative!important
  }

  .stickySearch.is_stuck {
    z-index: 1020
  }

  .cs2contenthub-pagination-container-top {
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      justify-content: center;
      .cs2contenthub-pagination {
        display: none !important;
      }
    }
  }

  .cs2contenthub-pagination {
    .page-item {
      margin: 0 .25rem;
      .page-link {
        background: $gray-light;
        color: $black;
        border: none !important;
      }
      &.active {
        .page-link {
          background: $gray;
        }
      }
      .prev-page, .next-page {
        color: $primary !important;
      }
      &.disabled {
        .prev-page, .next-page {
          color: $gray !important;
        }
      }
    }

    .prev-page, .next-page {
      background: transparent !important;
      color: $gray;
    }
  }

  .cs2contenthub-item {

    .isNew {
      left: 0;
      top: 0;
      z-index: 10;
    }

    .card-img.ratio-container {
      &:after {
        padding-bottom: 70.25%;
      }
    }

    .card-body {
      @include media-breakpoint-down(sm) {
        padding: .5rem;
      }
    }

    .img-overlay {
      .img-badge {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
      }
      .img-markets {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-items: flex-end;
        align-items: flex-start;
        width: 40px;
        right: 10px;
        top: 10px;
        bottom: 10px;

        .market-icon {
          width: 40px;
          height: 40px;
          margin-bottom: .5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        @include media-breakpoint-down(sm) {
          width: 15px;
          right: 5px;
          top: 5px;
          bottom: 5px;

          .market-icon {
            width: 15px;
            height: 15px;
            margin-bottom: 5px;
          }
        }
      }

      &.overlay-border:after {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: rgba($black, .3);
      }
    }
  }

  .contentHubSlider-wrapper {
    .contentHubSwiper-nav-mobile-wrapper {
      .swiper-button-next, .swiper-button-prev {
        &:after {
          font-size: 32px;
        }
      }
    }

    .contentHubSwiper-pagination-wrapper {
      .contentHubSwiper-pagination {
        position: relative;
        padding-top: 1.5rem;
        bottom: 0;
        height: calc(1.5rem + 16px);

        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          transition: $transition-base;

          &.swiper-pagination-bullet-active {
            background: $primary;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .productfinderSlider {
      &.plusPartImage{
        padding-right:90px;
        margin-right:-90px
      }
    }
  }

  .contentHubSwiper {
    padding-right: 3rem;
  }

  .cs2contenthub-filter {
    .modal-dialog {
      height: 100%;
      width: 100% !important;
      margin: 0 auto;
      padding: 20px 0;
      .modal-content {
        height: 100%;

        .modal-body {
          overflow: auto;
        }
      }
    }

    .custom-filter-checkbox {
      padding-left: 0;
      .custom-control-label {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem;
        background: $bg-secondary;
        border: 1px solid $bg-secondary;

        &:before {
          display: none;
        }
        &:after {
          top: calc(50% - 0.5rem);
          left: auto;
          right: 10px;
        }
      }
      .custom-control-input:checked {
        &~.custom-control-label {
          border: 1px solid $primary;
          background: $gray;

          &::after {
            //background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='$primary' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e);
          }
        }
      }
    }

  }



  .selectdiv {
    position: relative;

    &.disabled {
      &:after {
        color: $gray-600;
      }
    }

    &:after {
      content: '>';
      font: 16px Consolas, monospace;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 12px;
      top: 0;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
      color: #2dc6d6;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /*      width: 100%;
            max-width: 516px;
            height: 48px;*/
      margin: 8px 0;
      padding: 0 8px;
      color: $black;
      /*background-color: #fff;*/
      background-image: none;
      border: 1px solid $gray-600;
      border-radius: 3px;
      -ms-word-break: normal;
      word-break: normal;

      &:disabled {
        border-color: $gray-600;
      }
    }

    option {
      color: $primary;
      padding-top: 18px;
      padding-bottom: 18px;
      font-family: Verdana;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    option:focus {
      background: $gray-600;
      color: $white;
    }

    select:focus > option:checked {
      background: $gray-600;
      color: $white;
    }
  }
}

.new-item {
  height: auto;
}
