.tx-cs2-teaser {
  .teaser {
    background-color: $white;

    .teaser-text {
      padding: 10px 15px;
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(7px);
      bottom: 30px;
      left: 45px;
      right: 45px;

      h3 {
        font-size: 31px;
        line-height: 42px;
        font-weight: 300;
      }
      p {
        font-size: 14px;
        line-height: 17px;
      }

      .description {
        display: none;
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      .btn {
        display: none;
        @include media-breakpoint-down(sm) {
          display: inline-block;
        }
      }

      @include media-breakpoint-down(sm) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        max-width: 100%;
        padding: 12px 16px 16px;
        text-align: left;
        h3,p {
          text-shadow: none;
        }
        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        a.btn {
          width: 100%;
        }
      }
      @include media-breakpoint-up(md) {
        h3,p {
          color: $black;
        }
      }
    }
    &:hover {


      .teaser-text {
        .description {
          display: block;
        }

        .btn {
          display: inline-block;
        }
      }
      .overlay-30 {
        &:after {
          background: rgba(0,0,0,.6);
        }
      }
    }
  }
  .swiper-button-prev {
    left: -5rem;
  }
  .swiper-button-next {
    right: -5rem;
  }
  .swiper-button-disabled {
    display: none;
  }
  .teaserslider {
    @include media-breakpoint-down(sm) {
      width: calc(100% - 15px);
    }

    .row {
      margin-left: -15px;
      margin-right: -15px;
      .col-md-4,
      .col-md-6,
      .col-md-12
      {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media-breakpoint-down(sm) {

        margin-left:0;
        margin-right: 0;
        .col-md-4,
        .col-md-6,
        .col-md-12
        {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
.ratio43 {
  height: 68vw !important;
}
