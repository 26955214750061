.btn {
  outline: none;
  font-weight: bold;
  &.disabled,
  &:disabled {
    opacity: 1;
    color: $white;
    background-color: $gray-600;
    border-color: $gray-600;
  }
}

.btn-primary {
  color: $primary;
  background-color: transparent;
  border: 1px solid $primary;
  box-shadow: none;
  &.selected,
  &:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
}

.btn-secondary {
    color: $white;
    border-color: $primary;
    background-color: $primary;
    box-shadow: none;
  &.selected,
  &:hover {
    color: $white;
    background-color: $black;
    border-color: $black;
  }
  &.rounded-pill {
    padding: 0.83334rem 1.3888rem;
    line-height: 1;
  }
}
