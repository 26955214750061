.tx-cs2-people {


  .people {
    padding-top:80px;
    padding-bottom:80px;
    @include media-breakpoint-down(sm) {
      padding-top: unset;
      padding-bottom: unset;
    }



    .layout-6 {
      .in-overlay {
        padding: 2rem;
        @include media-breakpoint-down(sm) {
          padding: 1rem!important;
          background-color: transparent;
        }
      }
      .text-overlay {


        @include media-breakpoint-down(sm) {
          position: relative;
        }

        &.order-last {

          &:before {
            left: -10%;
            width: unset;
          }
          .in-overlay {
            padding-right: 5rem;
          }
        }
        &.order-first {

          .in-overlay {
            padding-left: 5rem;
          }
        }

        &:before {
          left: 0;
          /*right: 0;*/
          width: unset!important;

          @include media-breakpoint-down(sm) {
            left: 0!important;
            right: 0!important;
            width: unset!important;
          }

        }
      }


      .image-last{
        text-align: right;
      }

      .img-fluid {
        filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
      }
    }
  }



  /*.people-item {
    min-height: 283px;

    @include media-breakpoint-down(sm) {
      min-height: 152px;
    }
  }
  @include media-breakpoint-down(sm) {
    .img-fluid {
      max-width: 101px;
    }

    .text-part {
      max-width: calc( 100vw - 37px - 101px - 11px);

      &.full_width {
        max-width: calc( 100vw - 37px);
      }
    }
  }*/


}
