//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
$bluedakrblue: linear-gradient(68.19deg, #0E77BD 6.43%, #062E48 82.53%);
$bluedakrblue180: linear-gradient(180deg, #0E77BD 6.43%, #062E48 82.53%);
$blueradial: radial-gradient(67.66% 549.35% at 50% 40%, #0070BA 0%, #002A45 100%);
$blueradialbig: radial-gradient(118.43% 44.75% at 43.78% 19.48%, #0070BA 0%, #002A45 128%);
$shadowbase: 0px 4px 4px rgba(20, 76, 114, 0.25);
$shadowteaser: 2px 4px 8px rgba(0, 0, 0, 0.12);
$shadowteaserhover: 4px 4px 10px rgba(0, 0, 0, 0.25);
$shadowbottom: 2px 2px 3px rgba(0, 0, 0, 0.12);
$shadowtext: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 10px rgba(0, 0, 0, 0.17);
$shadowinner: inset 5px 5px 50px rgba(0, 0, 0, 0.7);
$linebg: repeating-linear-gradient( 45deg, rgba(255,255,255,.06), rgba(255,255,255,.06) 1px, rgba(255,255,255,0) 2px, rgba(255,255,255,0) 7px );
$border-radius-box: 2px !default;
$text-65: rgba(0,0,0,.65);
$font-size-xs: 0.8125rem;

:root {
  --fancybox-bg: rgba(0,0,0,.8);
}

.frame-space-before-extra-small { padding-top: 16px !important; }
.frame-space-before-small { padding-top: 32px !important; }
.frame-space-before-medium { padding-top: 40px !important; }
.frame-space-before-large { padding-top: 128px !important; }
.frame-space-before-extra-large { padding-top: 256px !important; }
.frame-space-after-extra-small { padding-bottom: 16px !important; }
.frame-space-after-small { padding-bottom: 32px !important; }
.frame-space-after-medium { padding-bottom: 40px !important; }
.frame-space-after-large { padding-bottom: 128px !important; }
.frame-space-after-extra-large { padding-bottom: 256px !important; }

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 16px !important; }
  .frame-space-before-small { padding-top: 24px !important; }
  .frame-space-before-medium { padding-top: 64px !important; }
  .frame-space-before-large { padding-top: 72px !important; }
  .frame-space-before-extra-large { padding-top: 144px !important; }
  .frame-space-after-extra-small { padding-bottom: 16px !important; }
  .frame-space-after-small { padding-bottom: 24px !important; }
  .frame-space-after-medium { padding-bottom: 64px !important; }
  .frame-space-after-large { padding-bottom: 72px !important; }
  .frame-space-after-extra-large { padding-bottom: 144px !important; }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  font-size: 16px;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  /*padding-top: calc(69px - var(--meta,0));*/
  @include media-breakpoint-down(md) {
    /*padding-top: calc(73px - var(--meta,0));*/
  }
}
body.overflow-hidden {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}
// content wrapper
.content-wrapper {
  padding: 0 $grid-gutter-width/2;
  max-width: $max-width-content-wide;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width-mobile/2;
  }
}
.element-wrapper { margin-bottom: $spacer*2}

.container-narrow {
  .content-wrapper {
    max-width: $max-width-content-narrower !important;
  }
}

.navigation-wrap {
  max-width: $max-width-content;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
}

.narrow-wrapper {
  max-width: $max-width-content-narrow;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width-mobile/2;
  }
}

.narrower-wrapper {
  max-width: $max-width-content-narrower;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width-mobile/2;
  }
}

.container-extended-gray,
.container-narrow-gray{
  background: $gray-300;
}

.container-narrow-gray,
.container-narrow-white {
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-narrow;
    @include media-breakpoint-down(md) {
      padding: 0 $grid-gutter-width-mobile/2;
    }
  }
}

.container-extended-gray,
.container-extended-white {
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-wide;
    @include media-breakpoint-down(md) {
      padding: 0 $grid-gutter-width-mobile/2;
    }
  }
}

@include media-breakpoint-down(md) {
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.primary {
  color: $primary;
}

h1,.h1 {
  color: $primary;
}

@include media-breakpoint-down(md) {
  h1,.h1 {
    font-size: 28px;
  }
  h2,.h2 {
    font-size: 25px;
  }
  h3,.h3 {
    font-size: 22px;
  }
  h4,.h4 {
    font-size: 20px;
  }
  .btn {
    font-size: 14px;
    padding: 12px 14px;
    line-height: 1;
  }
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 15px;
  }
}

.w-lg-auto {
  @include media-breakpoint-up(lg) {
    width: auto !important;
  }
}

.bg-grey {
  background-color: $gray-300;
}

.lead {
  line-height: 1.2;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.small {
  font-size: 0.875rem;
  line-height: 1.214;
  font-weight: 400;
  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.body {
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
}

.frame-type-header {
  .lead {
    margin-bottom: 2.2223rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
}

ol,
ul {
  padding-left: 20px;
}

em {
  font-size: 0.83333rem;
}



.svg-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  &.blue {
    color: $blue;
    --color1: #185FA5;
  }
  &.red {
    color: $red;
    --color1: #E30613;
  }
  &.green {
    color: $green;
    --color1: #84BE41;
  }

  &.black {
    color: $black;
    --color1: #000;
  }
  &.white {
    color: $white;
    --color1: #FFF;
  }

  &.svg-icon-2x {
    width: 2rem;
    height: 2rem;
  }
}
header {
  .svg-icon {
    width: 2.7778rem;
    height: 2.7778rem;
    margin-bottom: 1.1112rem;
  }
}
table {
  font-size: 14px;
  th {
    color: $black;
    border-bottom: 1px solid $gray-600 !important;
  }
  th,td {
    background-color: transparent;
    padding: 8px 0 !important;
  }
  thead {
    th {
      border: 0;
      color: $black !important;
      vertical-align: top;
    }
  }
  tr:first-child {
    th,td {
      border-top: none;
    }
  }
}

.ce-uploads {
  li {
    padding: 8px 0;
    border-bottom: 1px solid #CFCFCF;
    .ce-uploads-fileName {
      font-size: $font-size-base;
      padding-left: 9px;
    }
    i {
      color: $gray-600;
    }
  }
}

.overlay- {
  &20,
  &30,
  &60 {
    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height:100%;
      top:0;
      right: 0;
      bottom: 0;
      left:0;
      transition: all .35s linear;
    }
  }
  &20:after {
    background:rgba(0,0,0,0.2);
  }
  &30:after {
    background:rgba(0,0,0,0.3);
  }
  &60:after {
    background:rgba(0,0,0,0.6);
  }
}

.breadcrumb {
  margin-bottom: 0;
  padding: 1.25rem 0;
  .breadcrumb-item {
    padding: 0;
    &:before {
      content: none;
    }
    i {
      color: $blue;
    }
    a {
      font-size: 0.875rem;
      color: $black;
      line-height: 1.18;
      text-decoration: none;
      &:after {
        display: inline-block;
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0 8px;
        font-size: .6rem;
        color: $gray-600;
      }
    }
    &:last-child {
      a:after {
        content: none;
      }
    }
  }
}

.drop-shadow {
  filter: drop-shadow(-13px 13px 26px rgba(214, 214, 214, 0.3)) drop-shadow(13px -13px 26px rgba(214, 214, 214, 0.3));
}

.landingpage-thermofisher {

  font-family: 'HelveticaNeue';

  .h1,h1,
  .h2,h2,
  .h3,h3,
  .h4,h4,
  .h5,h5,
  .h6,h6 {
    font-family: 'HelveticaNeue';
    color: #ff0202;
    font-family: 'HelveticaNeue';
  }

  a {
    color: #000000;
    &:hover {
      color: #ff0202;
    }
  }
}

.swiper-container {
  .swiper-button-prev,
  .swiper-button-next {
    color: $primary;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      opacity: 1;
      background: #BEBEBE;
      &-active {
        width: 16px;
        height: 16px;
        background: $primary;
      }
    }
  }
}
