.tx-cs2-heroslider {
  .heroSlider {
    position: relative;
    height: 100%;
  }

  .swiper-wrapper {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .caption-align {
    display: flex;
    -ms-flex-align: center;
    z-index: 1;
    align-items: center;
    position: absolute;
    left: 81px;
    width: 516px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(7px);

    .caption-btn {
      margin-left: 20px;
      margin-bottom: 16px;
      display: inline-block;
      float: left;
      font-size: 14px;
    }
  }

  .slider-title {
    padding-top: 14px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 4px;
    text-align: start;
    word-break: break-word;
    margin-bottom: 8px;
  }

  .full-width {
    width: 1920px;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      overflow-x: hidden;
      overflow-y: hidden;
  }

  .swiper-button-prev {
    left: 12px;
    color: $white;
  }

  .swiper-button-next {
    right: 51px;
    color: $white;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: $white;
  }

  .swiper-pagination {
    bottom: 18px;
  }

  .button .text {
    font-weight: 700;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 16px;
    padding-left: 16px;
    word-break: break-all;
  }

  .swiper-socialmedia-logos {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 80px;
    margin-bottom: 48px;
    align-items: center;
    z-index: 10;
    .link-item {
      margin-top: 16px;
      transition: $transition-base;
      position: relative;
      .label {
        color: $white;
        margin-right: 10px;
        visibility: hidden;
        transition: $transition-base;
        position: absolute;
        top: 50%;
        right: 55%;
        transform: translateY(-55%);
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
      }
      .logo-item {
        position: relative;
        z-index: 10;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: $white;
        transition: $transition-base;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
      }
      &:hover {
        .label {
          visibility: visible;
          right: 100%;
        }
        .logo-item {
          color: $primary;
        }
      }
    }
  }

  .heroSlider-footer {
    background: url(../images/PAL_std_blue_rgb.png) 0 100%;
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide .title {
    position: absolute;
    top: 210px;
    left: 10px;
    color: white;
  }

  @include media-breakpoint-down(xl) {
    .full-width {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .caption-align {
      width: 345px;
      font-size: 15px;
    }
  }

  @include media-breakpoint-down(xs) {
    .caption-align {
      margin-top: 0;
      width: 245px;
      left: 0;
      bottom: 0;
      margin-bottom: 160px;

      .caption-btn {
        font-size: 14px;
      }
    }

    .button {
      .text {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 14px;
        padding-left: 14px;
      }
    }

    .slider-title {
      font-weight: 300;
      font-size: 25px;
      line-height: 34px;
      padding-bottom: 8px;
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }

    .swiper-slide-custom {
      justify-content: end;
    }

    .swiper-socialmedia-logos {
      margin-right: 16px;
      margin-bottom: 60px;
    }

    .heroSlider-footer {
      background: url('../images/PAL_std_blue_rgb.png') 0 100%;
      padding-bottom: 3px;
    }
  }

  .div-only-mobile {
    height: 640px;
  }
  .div-no-mobile {
    height: 1014px;
  }

  .height-resize {
    height: calc(100vh - 10px);
  }

  @media screen and (max-width: 849px) {
    .height-resize {
      height: 640px;
    }
  }
}
