.tx-cs2-newsroom {
  &.bg-hub {
    padding-bottom: 30px;
  }
}

.object-cover {
  object-fit: cover;
  object-position: center;
}

.mb-grid {
  margin-bottom: $grid-gutter-width;
}
.gap-grid {
  gap: $grid-gutter-width;
}

.news-item {
  background: $white;
  &.detail-item {
    .news-image {
      img {
        aspect-ratio: 4;
      }
    }
  }
  &.featured-item {
    height: 100%;
    .news-image {
      height: 0;
      padding-bottom: 44.4%;
      overflow: hidden;
      img {
        min-height: 100%;
        min-width: 100%;
        height: auto;
        aspect-ratio: 2.25;
      }
    }
  }

  &.small-item {
    height: 33%;
    .news-image {
      height: 0;
      padding-bottom: 100%;
      @include media-breakpoint-down(md) {
        padding-bottom: 44.4%;
      }
      overflow: hidden;
      img {
        aspect-ratio: 1;
        min-height: 100%;
        min-width: 100%;
        height: auto;
        @include media-breakpoint-down(md) {
          aspect-ratio: 2.25;
        }
      }
    }
  }
  .news-title,
  .news-text {
    overflow: hidden;
    line-clamp: 3;
    margin-bottom: 0;
  }
  .news-time {
    font-size:$small-font-size;
  }

  .news-date,
  .news-author {
    font-size:$small-font-size;
    font-style: italic;
  }
  .news-tags {
    position: absolute;
    background: $primary;
    color: $white;
    font-size: $font-size-xs;
    bottom: 0;
    left: 0;
    padding: 3px;
    line-height: 1;
  }
  .news-type {
    position: absolute;
    background: $black;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
  }
  .news-infos {
    position: absolute;
    background: rgba($light,.75);
    bottom: 0;
    right: 10px;
    line-height: 1;
    font-style: normal;
    font-weight: $font-weight-bold;
    @include media-breakpoint-down(md) {
      right: 5px;
    }
    .news-date {
      font-style: normal;
    }
  }
}

.font-size-small {
  font-size: $small-font-size;
}

.author-image img {
  aspect-ratio: 1;
  border: 2px solid $white;
  /*@include media-breakpoint-down(md) {
    aspect-ratio: 2.25;
  }*/
}

.circle-icon {
  display: inline-flex;
  position: relative;
  margin: .35rem;
  width: 2rem;
  height: 2rem;
  color: $white;
  align-items: center;
  justify-content: center;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    z-index: -1;
    background: $primary;
    border-radius: 50%;
  }
}

.page-normal .news-item.small-item {
  height: auto;
}
