.location-googlemap-part {
  background: #F2F2F2;
  flex-direction: column;
  padding: 0 0;
  .location-googlemap-part-title {
    font-size: 2.5rem;
    padding: 3.5rem 0 0.4rem 3.2rem;
  }
  .location-googlemap-select-area {
    margin-bottom: 2rem;
  }
  .location-googlemap-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 45%;
  }
  .location-googlemap {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .location-records {
    display: none;
  }
  .gm-style-iw-d h3 {
    font-family: Roboto;
    font-weight: 900;
    font-size: 28px;
    line-height: 33px;
  }
  .gm-style .gm-style-iw-t::after {
    background: none;
  }
  .gm-style .gm-style-iw-c {
    border-radius: 0;
  }
}
