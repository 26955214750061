.tx-cs2-iconswithlink  {

  .item-box {
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-down(sm) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }


  .item {
    position:relative;
    margin-left: 10px;
    margin-right: 10px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include media-breakpoint-down(lg) {
      margin-left: 4px!important;
      margin-right: 4px!important;
    }

    .item-link {
      display: inline-block;



      &:hover {

        .title-box {
          display: none;
        }
        .description-box {
          z-index: 30;
        }
        .img-box {

          border-color: $gray-600;
          .overlay {
            background-color: $primary;
          }
        }
      }
    }

    .text-part {
      position: absolute;
      top:5px;
      bottom:5px;
      left:5px;
      right:5px;
      overflow: hidden;

      .icon-box {
        position: absolute;
        top:25%;
        bottom:0;
        left:0;
        right:0;

        .svg-icon {
          width: 86px;
          height: 75px;
          margin: auto;
          display: block;
          position: relative;
          z-index: 25;
          @include media-breakpoint-down(md) {
            width: 46px;
            height: 40px;

          }
        }
      }
      .title-box {
        position: absolute;
        top: 65%;
        left: 0;
        right: 0;
        height: 56px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 25;

        .title {
          margin-top: 5px;
        }
        @include media-breakpoint-down(md) {
          height: 44px;

          .title {
            font-size: 1.5rem;
            margin-top: 0.15rem;
          }
        }
        @include media-breakpoint-down(xs) {
          height: 30px;

          .title {
            font-size: 1rem;
            margin-top: 0.2rem;
          }
        }
      }
      .description-box {
        position: absolute;
        top: 55%;
        left: 0;
        right: 0;
        height: 56px;

        .description {
          margin-top: 5px;

        }
        @include media-breakpoint-down(md) {
          height: 30px;

          .description {
            font-size: 0.75rem;
            margin-top: 0;
          }
        }
        @include media-breakpoint-down(xs) {
          height: 30px;

          .description {
            font-size: 0.5rem;
            margin-top: 0;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      .img-wrap {
        max-width:  calc((100vw - 45px)/2);
      }
    }
    .img-box {
      border: 5px solid $primary;
      overflow: hidden;
      position: relative;
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        border-radius: 50%;
        background-color: rgba($primary,0.4);
        transition: $transition-base;
      }

/*      &:hover {
        border-color: $gray-600;
        .overlay {
          background-color: $primary;
        }
      }*/
    }
  }

    .people-item {
    min-height: 283px;

    @include media-breakpoint-down(sm) {
      min-height: 152px;
    }
  }

  .circle-wrap {
    height:50% !important;
  }

  .circle-wrap::before {
    content: "";
    width: 50%;
    height: 100%;
    float: left;
    shape-outside: polygon(-0.1% 3.16%, 29px 56.53%, 39.64% 72.26%, 55.73% 83.77%, 79.47% 90.39%, 2px 89.74%);
    shape-margin: 2%;
  }

  .circle-wrap p {
    height:100%;
    margin:0 !important;
  }

  .circle-wrap p::before {
    content: "";
    width: 50%;
    height: 100%;
    float: right;
    shape-outside: polygon(100% 0px, 100% 100%, 2% 100%, 18.09% 97.31%, 46.77% 90.65%, 71.11% 76.86%, 85.43% 61.92%, 89.08% 49.09%, 95.78% 29.85%);
    shape-margin: 3%;
  }

}


