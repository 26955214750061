.v-vlite {
  --vlite-colorPrimary: $blue;
  --vlite-transition: 0.25s ease;

  /* Control bar */
  --vlite-controlBarHeight: 50px;
  --vlite-controlBarHorizontalPadding: 10px;
  --vlite-controlBarBackground: linear-gradient(to top, #000 -50%, transparent);

  /* Controls */
  --vlite-controlsColor: #fff;
  --vlite-controlsOpacity: 0.9;
  --vlite-controlsIconWidth: 28px;
  --vlite-controlsIconHeight: 28px;

  /* Progress bar */
  --vlite-progressBarHeight: 5px;
  --vlite-progressBarBackground: rgba(0 0 0 / 25%);

  font-family: -apple-system, blinkmacsystemfont, segoe UI, Helvetica, Arial, sans-serif,
  Apple Color Emoji, Segoe UI Emoji;
  position: relative;
  overflow: hidden;

  &,
  * {
    box-sizing: border-box;
  }

  &.v-audio {
    --vlite-controlsColor: #000;

    box-shadow: 0 2px 15px rgba($black,10%);
    border-radius: 8px;
  }

  &.v-video {
    background-color: #000;
    height: 0;
    padding-top: 56.25%;
  }

  &:-webkit-full-screen {
    width: 100%;
    height: 100%;
  }

  :-moz-full-screen & {
    width: 100%;
    height: 100%;
  }

  &:-moz-full-screen {
    width: 100%;
    height: 100%;
  }

  video:-webkit-media-controls {
    display: none !important;
  }

  &.v-fullscreenButton-display {
    .v-controlBar,
    .v-bigPlay {
      position: fixed;
      z-index: 2147483647;
    }
  }

  &.v-paused {
    .v-controlBar.hidden {
      opacity: 1;
    }
  }

  &.v-playing,
  &.v-paused:not(.v-firstStart) {
    .v-poster {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.v-playing {
    .v-bigPlay {
      visibility: hidden;
      opacity: 0;
    }
  }

  iframe.vlite-js {
    pointer-events: none;
  }

  .vlite-js,
  .vlite-js > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.v {
  &-controlButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    transition: opacity var(--vlite-transition);
    opacity: var(--vlite-controlsOpacity);
    padding: 0;
    -webkit-appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;

    * {
      pointer-events: none;
    }

    &:hover {
      opacity: 1;
    }

    &.v-controlPressed .v-iconUnpressed,
    &:not(.v-controlPressed) .v-iconPressed {
      display: none;
    }

    &:focus-visible {
      outline: 2px dashed var(--vlite-colorPrimary);
      outline-offset: -4px;
    }

    svg {
      fill: var(--vlite-controlsColor);
      width: var(--vlite-controlsIconWidth);
      height: var(--vlite-controlsIconHeight);
    }
  }
}

.v {
  &-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    z-index: 2;

    &.v-active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.v {
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--vlite-controlBarHeight));
  }
}

.v {
  &-controlButtonIcon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &-playPauseButton {
    flex-shrink: 0;
  }

  &-time {
    color: var(--vlite-controlsColor);
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  &-progressBar {
    transition: height var(--vlite-transition), top var(--vlite-transition);
    z-index: 1;
    width: calc(100% - 2 * var(--vlite-controlBarHorizontalPadding));
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;

    &:focus-visible {
      outline: 2px dashed var(--vlite-colorPrimary);
      outline-offset: 2px;
    }

    &::-webkit-slider-runnable-track {
      height: var(--vlite-progressBarHeight);
      border: none;
      border-radius: var(--vlite-progressBarHeight);
      background: linear-gradient(
          to right,
          var(--vlite-colorPrimary) var(--value, 0),
          var(--vlite-progressBarBackground) var(--value, 0)
      );
    }

    &::-moz-range-track {
      height: 100%;
      border: none;
      border-radius: var(--vlite-progressBarHeight);
      background: linear-gradient(
          to right,
          var(--vlite-colorPrimary) var(--value, 0),
          var(--vlite-progressBarBackground) var(--value, 0)
      );
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: none;
      background: var(--vlite-colorPrimary);
      margin-top: -3px;
    }

    &::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: none;
      background: var(--vlite-colorPrimary);
      margin-top: -3px;
    }
  }

  &-volumeButton {
    transition: transform var(--vlite-transition), opacity var(--vlite-transition);
    flex-shrink: 0;

    &.v-animate {
      transform: translateY(-3px);
    }
  }

  &-controlBar {
    position: relative;
    width: 100%;
    height: var(--vlite-controlBarHeight);
    padding-top: var(--vlite-progressBarHeight);
    text-align: right;
    padding-left: var(--vlite-controlBarHorizontalPadding);
    padding-right: var(--vlite-controlBarHorizontalPadding);
    transition: opacity var(--vlite-transition);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      opacity: 1 !important;
    }

    &.hidden {
      opacity: 0;
    }
  }

  &-audio {
    .v-progressBar {
      order: 2;
      height: 25px;
    }

    .v-controlBar {
      padding-top: 0;
    }

    .v-playPauseButton {
      order: 1;
    }

    .v-time {
      order: 3;
      padding: 0 8px 0 12px;
    }

    .v-volumeButton {
      order: 4;
    }
  }

  &-video {
    .v-progressBar {
      height: 5px;
    }

    .v-controlBar {
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--vlite-controlBarBackground);

      .v-progressBar {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.v {
  &-bigPlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    transition: opacity var(--vlite-transition);

    svg {
      fill: var(--vlite-controlsColor);
      transition: opacity var(--vlite-transition);
      opacity: var(--vlite-controlsOpacity);
      width: 100%;
      height: 100%;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  &-loading {
    .v-bigPlay {
      opacity: 0;
    }
  }
}

.mb-gap {
  margin-bottom: $grid-gutter-width/2;
}
.vshadow-box {
  box-shadow: 0 0 26px rgba(214,214,214,.3);
}
