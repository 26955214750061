.tx-cs2-accordion {
  .card {
    background-color:$gray-400;
    border: unset;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .card>.card-header {
    background-color: unset;
    border:unset;
    padding: 0.89rem;
    cursor: pointer;

    .title_box {
      color: $primary;
      padding-right :1.6rem;

      &.collapsed {
        color: $black;
      }

    }

    .icon-box {
      right: 0;
      @include media-breakpoint-down(sm) {
        top: -0.3rem;
      }
      .svg-icon {
        width: 1.4rem;
        height: 1.4rem;
        @include media-breakpoint-down(sm) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .closed {
      display: none;
      color: $black;
    }
    .opened {
      display: inline-block;
      color: $primary;
    }
    .collapsed {
      .closed {
        display: inline-block;
      }
      .opened {
        display: none;
      }
    }

  }

  .card-body {
    padding: 0 0.89rem 0.89rem;


    .img-fluid {
      width: 100%;
      height: auto;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

  }

  .layout-light{
    border-top: 1px solid $gray-600;

    .card {
      background-color: unset;
      border-bottom: 1px solid $gray-600;
      margin-top: 0;
      margin-bottom: 0;
      padding: 1rem 0;

      &>.card-header {
        padding: 0 0.89rem;

        .title_box {
          color: $black;

          .body {
            font-family: "Open Sans Condensed Light";
            font-size: 1.563rem;
            font-weight: 300;
            line-height: normal;

            @include media-breakpoint-down(sm) {
              font-size: 1.25rem;
            }
          }
        }

        .icon-box {
          .closed {
            color: $primary;

            @include media-breakpoint-down(sm) {
              color: $black;
            }
          }
        }
      }
      .collapse {
       /* padding-bottom: 0.89rem;*/

        .card-body {
          /*padding-bottom: 0;*/
          padding: 0.89rem 0.89rem 0;
          @include media-breakpoint-down(sm) {
            font-size: 0.875rem;
          }


          a {
            background-color: transparent;
            border: 1px solid $primary;
            border-radius: 0;
            box-shadow: none;
            display: inline-block;
            color: $primary;
            font-size: 1rem;
            font-weight: 700;
            line-height: 2.7778rem;
            outline: 0;
            padding: 0 1rem;
            text-align: center;
            text-decoration: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

            @include media-breakpoint-down(sm) {
              font-size: 0.875rem;
              padding: 0 0.875rem;
            }
          }
        }
      }
    }
  }
}
