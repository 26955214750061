/* cs2_brandslider */
.tx-cs2-brandslider {
  .brand-wrapper {
    max-width: 1160px;
  }
  .swiper {
    width: 100%;
    height: 100%;
    overflow: initial;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      display: block;
      opacity: 0.5;
    }
  }

  .swiper-slide img:hover {
    opacity: 1;
  }

  .swiper-wrapper {
    align-items: center;
    transition-timing-function: linear;
  }

  @media only screen and (max-width:430px)  {
    .brandSliderDescription {
      text-align: center;
    }
  }

  .brandSliderHeader { // If we need design defined in factsheet remove this brandSlider class
    width: 648px;
    height: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
