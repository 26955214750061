.tx-cs2-tabs {
  .nav-tabs {
    flex-wrap: unset;
    @include media-breakpoint-down(xs) {
      display: block;
      li.nav-item:last-of-type .nav-link.active {
        border: 1px solid $gray-200;
      }
    }

/*    li.nav-item {
      @include media-breakpoint-down(sm) {
        border: 1px solid $gray-300;
      }
    }*/
  }
  .nav-link {
    padding: 0.89rem;
    background-color: $white;
    color: $blue;
    margin-bottom: 0;
    border: unset;
    font-size: 1rem;
    white-space: nowrap;

    &.active {
      color: $black;
      font-weight: 700;
      background-color: $gray-300;
      border-radius: 5px 5px 0 0;
      background-top-color: $gray-300;
      background-top-width: 1px;
      background-top-style: solid;
      background-left-color: $gray-300;
      background-left-width: 1px;
      background-left-style: solid;
      background-right-color: $gray-300;
      background-right-width: 1px;
      background-right-style: solid;

    }
  }

  .tab-content {
    background-color: $gray-300;
    margin: 0 -20vw;
    color: $gray-600;


/*    @include media-breakpoint-down(sm) {
      margin: 0 -10vw;
    }*/
    @include media-breakpoint-down(xs) {
      margin: 0 -10px;
    }

    .fade:not(.show) {
      display: none;
    }

    .tab-content-wrapper {




      .tab-pane {
        .table td, p {
          color: $gray-600;
        }
        table th {
          color: $black;
          font-weight: 700;
        }
        .table td, .table th {
          background-color: $gray-300;
          border: none;
        }
      }
    }
  }
}
