.tx-cs2-socialmedia {
  .socialmedia {
    .svg-icon {
      width: 2.5rem;
      height: 2.5rem;
      @include media-breakpoint-down(sm) {
        width: 1.95rem;
        height: 1.95rem;
      }
    }
  }
}
