.main-footer {
  padding: 0;
  background: $white;
  .mainFooter {
    margin: 4.875rem 0 3.75rem 0;
    @include media-breakpoint-down(md) {
      margin: 15px 0;
    }
    .footer-list {
      .nav-item {
        .nav-link {
          padding: .5rem 0;
        }
        &:first-child {
          .nav-link {
            padding-top: 0;
          }
        }
      }
    }
    .collapse {
      @include media-breakpoint-up(md) {
        display: block;
        height: auto !important;
        visibility: visible;
      }
    }
    .collapsing{
      @include media-breakpoint-up(md) {
        position: relative;
        height: unset !important;
        overflow: hidden;
      }
    }
  }
  #footerbar {
    background: $gray-400;
    padding: 0.75rem 0;
    .copyright {
    }
  }
  h3 {
    -webkit-appearance: none;
    i {
      transition: $transition-base;
    }
    &:not(.collapsed) {
      i {
        transform: rotate(180deg);
      }
    }
  }
  a:not(.btn) {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $black;
    }
  }
  &.offer-visible {
    @include media-breakpoint-down(xs) {
      margin-bottom: var(--offer) !important;
    }
  }
}

#mobilefooterbar {
  background-color: $bluedark;
  .copyright {
    font-size: 15px;
    padding: 15px 0;
  }
}

.footerRowWrapper {
  background-color: $blue;
  color: $white;
  padding: 1rem 0;
  @include media-breakpoint-down(sm) {
    .nav:not(.social) {
      > .nav-item {
        & + .nav-item {
          margin-top: 1rem;
        }
      }
    }
  }
  a {
    color: $white;
    i {
      padding-right: 10px;
    }
  }
  .social {
    .nav-item {
      .nav-link {
        font-size: 2rem;
      }
      & + .nav-item {
        padding-left: 1rem;
      }
    }
  }
}

.landingpage-thermofisher {
  .main-footer {
    a {
      color: #000 !important;
      text-decoration: underline;
      &:hover {
        color: #e5352d !important;
      }
    }
    .landingpage-footer {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
