.stickyButtons {
  right: 10px;
  bottom: 6.25rem;
  z-index: 10;
  @include media-breakpoint-down(xs) {
    width: 100%;
    bottom: 0;
    right: 0;
  }
  .closebtn {
    .collapsbuttons .closeicon {
      display:block;
    }
    .collapsbuttons .openicon {
      display:none;
    }
    .collapsed {
      .closeicon {
        display:none;
      }
      .openicon {
        display:block;
      }
    }
  }
  .stickyBtn {
    border-radius: 50px;
    line-height: 1;
    box-shadow: 0px 0px 10px rgba($black, .07);
    i {
      font-size: 2rem;
    }
  }
  .stickyFaq {
    span {
      font-size: 1rem;
      transition: $transition-base;
      max-width: 0;
      overflow: hidden;
      word-break: keep-all;
    }
    @include media-breakpoint-down(xs) {
      margin-right: 10px;
      i {
        font-size: 1.5rem;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &:hover {
      span {
        max-width: 100%;
        margin-left: 8px;
      }
    }
  }
  .offerWrap {
    @include media-breakpoint-down(xs) {
      padding: 12px;
      background-color: $white;
      width: 100%;
    }
    .stickyOffer {
      i {
        font-size: 45px;
      }
      @include media-breakpoint-down(xs) {
        margin-right: 10px;
        i {
          font-size: 24px;
        }
      }
    }
  }
}

.main-container {
  &.nokeyvisual {
    padding-top: var(--header) !important;
  }

  .news-item.detail-item {
    padding-top: var(--header) !important;
  }
}
